import React, { useEffect } from 'react';
import { Box, Button, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import Dashboard from '../../../components/layouts/Dashboard/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import { ROUTER_URLS } from '../../../constants/router-urls';
import type { UserResultType } from '../../../types/user';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import toProperCase from '../../../utils/strings';
import { formatDateString } from '../../../utils/dates';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import { setSessionStorage } from '../../../utils/storage';
import BugReportIcon from '@mui/icons-material/BugReport';
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ForestIcon from '@mui/icons-material/Forest';
import type { PagedResultsType } from '../../../types/pagedResults';

import FormattedDataGrid from '../../../components/compounds/FormattedDataGrid/FormattedDataGrid';
import type { GridAlignment, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useUserCanAccess } from '../../../hooks/userCanAccess';

interface IProps extends WrappedProps {};

function AdminUsers({ postConfig }: IProps) {
  const navigate = useNavigate();

  const userCanEdit = useUserCanAccess('userEdit');
  const userCanImpersonate = useUserCanAccess('userImpersonate');
  const userCanCreate = useUserCanAccess('userCreate');
  const userCanCreateNewListing = useUserCanAccess('userNewListing')
  
  const [users, setUsers] = React.useState<UserResultType[]>([]);
  
  const [email, setEmail] = React.useState<string>('');
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'memberSince',
      sort: 'desc',
    },
  ]);

  useEffect(() => {
    if (!loading) {
      setLoading(true);

      const params = {
        page: paginationModel.page + 1,
        sort: sortModel[0],
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
        }
      }

      axios.post(API_V3_ALLIGATOR_URLS.admin.users.list, params, postConfig).then((response) => {
        const usersData: { users: UserResultType[], page: PagedResultsType } = response.data;
        setUsers(usersData.users);
        setRowCount(usersData.page.count);
        setLoading(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [paginationModel.page, sortModel[0]?.field, sortModel[0]?.sort]);

  const searchUsers = () => {
    setLoading(true);

    const params = {
      page: 1,
      user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
      }
    }

    setPaginationModel({
      page: 0,
      pageSize: 20,
    });
    setSortModel([{
      field: 'memberSince',
      sort: 'desc',
    }]);

    axios.post(API_V3_ALLIGATOR_URLS.admin.users.list, params, postConfig).then((response) => {
      const usersData = response.data;
      setUsers(usersData.users);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  const handleImpersonateUserClick = (e: any, userId: string | number) => {
    e.preventDefault();
    axios.post(`${API_V3_ALLIGATOR_URLS.admin.users.impersonate}/${userId}`, {}, postConfig).then((response) => {
      setSessionStorage("impersonateUserToken", response.data.securityToken);
      setSessionStorage("impersonateUserId", response.data.impersonatedUserId);
      setSessionStorage("impersonateUserName", response.data.impersonatedUserName);
      navigate(ROUTER_URLS.home);
    }).catch((error) => {
      console.log(error);
    });
  };

  const navigateToUser = (e: any, id: string | number) => {
    e.preventDefault();
    const path = generatePath(ROUTER_URLS.admin.users.show, { id });
    navigate(path);
  };

  const navigateToEditUser = (e: any, id: string | number) => {
    e.preventDefault();
    const path = generatePath(ROUTER_URLS.admin.users.edit, { id });
    navigate(path);
  };

  const navigateToNewHost = () => {
    const path = generatePath(ROUTER_URLS.admin.users.new);
    navigate(path);
  };

  const navigateToUserBookings = (e: any, id: string | number, userType: string) => {
    e.preventDefault();
    const path = generatePath(ROUTER_URLS.admin.bookings.index);
    const searchParams = userType === 'user' ? `?userId=${id}` : `?hostId=${id}`
    navigate({ pathname: path, search: searchParams });
  };

  const navigateToNewListing = (e: any, id: string | number) => {
    e.preventDefault();
    const path = generatePath(ROUTER_URLS.listings.new);
    const searchParams = `?hostId=${id}`;
    navigate({ pathname: path, search: searchParams });
  }

  const handleSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    const copiedSortModel = [...newSortModel];
    setSortModel(copiedSortModel);
  }, []);

  const gridHeaders = [
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      editable: false,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.firstName ? toProperCase(params.row.firstName) : '',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.lastName ? toProperCase(params.row.lastName) : '',
    },
    {
      field: 'userType',
      headerName: 'User Type',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.userType ? toProperCase(params.row.userType) : '',
      sortable: false,
    },
    {
      field: 'stripeCustomerId',
      headerName: 'Stripe Customer Id',
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: 'stripeConnectId',
      headerName: 'Stripe Connect Id',
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: 'memberSince',
      headerName: 'Member Since',
      width: 125,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.memberSince ? formatDateString(params.row.memberSince) : '',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      cellClassName: 'actions',
      align: 'right' as GridAlignment,
      getActions: ({ id, row }: { id: string | number, row: UserResultType }) => {
        const actionBlocks: JSX.Element[] = []
        if (row.userType !== 'user' && userCanImpersonate) {
          actionBlocks.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Impersonate"
              className="textPrimary"
              onClick={(e: any) => { handleImpersonateUserClick(e, row.id) }}
              color="default"
            />
          )
        }
        if (userCanEdit) {
          actionBlocks.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={(e: any) => { navigateToEditUser(e, row.id) }}
              color="primary"
            />,
          )
        }
        if (row.userType !== 'user' && userCanCreateNewListing) {
          actionBlocks.push(
            <GridActionsCellItem
              icon={<ForestIcon />}
              label="New Listing"
              className="textPrimary"
              onClick={(e: any) => { navigateToNewListing(e, row.id) }}
              color="primary"
            />
          )
        }
        return actionBlocks.concat(
          [
            <GridActionsCellItem
              icon={<BugReportIcon />}
              label="View"
              className="textPrimary"
              onClick={(e: any) => { navigateToUser(e, row.id) }}
              color="warning"
            />,
            <GridActionsCellItem
              icon={<MenuBookIcon />}
              label="Bookings"
              className="textPrimary"
              onClick={(e: any) => { navigateToUserBookings(e, row.id, row.userType) }}
              color="default"
            />,
          ]
        );
      },
    },
  ]

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Users</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          {userCanCreate && <Button
            type="submit"
            variant="contained"
            onClick={navigateToNewHost}
            color='secondary'
            sx={{ marginRight: 2 }}
          >
            +New User
          </Button>}
        </Grid>
      </Grid>
      

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4} display='flex' alignItems='center'>
          <TextField
            id="outlined-search"
            label=""
            type="search"
            placeholder="Email"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: (theme: any) => theme.palette.primary.main }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
        </Grid>
        <Grid item xs={12} lg={4} display='flex' alignItems='center'>
          <TextField
            id="outlined-search"
            label=""
            type="search"
            placeholder="First Name"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: (theme: any) => theme.palette.primary.main }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            value={firstName}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction='row' alignItems='center'>
            <TextField
              id="outlined-search"
              label=""
              type="search"
              placeholder="Last Name"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: (theme: any) => theme.palette.primary.main }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ height: '45px', marginY: 3, marginLeft: 3, }}
              onClick={searchUsers}
              disabled={loading}
            >
              Search
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Box sx={{ marginY: 3 }}>
        <FormattedDataGrid
          headers={gridHeaders}
          rows={users}
          totalRows={rowCount}
          pagingModel={paginationModel}
          setPagingModel={setPaginationModel}
          isLoading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
            sorting: {
              sortModel: [
                { field: 'memberSince', sort: 'desc' }
              ]
            }
          }}
          sortModel={sortModel}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
    </Dashboard>
  );
}

export default withCommonTools(AdminUsers);
