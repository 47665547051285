import React from 'react';
import { Box, CircularProgress, Typography } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

interface IProps {
  isLoading: boolean;
  listingViewChartData: any;
}

const PIE_CHART_COLORS = ['#8984D8', '#0E535B', '#F47A1F', '#FDBB2F', '#377B2B', '#007CC3', '#00529B'];

const sampleViewsData = [
  { name: 'Whitetail', value: 3500 },
  { name: 'Duck - Timber', value: 4000 },
  { name: 'Hog - Night', value: 2250 },
  { name: 'Pheasant', value: 3000 },
  { name: 'Alligator', value: 3500 },
];

function ListingViewsPieChart({ isLoading, listingViewChartData }: IProps) {
  if (isLoading) {
    return (
      <Box sx={{
        mt: 3,
        mb: 3,
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        minHeight: '400px',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!listingViewChartData) {
    listingViewChartData = [0];
  }

  const viewsTotal = listingViewChartData.reduce((total: number, summary: any) => {
    if (typeof summary.value === 'number') {
      return total + Number(summary.value);
    }
    return total;
  }, 0);

  if (viewsTotal < 1) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        paddingY: 5,
        // opacity: .25
        }}
        height={400}
      >
        <Box height={400} width='100%' sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
          opacity: .33
        }}>
          <ResponsiveContainer width='100%' height='100%'>
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={sampleViewsData}
                outerRadius={100}
                fill="#8884d8"
                label={(slice) => { return slice.value.toLocaleString() }}
              >
              {sampleViewsData.map((row: any, index: number) => (
                <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]} style={{outline: 'none'}} />
              ))}
              </Pie>
              <Tooltip formatter={(value) => { return `${value.toLocaleString()}` }} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
          zIndex: 10,
          position: 'absolute'
          }}
        >
          <Typography textAlign='center' maxWidth='65%' fontWeight={600}>
            You're seeing sample data because your listings have not been viewed yet.<br/><br />Be sure to create a listing and contact support for help creating or improving your listings.</Typography>
        </Box>
      </Box>
    )
  }
  
  return (
    <Box height={400} sx={{ paddingY: 5 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={300}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={listingViewChartData}
            outerRadius={100}
            fill="#8884d8"
            label={(slice) => { return slice.value.toLocaleString() }}
          >
          {listingViewChartData.map((row: any, index: number) => (
            <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]} style={{outline: 'none'}} />
          ))}
          </Pie>
          <Tooltip formatter={(value) => { return `${value.toLocaleString()}` }} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ListingViewsPieChart;