import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { formatDateString } from '../../utils/dates';
import toProperCase, { snakeToProper } from '../../utils/strings';
import { ROUTER_URLS } from '../../constants/router-urls';
import type { BookingResultType } from '../../types/booking';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchListings } from '../../hooks/fetchListing';
import { format } from 'date-fns';

interface IProps extends WrappedProps {};

interface ITripSearchParams {
  trip: {
    status: string;
    listing_id: null | string;
  },
  sort: {
    sort: string;
  };
}

function Bookings({ postConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();

  const [bookings, setBookings] = useState<BookingResultType[]>([]);
  const [selectedListingId, setSelectedListingId] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState('New')
  const [loading, setLoading] = useState<boolean>(false);

  const [exportLoading, setExportLoading] = useState(false);

  const { data: listingRecords, isLoading } = useFetchListings(postConfig);

  const handleFilterStatusChange = (status: string) => {
    setFilterStatus(status);
  };

  const navigateToBooking = (id: number) => {
    const path = generatePath(ROUTER_URLS.bookings.show, { id });
    navigate(path);
  };

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    setSelectedListingId(event.target.value);
  };

  useEffect(() => {
    setLoading(true);

    const filterStatusParam = filterStatus ?? 'New';
    const searchParams: ITripSearchParams = {
      trip: {
        status: filterStatusParam,
        listing_id: null,
      },
      sort: {
        sort: 'asc',
      },
    }

    if (['Completed', 'Denied'].includes(filterStatusParam)) {
      searchParams.sort.sort = 'desc';
    }

    if (selectedListingId) {
      searchParams.trip.listing_id = selectedListingId;
    }

    axios.post(API_V3_ALLIGATOR_URLS.booking.list, searchParams, postConfig).then((response) => {
      const bookingData: BookingResultType[] = response.data;
      setBookings(bookingData);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false)
    });

  }, [filterStatus, selectedListingId]);

  const handleExport = () => {
    setExportLoading(true);
    const config: any = { ...postConfig, responseType: 'blob' };
    const params = {
    }
    
    axios.post(API_V3_ALLIGATOR_URLS.booking.export, params, config).then((response) => {
      const csvBlob = new Blob([response.data], { type: 'application/csv' });
      const csvUrl = window.URL.createObjectURL(csvBlob);
      const tempLink = document.createElement('a');
      tempLink.href = csvUrl;
      tempLink.setAttribute(
        'download',
        `approved-bookings-export-${format(new Date, 'M-d-yy')}.csv`
      )
      document.body.appendChild(tempLink);
      tempLink.click();
      
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(csvUrl);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setExportLoading(false);
    });
  }

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Bookings</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          <Button
            onClick={handleExport}
            disabled={exportLoading}
            variant='contained'
            sx={{ height: '45px', marginY: 3, marginLeft: 3, }}
          >
            Download
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} marginBottom={2}>
        <Grid item xs={12} lg={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <ToggleButtonGroup
            color="primary"
            fullWidth
            value={filterStatus}
            exclusive
            onChange={(event: React.MouseEvent<HTMLElement>, value: string | null) => {
              if (value) {
                handleFilterStatusChange(value);
              }
            }}
            aria-label="Platform"
          >
            { ['New', 'Approved', 'Denied', 'Completed'].map((status) => (
              <ToggleButton
                key={status}
                value={status}
              >
                {status}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} lg={7}>
          { !isLoading &&
            <FormControl fullWidth>
              <InputLabel id="booking-status-label">Listing</InputLabel>
              <Select
                labelId="listing-label"
                id="listing-select"
                value={selectedListingId}
                label="Status"
                onChange={handleListingSelectChange}
                fullWidth    
              >
                <MenuItem value="">
                  <em>Clear</em>
                </MenuItem>
                {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => (
                  <MenuItem key={listing.id} value={listing.id.toString()}>{listing.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Grid>
      </Grid>
      { loading ? <LoadingSpinner /> : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Guest</TableCell>
                <TableCell align="left"># of Guests</TableCell>
                <TableCell align="left">Listing</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookings.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover={true}
                    onClick={() => { navigateToBooking(row.id) }}
                  >
                    <TableCell align="left">{formatDateString(row.startDate)} - {formatDateString(row.endDate)}</TableCell>
                    <TableCell align="left">{toProperCase(row.user.name)}</TableCell>
                    <TableCell align="left">{row.numberOfGuests}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.listingName}
                    </TableCell>
                    <TableCell align="left">{snakeToProper(row.status)}</TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
        </TableContainer>)
      }
    </Dashboard>
  );
}

export default withCommonTools(Bookings);