import { useQuery } from "react-query";
import { fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";

export const useReportRecentDeposits = (config: { headers: { Authorization: string } }) => {
  return useQuery<any>(
    ['RecentDepositsReport'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.reports.recentDeposits, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};