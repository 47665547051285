export const fetchById = async (url: string, id?: string, config?: { headers: { Authorization: string } }) => {
  if (!id) throw new Error('error');

  const fetchUrl = `${url}/${id}`;
  const response = await fetch(fetchUrl, config);
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('error');
    }
    const errorBody = await response.json();
    console.log(errorBody);
    if (errorBody?.error) {
      throw new Error(errorBody?.error);
    }
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchList = async (url: string, config: { headers: { Authorization: string } }) => {
  const response = await fetch(url, config);
  if (!response.ok) {
    if (response.status === 401) {
      // signOut();
      throw new Error('error');
    }
    const errorBody = await response.json();
    if (errorBody?.error) {
      throw new Error(errorBody?.error);
    }
    throw new Error('Network response was not ok');
  }
  return await response.json();
};