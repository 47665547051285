import { Dialog, DialogContent, DialogTitle, IconButton, Stack} from '@mui/material';
import { useFetchBooking } from '../../../hooks/fetchBooking';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';
import BookingListingCard from '../BookingCard/BookingListingCard';
import BookingPayoutCard from '../BookingCard/BookingPayoutCard';
import BookingUserCard from '../BookingCard/BookingUserCard';
import CloseIcon from '@mui/icons-material/Close';

interface ConfigType {
  headers: {
      Authorization: string;
  };
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  getConfig: ConfigType;
  tripId?: string;
}



function LoadingModalContent() {
  return (
    <LoadingSpinner />
  );
}

function TripDetailsModalContent({ tripId, getConfig }: { getConfig: ConfigType, tripId: string }) {

  const { data: bookingRecord } = useFetchBooking(getConfig, tripId);
  
  if (!bookingRecord) {
    return <LoadingModalContent />
  }

  const bookingRequested = ['requested'].includes(bookingRecord.status.toLowerCase());
  const firstPaymentCompleted = bookingRecord.userPayments && bookingRecord.userPayments.filter((payment) => payment.status === 'completed').length > 0;
  
  // const hasStripeConnected = Boolean(userRecord?.connectAccountSetup);

  return (
    <Stack margin={3} spacing={3}>
      <BookingListingCard booking={bookingRecord} />
      <BookingUserCard user={bookingRecord.user} chatId={bookingRecord.chatId} status={bookingRecord.status} />
      {!bookingRequested && firstPaymentCompleted && <BookingPayoutCard booking={bookingRecord} />}
      {
        // <BookingFeeDetailsCard
        //   booking={bookingRecord}
        //   confirmBooking={approveBooking}
        //   denyBooking={denyBooking}
        //   hasStripeConnected={hasStripeConnected}
        //   handleStripeClick={handleStripeConnectClick}
        //   userId={userId}
        // />
        }
    </Stack>
  )
}


export default function CalendarTripModal(props: SimpleDialogProps) {
  const { onClose, open, getConfig, tripId } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Booking Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
       <DialogContent dividers>
        { tripId && <TripDetailsModalContent getConfig={getConfig} tripId={tripId} /> }
      </DialogContent>
    </Dialog>
  );
}
