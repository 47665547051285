import React from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { SelectChangeEvent } from '@mui/material';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useFetchAdminListing } from '../../../hooks/fetchListing';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Calendar from '@mui/icons-material/Event';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import type { SelectedAdditionalLineItemType } from '../../../types/lineItem';
import { daysBetweenCalendarDays } from '../../../utils/dates';
import { bookingTotal, lineItemDescriptionForFee } from '../../../utils/lineItems';
import { AdditionalLineItem } from '../AdditionalLineItem/AdditionalLineItem';
import { BasePriceLineItem } from '../BasePriceLineItem/BasePriceLineItem';

interface IProps extends WrappedProps {
  listingId: number | null;
  numberOfGuests: string;
  setNumberOfGuests: (value: string) => void;

  payInFull: boolean;
  setPayInFull: (value: boolean) => void;

  dateRange: DateRange<Date>;
  setDateRange: (value: DateRange<Date>) => void;

  additionalItems: SelectedAdditionalLineItemType[];
  setAdditionalItems: (value: SelectedAdditionalLineItemType[]) => void;

  nextStepPanel: React.ReactElement;
};

function DetailsStep({
  nextStepPanel,
  listingId,
  numberOfGuests,
  setNumberOfGuests,
  payInFull,
  setPayInFull,
  dateRange,
  setDateRange,
  additionalItems,
  setAdditionalItems,
  getConfig
}: IProps) {
  
  const { data: listingRecord, isLoading } = useFetchAdminListing(getConfig, listingId?.toString() ?? '');

  if (isLoading || !listingRecord) {
    return (
      <Grid2 container>
        <Grid2 xs={12}>
          <LoadingSpinner />
        </Grid2>
      </Grid2>
    );
  }

  const requiredFees = listingRecord.additionalCosts.filter((cost) => !cost.isOptional);
  const optionalFees = listingRecord.additionalCosts.filter((cost) => cost.isOptional);

  const selectAdditionalItem = (item: SelectedAdditionalLineItemType) => {
    const copied = [...additionalItems];
    copied.push(item);
    setAdditionalItems(copied);
  };

  const removeAdditionalItem = (id: string) => {
    const copied = [...additionalItems];

    const index = copied.findIndex((bookingFee) => bookingFee.id === id)

    if (index > -1) copied.splice(index, 1);
    setAdditionalItems(copied);
  };

  const updateAdditionalItem = (id: string, quantity: number) => {
    const copied = [...additionalItems];
    const index = copied.findIndex((bookingFee) => bookingFee.id === id)
    if (index > -1) {
      const newItem = {...copied[index]}
      newItem.numberOfItems = quantity
      copied[index] = newItem;
    }
    setAdditionalItems(copied);
  };

  const handleGuestOrDateUpdates = (numberOfGuests: number, numberOfDays: number) => {
    const mapped: SelectedAdditionalLineItemType[] = additionalItems.map((item) => {
      return {
        ...item,
        numberOfGuests,
        numberOfDays,
      }
    });
    setAdditionalItems(mapped);
  }

  const handleChangeNumberOfGuests = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setNumberOfGuests(value);
    const numberOfNights = dateRange?.[0] && dateRange?.[1] ? daysBetweenCalendarDays(dateRange[0], dateRange[1]) : 0;
    const numberOfDays = numberOfNights + 1;
    handleGuestOrDateUpdates(Number(value), numberOfDays);
  }

  const handlePayInFullChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayInFull(event.target.checked);
  };

  const calculatedTotal = bookingTotal(
    listingRecord,
    Number(numberOfGuests),
    dateRange[0] ?? new Date(),
    dateRange[1] ?? new Date(),
    1,
    additionalItems,
    requiredFees,
  );

  return (
    <Grid2 container spacing={2} marginX={3}>
      <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h5'>Add details for the booking</Typography>
      </Grid2>
      

      <Grid2 xs={12}>
          
          <Box sx={{ marginY: 3, paddingX: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="number-of-guests-label">Number of Guests</InputLabel>
              <Select
                labelId="number-of-guests-label"
                id="number-of-guests-select"
                value={numberOfGuests}
                label="Number of Guests"
                onChange={handleChangeNumberOfGuests}
              >
                <MenuItem value={'1'}>1</MenuItem>
                <MenuItem value={'2'}>2</MenuItem>
                <MenuItem value={'3'}>3</MenuItem>
                <MenuItem value={'4'}>4</MenuItem>
                <MenuItem value={'5'}>5</MenuItem>
                <MenuItem value={'6'}>6</MenuItem>
                <MenuItem value={'7'}>7</MenuItem>
                <MenuItem value={'8'}>8</MenuItem>
                <MenuItem value={'9'}>9</MenuItem>
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'11'}>11</MenuItem>
                <MenuItem value={'12'}>12</MenuItem>
                <MenuItem value={'13'}>13</MenuItem>
                <MenuItem value={'14'}>14</MenuItem>
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={['SingleInputDateRangeField']} sx={{ my: 1 }}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  slotProps={{
                    textField: { InputProps: { endAdornment: <Calendar /> }, label: 'Start and End Dates' },
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  value={dateRange}
                  onChange={(newValue) => { setDateRange(newValue) }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <FormControl sx={{ marginY: 1 }}>
              <FormControlLabel control={<Checkbox
                checked={payInFull}
                onChange={handlePayInFullChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Pay in full" />
              <FormHelperText sx={{ marginLeft: 0, marginY: 0 }}>You can also pay a 50% deposit now and a final payment before your trip.</FormHelperText>
            </FormControl>
          </Box>
          

          { optionalFees.length > 0 &&
            <Box sx={{ borderTop: 1,  paddingX: 2 }}>
              { optionalFees.map((fee) => {
                const details = lineItemDescriptionForFee(fee);
                return (
                  <AdditionalLineItem
                    id={fee.id ?? "missing id"}
                    key={fee.id}
                    basePrice={fee.price}
                    label={fee.description}
                    description={details}
                    required={false}

                    numberOfGuests={Number(numberOfGuests)}
                    // numberOfDays={numberOfDays}
                    
                    perPerson={fee.perPerson}
                    perDay={fee.perDay}
                    perNight={fee.perNight}
                    canSpecifyNumber={fee.canSpecifyNumber}
                    selectAdditionalItem={selectAdditionalItem}
                    removeAdditionalItem={removeAdditionalItem}
                    updateAdditionalItem={updateAdditionalItem}

                    startDate={dateRange[0] ?? new Date()}
                    endDate={dateRange[1] ?? new Date()}

                    minNumberItems={fee.minValue}
                    maxNumberItems={fee.maxValue}
                  />
                )
              })}
            </Box>
          }
          
          <Box sx={{ borderTop: 1,  padding: 2 }}>
            { requiredFees.map((fee) => {
                const details = lineItemDescriptionForFee(fee);
                return (
                  <AdditionalLineItem
                    id={fee.id ?? "missing id"}
                    key={fee.id}
                    basePrice={fee.price}
                    label={fee.description}
                    description={details}
                    required={true}

                    numberOfGuests={Number(numberOfGuests)}
                    // numberOfDays={numberOfDays}
                    
                    perPerson={fee.perPerson}
                    perDay={fee.perDay}
                    perNight={fee.perNight}
                    canSpecifyNumber={fee.canSpecifyNumber}
                    updateAdditionalItem={updateAdditionalItem}

                    startDate={dateRange[0] ?? new Date()}
                    endDate={dateRange[1] ?? new Date()}

                    minNumberItems={fee.minValue}
                    maxNumberItems={fee.maxValue}
                  />
                )
              })}

            <BasePriceLineItem
              basePrice={listingRecord.basePrice}
              numberOfGuests={Number(numberOfGuests)}
              // numberOfDays={numberOfDays}
              perPerson={listingRecord.pricePerPerson}
              listingUnit={listingRecord.listingUnit}
              startDate={dateRange[0] ?? new Date()}
              endDate={dateRange[1] ?? new Date()}
            />
          </Box>
          <>
            {
              payInFull ?
              <Box sx={{ borderTop: 1, paddingX: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 2 }}>
                  <Typography component='p'>
                    Total
                  </Typography>
                  <Typography component='p' >
                    ${calculatedTotal.toFixed(2)}
                  </Typography>
                </Box>
              </Box> :
              <>
                <Box sx={{ borderTop: 1, paddingX: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 2 }}>
                    <Typography component='p'>Pay Now</Typography>
                    <Typography component='p'>${(calculatedTotal / 2).toFixed(2)}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 2 }}>
                    <Typography component='p'>Pay Later</Typography>
                    <Typography component='p' >${(calculatedTotal / 2).toFixed(2)}</Typography>
                  </Box>
                </Box>
                
              </>
            }
            
          </>
      </Grid2>






      <Grid2 xs={12}>
        { nextStepPanel }
      </Grid2>
    </Grid2>
  );
}

export default withCommonTools(DetailsStep);
