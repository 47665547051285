import React from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import Box from '@mui/material/Box';
import Dashboard from '../../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import { DataGridPro, GridColumnMenu, GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro';
import type { GridColDef, GridRowId, GridColumnMenuProps, GridPaginationModel } from '@mui/x-data-grid-pro';


import { useFetchAdminContactHosts } from '../../../hooks/fetchContactHost';
import { Stack, Typography } from '@mui/material';
import { displayPhoneNumber } from '../../../utils/phoneNumber';
import type { ContactHostType } from '../../../types/contactHost';

const columns: GridColDef[] = [
  { 
    field: 'listingName',
    headerName: 'Listing Name',
    width: 325,
    valueGetter: (params) => {
      return params.row.listing.name;
    }
  },
  {
    field: 'hostEmail',
    headerName: 'Host Email',
    width: 325,
    valueGetter: (params) => {
      return params.row.host.email;
    }
  },
  {
    field: 'emailAddress',
    headerName: 'Contact Email',
    width: 325,
  },
];

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuFilterItem: null,
      }}
    />
  );
}

interface IProps extends WrappedProps {};

function AdminHostContacts({ getConfig }: IProps) {
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
    GridRowId[]
  >([]);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(newIds);
    },
    [],
  );

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { data, isLoading, isFetching } = useFetchAdminContactHosts(getConfig, { page: paginationModel.page + 1 });

  const handlePaginationChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }

  const ListDisplayComponent = (
    <Box sx={{ marginTop: 5, height: 600, width: '100%' }}>
      <Typography component='h1' variant='h4' sx={{ marginY: 4 }}>Host Contacts</Typography>
      <DataGridPro
        rows={data?.contacts ?? []}
        columns={columns}
        rowThreshold={0}
        pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
        getDetailPanelContent={({ row }: { row: ContactHostType }) => (
          <Stack paddingX={5} paddingY={3} spacing={1}>
            <Typography variant='body1'>Listing: {row.listing.name} - {row.listing.slug}</Typography>
            <Typography variant='body1'>Host: {row.host.firstName} {row.host.lastName} - {row.host.email}</Typography>
            <Typography variant='body1'>Contact: {row.firstName} {row.lastName} - {row.emailAddress} - {displayPhoneNumber(row.phoneNumber)}</Typography>
            <Typography variant='body1'>Subject: {row.subject}</Typography>
            <Typography variant='body1'>Body: {row.body}</Typography>
          </Stack>
        )}
        getDetailPanelHeight={() => 250}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        rowCount={data?.page.count ?? 0}
        checkboxSelection={false}
        disableRowSelectionOnClick
        paginationMode='server'
        pageSizeOptions={[20]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        slots={{ columnMenu: CustomColumnMenu }}
        pagination
        onPaginationModelChange={handlePaginationChange}
        loading={isLoading}
        sx={{
          '.MuiTablePagination-spacer': {
            flex: 'none !important'
          }
        }}
      />
    </Box>
  )

  return (
    <Dashboard>
      { isLoading || isFetching ? <LoadingSpinner /> : ListDisplayComponent }
    </Dashboard>
  );
}

export default withCommonTools(AdminHostContacts);