import React from 'react';
import { Box, Button, List, ListItem, Typography, Stack } from '@mui/material';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import axios from 'axios';

interface IProps extends WrappedProps {};

function SigningTemplateSignUp({ getConfig, postConfig }: IProps) {

  // const { data: waivers, isLoading: loading, refetch: reload } = useFetchSigningTemplates(getConfig);
  // const handleDeleteWaiver = (id: number) => {
  //   axios.delete(`${API_V3_ALLIGATOR_URLS.signingTemplates.destroy}${id}`, postConfig).then(() => {
  //     reload().then(() => {}).catch((e) => {console.log(e)});
  //   }).catch((e) => {
  //     console.log(e)
  //   })
  // }

  const handleSendUserConfirmation = () => {
    axios.post(API_V3_ALLIGATOR_URLS.signingTemplates.verify, {}, postConfig).then((res) => {
      
    }).catch((e) => {
      console.log(e);
    })
  }

  return (
    <Stack sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
      <Typography variant='h6' align='center' fontWeight={600} sx={{ marginY: 2 }}>Follow the steps below to begin using e-waivers</Typography>
      <List sx={{ listStyleType: 'disc' }}>
        <ListItem sx={{ display: 'list-item' }}>Click the button below to agree to Venku sending electronic waiver signature requests on your behalf.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Check the email you logged in with for a confirmation email</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Follow the steps included in the email</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Refresh this page</ListItem>
      </List>
      <Box>
        <Button variant='contained' onClick={handleSendUserConfirmation}>Send email confirmation</Button>
      </Box>
    </Stack>
  );
}

export default withCommonTools(SigningTemplateSignUp);