import { formatPhoneNumber } from 'react-phone-number-input';

export const displayPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return 'N/A';
  if (phone[0] === '(') return phone;
  if (phone[0] === '+') return formatPhoneNumber(phone);

  return formatPhoneNumber(`+1${phone}`) || 'N/A';
};

export const phoneNumberForInput = (phone: string): string => {
  if (phone.slice(0, 2) === '+1') return phone;
  if (phone[0] !== '1') return `+1${phone}`;
  return phone;
};
