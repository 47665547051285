import { loadStripe } from '@stripe/stripe-js';
import type { Stripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

// following setup recommended by stripe :shrug:
// eslint-disable-next-line @typescript-eslint/promise-function-async
const getStripe = () => {
  if ((stripePromise === null || stripePromise === undefined) && process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export default getStripe;