import React from 'react';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchUser } from '../../hooks/fetchUser';
import { Stack } from '@mui/material';


interface IProps extends WrappedProps {};

function UserConnectClose({ getConfig, getCurrentUserId }: IProps) {

  const { data: userRecord, isLoading } = useFetchUser(getConfig);

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading}>
      <Stack>
        <p>Connected!</p>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(UserConnectClose);
