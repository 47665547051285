import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFetchAdminBooking } from '../../../hooks/fetchBooking';
import { Box, Typography  } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import StripeWrapper from '../../../components/compounds/StripeWrapper/StripeWrapper';
import OfflineConfirmation from '../../../components/compounds/OfflineBooking/Confirmation';
import CustomLink from '../../../components/atoms/CustomLink/CustomLink';

interface IProps extends WrappedProps {};

function AdminConfirmOfflineBooking({ getConfig }: IProps) {
  const { id: bookingId } = useParams();
  const [searchParams] = useSearchParams();

  const { data: bookingRecord, isLoading, error } = useFetchAdminBooking(getConfig, bookingId ?? '');
  const setupIntentClientSecret = searchParams.get('setup_intent_client_secret');

  if (error) {
    console.log(error)
  }

  if (!bookingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading}>
      <Grid2 container spacing={2} sx={{ justifyContent: 'center', marginTop: 5 }}>
          
          <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ overflow: 'hidden', borderRadius: 3, height: { xs: 300 }, width: 400 }}>
              <img
                src={bookingRecord.mainListingImageUrl}
                alt={`${bookingRecord.listingName} - Trip Image`}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Grid2>
          
          <Grid2 xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='h5' component='h1'>
              The booking was successfully submitted!
            </Typography>
          </Grid2>

          <Grid2 xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              Details for the booking can be viewed here:
              <CustomLink to={`/admin/booking/${bookingRecord.id}/debug`} text='Booking Details' />
            </Typography>
          </Grid2>

          <Grid2 xs={12} sx={{ textAlign: 'center' }}>

            { setupIntentClientSecret &&
              <StripeWrapper clientSecret={setupIntentClientSecret}>
                <OfflineConfirmation
                  clientSecret={setupIntentClientSecret}
                  tripSlug={bookingId}
                />
              </StripeWrapper>
            }

          </Grid2>
        </Grid2>
    </DashboardLayout>
  )
};

export default withCommonTools(AdminConfirmOfflineBooking);