import { createTheme } from '@mui/material/styles';
import palette from './palette.module.scss';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: palette.primary,
    },
    secondary: {
      main: palette.secondary,
    },
    custom: {
      tertiary: palette.tertiary,
      actionOne: palette.actionOne,
      actionTwo: palette.actionTwo,
      actionDanger: palette.actionDanger,
    },
  },
});

// Typescript module augmentation
declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string;
    darker?: string;
    text?: string;
  }
  interface Palette {
    custom: CustomPaletteColorOptions;
  }
  interface PaletteOptions {
    custom: CustomPaletteColorOptions;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
  interface DisabledPalletteOptions {
    button: string;
  }
  interface CustomPaletteColorOptions {
    tertiary: string;
    actionOne: string;
    actionTwo: string;
    actionDanger: string;
  }
}

export default theme;
