import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from "@mui/material";

interface IProps {
  title: string;
  description: string;
  editPath?: string;
  children?: React.ReactNode;
  titleImagePath?: string;
};

function RecordDetailHeader({ title, description, editPath, children, titleImagePath }: IProps) {
  const navigate = useNavigate();

  const navigateToEdit = () => {
    if (editPath) navigate(editPath);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingY: 2, marginX: 5, marginTop: 5 }}>
      <Grid container direction='row'>
        <Grid item lg={9} xs={12}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Typography variant="h5" marginY={1} marginRight={2}>{ title }</Typography>
            { titleImagePath && <img src={titleImagePath} alt="" height='26px' />}
          </Box>
          <Typography variant="body1">{ description }</Typography>
        </Grid>
        <Grid item lg={3} xs={12} display='flex' justifyContent='end' alignItems='center'>
          { children }
          {editPath && <Button
            type="submit"
            // variant="contained"
            onClick={navigateToEdit}
            // color='secondary'
            sx={{
              marginLeft: 2
            }}
          >
            Edit
          </Button>}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecordDetailHeader;