import React from 'react';
import { Grid, Typography } from '@mui/material';
import type { BookingDetailType } from '../../../types/booking';
import formatCurrency from '../../../utils/currency';
import { formatDateString } from '../../../utils/dates';
import { buildPayoutInformation } from '../../../utils/payout';

interface IProps {
  booking: BookingDetailType;
};

function PayoutSchedule({ booking }: IProps) {
  const payout = buildPayoutInformation(booking);

  const depositInfo = `$${formatCurrency(payout.depositAmount)} on ${formatDateString(payout.depositDate)}`;
  const finalPayoutInfo = payout.finalPayoutAmount > 0 ? `$${formatCurrency(payout.finalPayoutAmount)} on ${formatDateString(payout.finalPayoutDate)}` : 'Pending - Contact Support for Details';

  return (
    <>
      <Grid container marginY={2}>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ fontWeight: 600, marginRight: 1 }}>{payout.depositPaidOut ? '' : 'Scheduled '}Deposit Payout:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ width: '100%' }}>{ depositInfo }</Typography>
        </Grid>
      </Grid>
      <Grid container marginY={2}>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ fontWeight: 600, marginRight: 1, width: 120 }}>{payout.finalPaidOut ? '' : 'Scheduled '}Final Payout:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{ finalPayoutInfo }</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PayoutSchedule;