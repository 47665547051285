import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import { useAuthHeader } from 'react-auth-kit'
import { SearchNavHamburgerStyles, SearchNavContainerStyles, SearchNavLabelStyles } from './StyleOverrides';
import { Badge, Button, styled } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getSessionStorageOrDefault, removeSessionStorageItem } from '../../../utils/storage';
import { ROUTER_URLS } from '../../../constants/router-urls';

import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

import UserPopover from './UserPopover';


const StyledLink = styled(Link)`
    

    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: inherit;
    }
`;

interface IProps extends WrappedProps {
  toggle: () => void;
  unreadMessageCount: number;
  userProfileImage?: string;
  userFullName: string;
};

function NavBar({ toggle, postConfig, unreadMessageCount, userProfileImage, userFullName }: IProps) {
  const navigate = useNavigate();

  const authHeader = useAuthHeader();
  const token = authHeader();

  const [impersonatedUser, setImpersonatedUser] = useState<string>();

  useEffect(() => {
    const name = getSessionStorageOrDefault("impersonateUserName");
    setImpersonatedUser(name);
  }, []);


  const stopImpersonatingUser = () => {
    removeSessionStorageItem("impersonateUserName");
    removeSessionStorageItem("impersonateUserId");
    removeSessionStorageItem("impersonateUserToken");
    navigate(ROUTER_URLS.admin.users.index);
    setImpersonatedUser(undefined);
  };

  return (
    <Box flexGrow={1} flexDirection='column'>
      <AppBar
        position="fixed"
        sx={SearchNavContainerStyles}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggle}
            edge="start"
            sx={SearchNavHamburgerStyles}
          >
            <MenuIcon />
          </IconButton>
          
          <StyledLink to="/">
          <Typography
            variant="h5"
            noWrap
            component="div"
            
            sx={SearchNavLabelStyles}
          >
            Host Dashboard
          </Typography>
          </StyledLink>
          <Box sx={{ flexGrow: 1 }} />
          { impersonatedUser &&
            <>
              <Typography
                variant="h5"
                noWrap
                component="div"
                marginX={2}
              >
                Viewing App As: {impersonatedUser}
              </Typography>
              <Button
                onClick={stopImpersonatingUser}
                className="upload-button"
                variant="contained"
                color="warning"
                size="small"
                sx={{
                  marginX: 2,
                }}
              >
                Stop Impersonating
              </Button>
            </>
          }
          <IconButton
            size="large"
            aria-label="new message"
            color="inherit"
            component={Link}
            to="/messages"
            sx={{ marginRight: 2, padding: 2 }}
          >
            <Badge badgeContent={unreadMessageCount} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          
          <Box sx={{ flexGrow: 0 }}>
            <UserPopover
              postConfig={postConfig}
              thumbnailUrl={userProfileImage}
              token={token}
              userFullName={userFullName}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default withCommonTools(NavBar);
