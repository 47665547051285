// export const createImage = (url: any) =>
//   new Promise((resolve, reject) => {
//     const image = new Image()
//     image.addEventListener('load', () => resolve(image))
//     image.addEventListener('error', (error) => reject(error))
//     image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
//     image.src = url
//   })

export function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: any, height: any, rotation: any) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

const createImage = async (url: string) =>
    await new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => { resolve(image) })
        image.addEventListener('error', error => { reject(error) })
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })

export const getCroppedImg = async (imageSrc: string, crop: any) => {
    const image = await createImage(imageSrc) as CanvasImageSource;
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    /* setting canvas width & height allows us to 
    resize from the original image resolution */
    canvas.width = 250
    canvas.height = 250

    if (!image) {
      throw new Error("Failed to generate image");
      
    }

    if (!ctx) {
      throw new Error("Failed to render ctx");
    }

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )

    return await new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob)
        }, 'image/jpeg')
    })
}

// const image = await createImage(imageSrc)
//       const canvas = document.createElement('canvas')
//       const ctx = canvas.getContext('2d')
// 
//       /* setting canvas width & height allows us to 
//       resize from the original image resolution */
//       canvas.width = 250
//       canvas.height = 250
// 
//       ctx.drawImage(
//           image,
//           crop.x,
//           crop.y,
//           crop.width,
//           crop.height,
//           0,
//           0,
//           canvas.width,
//           canvas.height
//       )

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
// export default async function getCroppedImg(
//   imageSrc: string,
//   pixelCrop: any,
//   rotation = 0,
//   newHeight?: number,
//   newWidth?: number,
//   flip = { horizontal: false, vertical: false },
// ) {
//   const image: any = await createImage(imageSrc)
//   console.log(image);
//   const canvas = document.createElement('canvas')
//   // console.log(canvas)
//   const ctx = canvas.getContext('2d')
// 
//   if (!ctx) {
//     return null
//   }
// 
//   // const rotRad = getRadianAngle(rotation)
// 
//   // calculate bounding box of the rotated image
//   const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
//     image.width,
//     image.height,
//     rotation
//   )
// 
//   // set canvas size to match the bounding box
//   canvas.width = bBoxWidth;
//   canvas.height = bBoxHeight;
// 
//   // translate canvas context to a central location to allow rotating and flipping around the center
//   // ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
//   // ctx.rotate(rotRad)
//   // ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
//   // ctx.translate(-image.width / 2, -image.height / 2)
// 
//   // draw rotated image
//   ctx.drawImage(image, 0, 0)
// 
//   const croppedCanvas = document.createElement('canvas')
// 
//   const croppedCtx = croppedCanvas.getContext('2d')
// 
//   if (!croppedCtx) {
//     return null
//   }
// 
//   // Set the size of the cropped canvas
//   croppedCanvas.width = pixelCrop.width
//   croppedCanvas.height = pixelCrop.height
// 
//   // console.log(pixelCrop.width);
//   // console.log(pixelCrop.height);
//   // Draw the cropped image onto the new canvas
//   // croppedCtx.drawImage(
//   //   canvas,
//   //   pixelCrop.x,
//   //   pixelCrop.y,
//   //   500, // pixelCrop.width,
//   //   500, // pixelCrop.height,
//   //   // 0,
//   //   // 0,
//   //   croppedCanvas.width,
//   //   croppedCanvas.height,
//   //   pixelCrop.width,
//   //   pixelCrop.height
//   // )
//   croppedCtx.drawImage(
//     canvas,
//     pixelCrop.x,
//     pixelCrop.y,
//     pixelCrop.width,
//     pixelCrop.height,
//     0, // pixelCrop.x,
//     0, // pixelCrop.y,
//     croppedCanvas.width,
//     croppedCanvas.height,
//   )
// 
//   // As Base64 string
//   // return croppedCanvas.toDataURL('image/png');
// 
//   // return croppedCanvas.toBlob((file) => {
//   //   // if (file) {
//   //     // resolve(URL.createObjectURL(file))
//   //   // }
//   //   if (file) {
//   //     URL.createObjectURL(file)
//   //   }
//   // }, 'image/png')  
// 
//   // As a blob
//   // return new Promise((resolve, reject) => {
//   //   croppedCanvas.toBlob((file) => {
//   //     // if (file) {
//   //       resolve(URL.createObjectURL(file))
//   //     // }
//   //   }, 'image/png')
//   // })
// 
//   try {
//     const dataBlob = await new Promise<Blob | null>(
//       (resolve) => croppedCanvas.toBlob(
//         (blob) => resolve(blob),
//         "image/png",
//       )
//     );
//     console.log(dataBlob);
//     return dataBlob;
//   } catch (e) {
//     console.log(e);
//   }
// }