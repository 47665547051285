import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../constants/router-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchWaiverEnabled } from '../../hooks/fetchSigningTemplate';
import SigningTemplatesTable from '../../components/compounds/SigningTemplate/SigningTemplatesTable';
import SigningTemplateSignUp from '../../components/compounds/SigningTemplate/SigningTemplatesSignUp';

interface IProps extends WrappedProps {};

function WaiverPageBody({ enabled }: { enabled: boolean }) {
  return enabled ? <SigningTemplatesTable /> : <SigningTemplateSignUp />
}

function WaiversTemplates({ getConfig }: IProps) {
  const navigate = useNavigate();

  const { data: userWaiverEnabled, isLoading } = useFetchWaiverEnabled(getConfig);

  const navigateToNewWaiver = () => {
    navigate(ROUTER_URLS.waivers.waiverUpload);
  };

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Waiver Templates</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          {Boolean(userWaiverEnabled?.boldsign) && <Button
              type="submit"
              variant="contained"
              onClick={navigateToNewWaiver}
              color='secondary'
            >
              Configure Waiver Templates
          </Button>}
        </Grid>
      </Grid>
      { isLoading ? <LoadingSpinner /> : <WaiverPageBody enabled={Boolean(userWaiverEnabled?.boldsign)} /> }
    </Dashboard>
  );
}

export default withCommonTools(WaiversTemplates);