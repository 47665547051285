import React, { useState, useEffect } from 'react';

interface IProps {
  file: File,
  styleOverrides?: React.CSSProperties;
}

function ImagePreview({ file, styleOverrides }: IProps) {
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null);
  
  useEffect(() => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewUrl(reader.result)
    }

    reader.readAsDataURL(file);
  }, [])

  return (
    <>
    { previewUrl && <img src={previewUrl as string} alt="Preview" style={styleOverrides} /> }
    </>
  );
}

export default ImagePreview;