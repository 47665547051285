import * as React from 'react';
import { IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';

interface IProps {
  id: string;
  slug: string;
}

export default function LandingCardActions({ id, slug }: IProps) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToEdit = (id: string) => {
    const path = generatePath(ROUTER_URLS.landings.edit, { id });
    navigate(path);
  };

  return (
    <div>
      <IconButton
        aria-label="options"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { navigateToEdit(id) }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem disabled>
          <ListItemIcon>
            <ShareIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <ListItemText>Share on Social</ListItemText>
        </MenuItem>
        <Link target='_blank' rel="noopener" href={`https://www.huntpay.com/packages/${slug}`} underline='none' color='text.primary'>
          <MenuItem>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" color='primary' />
            </ListItemIcon>
            <ListItemText>Preview</ListItemText>
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}