import React, { useEffect, useState } from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { useFetchAdminPayout } from '../../../hooks/fetchPayout';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { ROUTER_URLS } from '../../../constants/router-urls';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { dateFromISOString, updateTimeToCurrent } from '../../../utils/dates';
import { formatISO } from 'date-fns';
import toProperCase from '../../../utils/strings';

interface IProps extends WrappedProps {};

const PAYOUT_STATUSES = ['Scheduled', 'Processing', 'Error', 'Completed', 'Cancelled', 'Imported']

function AdminEditPayout({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();

  const { id: payoutId } = useParams();

  const [amount, setAmount] = useState('');
  const [payoutDate, setPayoutDate] = useState<Date | null>(null);
  const [status, setStatus] = useState('');
  const [attemptCount, setAttemptCount] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const { data: payoutRecord, isLoading } = useFetchAdminPayout(getConfig, payoutId ?? '');

  useEffect(() => {
    if (payoutRecord) {
      setPayoutDate(dateFromISOString(payoutRecord.payoutDate))
      setAmount((payoutRecord.amount / 100).toFixed(2));
      setStatus(toProperCase(payoutRecord.status));
      setAttemptCount(payoutRecord.attemptCount.toString());
    }
  }, [payoutRecord]);

  if (!payoutRecord) {
    return <DashboardLayout loading />;
  }

  const handleBack = () => {
    navigate(ROUTER_URLS.admin.payouts.index);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params = {
      purchase_payout: {
        amount: Number(amount) * 100,
        payout_date: payoutDate ? formatISO(payoutDate) : '',
        status: status.toLowerCase(),
        attempt_count: Number(attemptCount),
      }
    }
    axios.put(`${API_V3_ALLIGATOR_URLS.admin.payouts.update}${payoutId ?? ''}`, params, postConfig).then(() => {
      navigate(ROUTER_URLS.admin.payouts.index);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };


  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Payout Details
        </Typography>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Amount"
            value={amount}
            onChange={(e) => { setAmount(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Payout Date"
              value={payoutDate}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setPayoutDate(newValue)
              }}
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Number of attempts"
            value={attemptCount}
            onChange={(e) => { setAttemptCount(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={status}
              label="Status"
              onChange={handleStatusChange}
              fullWidth
            >
              { PAYOUT_STATUSES.map((status: string) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>  
  );
}

export default withCommonTools(AdminEditPayout);