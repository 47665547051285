import React from 'react';
import { Box, Paper, Typography } from "@mui/material";

interface IProps {
  headerTitle: string;
  children: React.ReactElement;
};

function PaperWithHeader({ headerTitle, children }: IProps) {
  return (
    <Paper elevation={2} sx={{ borderRadius: 3, height: '100%' }}>
      <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
        <Typography
          variant='body1'
          component='p'
          align='center'
          sx={{
            mt: 3,
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '75%',
            alignItems: 'center',
            fontWeight: 600,
          }}
        >
          { headerTitle }
        </Typography>
      </Box>
      { children }
    </Paper>
  )
};

export default PaperWithHeader;