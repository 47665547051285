import { useQuery } from "react-query";
import { fetchById, fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { UserDetailType, AdminUserDetailType } from "../types/user";

export const useFetchUser = (config: { headers: { Authorization: string } }) => {
  return useQuery<UserDetailType>(
    ['UserInfo'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.users.profile, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchUserAsAdmin = (config: { headers: { Authorization: string } }, userId?: string, ) => {
  return useQuery<AdminUserDetailType>(
    ['AdminUserInfo', userId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.admin.users.show, userId, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};