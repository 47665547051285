export enum MessagePriority {
  Normal = 0,
  Warning = 1,
  Billing = 2,
  System = 3,
}

export enum BookingStatus {
  Requested = 0,
  Approved = 1,
  Denied = 2,
  PaymentFailed = 3,
  PaymentSuccessful = 4,
  Completed = 5,
  Reviewed = 6,
  TransferFailed = 7,
  TransferSuccessful = 8,
  CancellationRequested = 9,
  Cancelled = 10,
}

export enum ListingUnit {
  PerDay = 0,
  PerNight = 1,
  PerExperience = 2,
  PerLease = 3,
}

export enum BookingPaymentStatus {
  Pending = 0,
  Paid = 1,
  Error = 2,
}

export enum ListingStatus {
  Active = 0,
  Expired = 1,
  Pending = 2,
}

export enum ListingSkillLevel {
  All = 0,
  Beginner = 1,
  Intermediate = 2,
  Advanced = 3,
}

export enum RegionScope {
  National = 0,
  Regional = 1,
  State = 2,
  ZipCodes = 3,
}

export enum ListingMapOption {
  Exact = 0,
  Radius = 1,
}

export enum UserType {
  User = 0,
  Host = 1,
  Admin = 2,
}
