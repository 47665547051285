import React, { useState } from 'react';
import axios from 'axios';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
// import { DEFAULT_NEW_LISTING_PARAMS, LISTING_CATEGORIES, newListingSteps } from '../../constants/listings';
import { LISTING_CATEGORIES, newListingSteps } from '../../constants/listings';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import type { ListingCategoryType, ListingDetailType } from '../../types/listing';
import { ROUTER_URLS } from '../../constants/router-urls';
import AddableAutocomplete from '../../components/atoms/AddableAutocomplete/AddableAutocomplete';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
// import toProperCase from '../../utils/strings';
import { listingStepOneValid } from '../../utils/listing';

interface IProps extends WrappedProps {};

function NewListing({ postConfig }: IProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const activeStep = 0;
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);

  const hostId = searchParams.get('hostId');

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleShortDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShortDescription(event.target.value);
  }

  const handleNext = () => {
    const params = {
      listing: {
        name: title,
        category: category,
        short_description: shortDescription,
        keywords,
        host_id: hostId,
      }
    };

    // @todo: handle failure to save listing
    // handle the loading state when trying to save the listing
    axios.post(API_V3_ALLIGATOR_URLS.listing.create, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        // @todo: optimization - we should be able to pass this returned listing record as part of the nav state
        // then if the new page can find and load the passed state skip refetching the listing from the api
        const editListingUrl = generatePath(ROUTER_URLS.listings.editAddress, { id: newListingData.id });
        navigate({
          pathname: editListingUrl,
        });
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });

  };

  return (
    <Dashboard>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
          />
          <Grid container direction='column' spacing={2} padding={2}>
            <Grid item>
              <Grid container direction='row' spacing={5}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Title"
                    value={title}
                    onChange={handleTitleChange}
                    fullWidth
                    placeholder="Example: Canoe Fishing on Walden Pond"
                    helperText="Listing title will be present on search"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label" required>Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={category}
                      label="Category"
                      onChange={handleCategoryChange}
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      { LISTING_CATEGORIES.map((option: ListingCategoryType) => (
                        <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Pick a category for your listing</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>  
              <Grid container direction='row' spacing={5}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Short description"
                    value={shortDescription}
                    onChange={handleShortDescriptionChange}
                    fullWidth
                    helperText="This will be visible in search results along with your title"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row' spacing={5}>
                <Grid item xs={12} lg={12}>
                  <AddableAutocomplete
                    value={keywords}
                    setValue={setKeywords}
                    defaultOptions={[]}
                    label="Keywords"
                    helperText="Add tags to your listing by typing and selecting them"
                    required={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item xs={10} lg={10} marginTop={5}>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button disabled={!listingStepOneValid(title, category, shortDescription)} onClick={handleNext}>
                  { "Save & Next" }
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default withCommonTools(NewListing);
