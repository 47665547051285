import React, { useState } from 'react';
import { Button, Checkbox, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import type { UserResultType } from '../../../types/user';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import Grid2 from '@mui/material/Unstable_Grid2';
import toProperCase from '../../../utils/strings';
import { formatPhoneNumber } from 'react-phone-number-input';

interface IProps extends WrappedProps {
  userId: number | null;
  setUserId: (value: number | null) => void;
  setUserName: (value: string) => void;
  nextStepPanel: React.ReactElement;
};

function UserStep({ nextStepPanel, userId, setUserId, setUserName, postConfig }: IProps) {
  const [users, setUsers] = useState<UserResultType[]>([]);
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const searchUsers = () => {
    setLoading(true);

    const params = {
      items: 5,
      user: {
        email: email,
        user_type: ['user'],
      }
    }

    axios.post(API_V3_ALLIGATOR_URLS.admin.users.list, params, postConfig).then((response) => {
      const usersData = response.data;
      setUsers(usersData.users);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <Grid2 container>
        <Grid2 xs={12}>
          <LoadingSpinner />
        </Grid2>
      </Grid2>
    );
  }

  return (
    <Grid2 container spacing={2} marginX={3}>
      <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h5'>Find the user by email</Typography>
      </Grid2>
      <Grid2 xs={12} marginY={3}>
        <Stack spacing={5} direction='row' sx={{ display: 'flex', alignContent: 'center' }}>
          <TextField
            id="outlined-search"
            label=""
            type="search"
            placeholder="Email"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: (theme: any) => theme.palette.primary.main }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            sx={{ maxWidth: 600 }}
          />
          <Button
            type="submit"
            variant="contained"
            onClick={searchUsers}
            disabled={loading}
          >
            Search
          </Button>
        </Stack>
      </Grid2>
      <Grid2 xs={12} minHeight={300}>
        {users.length > 0 && <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Phone Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, index) => {
                const isItemSelected = userId === row.id;
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover={true}
                    onClick={() => {
                      if (isItemSelected) {
                        setUserId(null);
                        setUserName('');
                      } else {
                        setUserId(Number(row.id))
                        setUserName(`${toProperCase(row.firstName)} ${toProperCase(row.lastName)}`)
                      }
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">{row.email}</TableCell>
                    <TableCell align="left">{toProperCase(row.firstName)} {toProperCase(row.lastName)}</TableCell>
                    <TableCell align="left">{row.phoneNumber ? formatPhoneNumber(row.phoneNumber) : 'N/A'}</TableCell>
                  </TableRow>
                )})
              }
            </TableBody>
          </Table>
        </TableContainer>}
        {
          users.length === 0 &&
          <Typography variant='h6' sx={{ textAlign: 'center', marginTop: 3 }}>No users found. Please search by email to field to find.</Typography>
        }
      </Grid2>
      <Grid2 xs={12}>
        { nextStepPanel }
      </Grid2>
    </Grid2>
  );
}

export default withCommonTools(UserStep);
