import React, { useEffect, useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchMessage } from '../../hooks/fetchMessage';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';

// import axios from 'axios';
// import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';

import Grid2 from '@mui/material/Unstable_Grid2';
import { formatDateString } from '../../utils/dates';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';

interface IProps extends WrappedProps {};

function Message({ getConfig, postConfig }: IProps) {
  const { id: messageId } = useParams();
  const navigate = useNavigate();
  
  const [response, setResponse] = useState('');
  const [messageSending, setMessageSending] = useState(false);

  const { data: message, isLoading, refetch } = useFetchMessage(getConfig, messageId ?? '');
  
  useEffect(() => {
    
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [message])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResponse(e.target.value);
  };

  const handleSubmit = () => {
    setMessageSending(true);
    const params = {
      body: response,
      chat_id: message?.id,
    };
    axios.post(API_V3_ALLIGATOR_URLS.messages.create, params, postConfig).then((response) => {
      setResponse('');
      refetch().then(() => {
        setMessageSending(false);
      }).catch((e) => {
        console.log(e);
        setMessageSending(false);
      });
    }).catch((error) => {
      console.log(error);
      setMessageSending(false);
    });
  };

  const handleBack = () => {
    // const path = generatePath(ROUTER_URLS.messages.index);
    // navigate(path);
    navigate(-1);
  };

  if (!message) {
    return <DashboardLayout loading />;
  }

  // const participants = message.participants.map(person => `${person.firstName} ${person.lastName}`).join(', ');

  return (
    <DashboardLayout loading={isLoading || messageSending}>
      <Stack spacing={2} marginY={2} marginX={2}>
        <Typography variant='body1' fontSize={25} sx={{ fontWeight: 600 }}>Subject: { message.subject }</Typography>
        {message.messages.map((row) => {
          return (
            <div key={row.id}>
              <Grid2 container>
                <Grid2 xs={8}>
                  <Typography variant='body1' fontSize={16} sx={{ fontWeight: 600 }}>{ row.senderName }</Typography>
                </Grid2>
                <Grid2 xs={4}>
                  <Typography variant='body1' fontSize={16} sx={{ textAlign: 'right' }}>{ formatDateString(row.createdAt, 'PPp') }</Typography>
                </Grid2>
                {
                // <Grid2 xs={12}>
                //   <Typography variant='body1' fontSize={16}>{ participants }</Typography>
                // </Grid2>
                }
                <Grid2 xs={12} marginY={2}>
                  <Typography variant='body1' fontSize={16}>{ row.body }</Typography>
                </Grid2>
                <Grid2 xs={12}>
                  <Divider />
                </Grid2>              
              </Grid2>
            </div>
          )
        })}
        <TextField value={response} onChange={handleChange} rows={4} multiline fullWidth sx={{ marginY: 4}} label="Reply" />
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} disabled={response.length < 1} sx={{ mx: 2 }}>Send</Button>
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(Message);