import { useRef, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import type { UploadedFileInterface } from '../../components/atoms/ListingFileUploadInput/ListingFileUploadInput';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import WaiverFileUploadInput from '../../components/atoms/WaiverFileUploadInput/WaiverFileUploadInput';
import './styling.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../constants/router-urls';

interface IProps extends WrappedProps {};

function WaiverUploads({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const [boldSignWaiverUrl, setBoldSignWaiverUrl] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileInterface[]>([]);
  const imagesToUploadCount = useRef(0);

  const navigate = useNavigate();
  window.addEventListener("message", function (params) {
    if (params.origin !== "https://app.boldsign.com") {
        return;
    }

    switch (params.data.action) {
        case "TemplateCreate":
        case "DraftSave":
            navigate(ROUTER_URLS.waivers.index);
          break;
        default:
          break;
    }
});
  return (
    <DashboardLayout loading={false}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
        {!boldSignWaiverUrl && <Grid container direction='row' justifyContent="center">
            <Typography variant='h5' component='h5' align='center' sx={{ mt: 3, mb: 3, display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '75%', alignItems: 'center' }}>Upload your waiver here!</Typography>
          </Grid>}
          {!boldSignWaiverUrl && <Grid container direction='row'>
            <WaiverFileUploadInput
              promptText='Drag and drop your PDF here or'
              buttonText='Upload waiver'
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              postConfig={postConfig}
              filesToUploadCount={imagesToUploadCount}
              // fileAlreadyUploaded={imageAlreadyUploaded}
              setBoldSignTemplateUrl={setBoldSignWaiverUrl}
            />
          </Grid>}
          {boldSignWaiverUrl && boldSignWaiverUrl.length > 0 && <Grid container direction='row'>
            <iframe title="boldSignConnection" width="100%" height="100%" id="boldsign-iframe" src={boldSignWaiverUrl}></iframe>
          </Grid>}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(WaiverUploads);
