// import { addDays, formatISO, subDays } from "date-fns";
import type { BookingDetailType, BookingHostPayoutType } from "../types/booking"
import type { PayoutType } from "../types/payout"
// import { dateFromISOString } from "./dates";

// const calculateDepositAmount = (booking: BookingDetailType) => {
//   // return (booking.subTotal * 0.5) - booking.hostFees;
//   return 0;
// };

// const calculateFinalAmount = (booking: BookingDetailType) => {
//   const depositAmount = calculateDepositAmount(booking);
//   // return booking.subTotal - depositAmount - booking.hostFees;
//   return 0;
// };

const PAID_OUT_STATUS = 'completed';

export const buildPayoutInformation = (booking: BookingDetailType): PayoutType => {
  if (booking.payouts && booking.payouts.length > 1) {
    const sortedPayouts = booking.payouts.sort((a, b) => a.payoutNumber - b.payoutNumber);
    const deposit = sortedPayouts[0];
    const final: BookingHostPayoutType | undefined = sortedPayouts[1];
    return {
      depositPaidOut: deposit.status === PAID_OUT_STATUS,
      depositAmount: deposit.amount / 100,
      depositDate: deposit.payoutDate,
      finalPaidOut: final.status === PAID_OUT_STATUS,
      finalPayoutAmount: final.amount / 100,
      finalPayoutDate: final.payoutDate,
    };
  } else if (booking.payouts && booking.payouts.length === 1) {
    const deposit = booking.payouts[0];
    return {
      depositPaidOut: deposit.status === PAID_OUT_STATUS,
      depositAmount: deposit.amount / 100,
      depositDate: deposit.payoutDate,
      finalPaidOut: false,
      finalPayoutAmount: 0,
      finalPayoutDate: '',
    };
  } else {
    return {
      depositPaidOut: false,
      depositAmount: 0,
      depositDate: '',
      finalPaidOut: false,
      finalPayoutAmount: 0,
      finalPayoutDate: '',
    };
  }
}

