import React from 'react';
import ReactQuill from 'react-quill';
import { Typography } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import './styles.css';

interface IProps {
  label: string;
  value: string;
  setValue: (newValue: string) => void;
}

function RichTextEditor({ label, value, setValue}: IProps) {
  return (
    <>
      <div id="container">
        <div id="header">
          <Typography
            variant='body1'
            component='p'
            align='center'
            sx={{
              mt: 1,
              mb: 1,
              display: 'flex',
            }}
          >
            { label }
          </Typography>
        </div>
          <div id="editor-container">
            <ReactQuill
              id="editor"
              theme="snow"
              value={value}
              onChange={setValue}
              modules={{
                clipboard: {
                  matchVisual: false
                }
              }}
            />
          </div>
        <div id="footer" />
      </div>
    </>
  );
};

export default RichTextEditor;