import { useAuthUser } from "react-auth-kit";

type AuthRequiredActionsType = 'tripsExport' | 'tripEdit' | 'listingExport' | 'listingEdit' | 'userImpersonate' | 'userEdit' | 'userCreate' | 'userNewListing'

type AuthRequiredActionsMap = { [action in AuthRequiredActionsType]: string[] }

const AUTH_REQUIRED_ACTIONS: AuthRequiredActionsMap = {
  tripsExport: ['admin'],
  tripEdit: ['admin'],
  listingExport: ['admin', 'support'],
  listingEdit: ['admin', 'support', 'sales'],
  userImpersonate: ['admin', 'support'],
  userEdit: ['admin', 'support'],
  userCreate: ['admin', 'support'],
  userNewListing: ['admin', 'support']
}

export const useUserCanAccess = (requestedAction: AuthRequiredActionsType) => {
  const auth = useAuthUser();
  const userInfo = auth();
  const roles = AUTH_REQUIRED_ACTIONS[requestedAction];
  return userInfo && roles && roles.includes(userInfo.role);
};

export const useUserCanAccessBookNow = () => {
  const auth = useAuthUser();
  const userInfo = auth();
  return Boolean(userInfo?.accessBookNowApp);
}