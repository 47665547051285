import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { ROUTER_URLS } from "../../../constants/router-urls";
import { useFetchDashboardRecentDeposits } from "../../../hooks/fetchDashboard";
import CustomLink from "../../atoms/CustomLink/CustomLink";
import SignificantNumberCard from "../SignificantNumberCard/SignificantNumberCard";

interface IProps {
  getConfig: {
    headers: {
        Authorization: string;
    };
  }
}

export default function RecentDepositsCard({ getConfig }: IProps) {

  const [daysAgo, setDaysAgo] = useState('30')

  const { data: dashboardMetrics, isLoading: dashboardMetricsLoading } = useFetchDashboardRecentDeposits(getConfig, { days_back: Number(daysAgo) });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaysAgo((event.target as HTMLInputElement).value);
  };

  let count = 0;
  if (dashboardMetrics?.total && dashboardMetrics.total > 0) {
    count = dashboardMetrics.total / 100
  }

  return (
    <SignificantNumberCard
      header="Deposits"
      loading={dashboardMetricsLoading}
      count={count}
      currency
      variant='h2'
      navLink={
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          borderTop: 1,
          borderColor: 'divider',
          width: '100%',
          paddingY: 2 
        }}>
          <CustomLink to={ROUTER_URLS.reports.recentDeposits} text="View All Deposits" />
        </Box>
      }
      toggles={
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={daysAgo}
            onChange={handleChange}
            row
          >
            <FormControlLabel value="30" control={<Radio />} label="Last 30 Days" />
            <FormControlLabel value="365" control={<Radio />} label="Last 12 Months" />
          </RadioGroup>
        </FormControl>
      }
    />
  );
}
