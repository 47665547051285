import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import PaperWithHeader from '../PaperWithHeader/PaperWithHeader';
import { useFetchWaivers } from '../../../hooks/fetchWaiver';
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import toProperCase from '../../../utils/strings';
import DrawIcon from '@mui/icons-material/Draw';
import { green, grey } from '@mui/material/colors';

interface IProps extends WrappedProps {
  tripId: number;
};

function BookingTodoListCard({ tripId, getConfig }: IProps) {

  const { data: waivers } = useFetchWaivers(getConfig, tripId);
  
  if (!(waivers && waivers.length > 0)) {
    return (<></>);
  }

  return (
    <PaperWithHeader headerTitle='Trip Todos'>
      
      <List sx={{ paddingY: 3, paddingX: 2 }}>
        {waivers.map((waiver) => {
          const itemColor = waiver.status === 'signed' ? green[500] : grey[500];
          return (
            <ListItem
              // secondaryAction={
              //   <IconButton edge="end" aria-label="delete">
              //     <DrawIcon />
              //   </IconButton>
              // }
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: itemColor }}>
                  <DrawIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Trip Waiver"
                secondary={toProperCase(waiver.status ?? '')}
              />
            </ListItem>
          )
        })}
      </List>
    </PaperWithHeader>
  );
}

export default withCommonTools(BookingTodoListCard);