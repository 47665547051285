import { useQuery } from "react-query";
import { fetchById, fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { LandingType } from "../types/landing";



export const useFetchLanding = (configHeaders: { headers: { Authorization: string } }, landingId?: string) => {
  const config = {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: true,
  };
  return useQuery<LandingType>(
    ['LandingInfo', landingId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.landing.show, landingId, configHeaders),
    config,
  );
};

export const useFetchLandings = (configHeaders: { headers: { Authorization: string } }) => {
  return useQuery<LandingType[]>(
    ['LandingsInfo'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.landing.list, configHeaders),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};
