import axios from "axios";
import { useQuery } from "react-query";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { ReferralMetricType } from "../types/referralMetric";

export const useFetchReferralCode = (config: { headers: { Authorization: string } }) => {
  return useQuery<{vrp_code: string}>(
    ['ReferralCodes'],
    async () => {
      const response = await axios.get(API_V3_ALLIGATOR_URLS.referralCode.show, config);

      if (response.status !== 200) {
        if (response.status === 401) {
          throw new Error('error');
        }
        throw new Error('Network response was not ok');
      }
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useFetchReferralMetrics = (config: { headers: { Authorization: string } }) => {
  return useQuery<ReferralMetricType>(
    ['ReferralMetrics'],
    async () => {
      const response = await axios.get(API_V3_ALLIGATOR_URLS.referralMetrics.show, config);

      if (response.status !== 200) {
        if (response.status === 401) {
          throw new Error('error');
        }
        throw new Error('Network response was not ok');
      }
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};