import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchSigningTemplates } from '../../../hooks/fetchSigningTemplate';

interface IProps extends WrappedProps {};

function SigningTemplateTable({ getConfig, postConfig }: IProps) {

  const { data: waivers, isLoading: loading, refetch: reload } = useFetchSigningTemplates(getConfig);
  const handleDeleteWaiver = (id: number) => {
    axios.delete(`${API_V3_ALLIGATOR_URLS.signingTemplates.destroy}${id}`, postConfig).then(() => {
      reload().then(() => {}).catch((e) => {console.log(e)});
    }).catch((e) => {
      console.log(e)
    })
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Position</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waivers?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                  
                >
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">{row.position}</TableCell>
                  <TableCell>
                    <Button onClick={() => { handleDeleteWaiver(row.id) }}>Delete</Button>
                  </TableCell>
                </TableRow>
              )}
            )}
          </TableBody>
        </Table>
      </TableContainer>
  );
}

export default withCommonTools(SigningTemplateTable);