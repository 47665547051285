import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // another plugin!
import type { DateClickArg } from '@fullcalendar/interaction';
import { Box, Button } from '@mui/material';
import type { EventClickArg, EventInput } from '@fullcalendar/core';
import { removeAtIndex } from '../../../utils/arrays';
import { listDaysBetweenDates, listWeekendsBetweenDates } from '../../../utils/dates';
import { format, isSaturday, isSunday } from 'date-fns';

interface IProps {
  dates: EventInput[];
  setDates: (newDates: EventInput[]) => void;
}

function BigCalendar({ dates, setDates}: IProps) {
  const [calendarStartDate, setCalendarStartDate] = useState<Date>();
  const [calendarEndDate, setCalendarEndDate] = useState<Date>();

  const handleDateClick = (info: DateClickArg) => {
    let currentDates = [...dates];
    const existingDateIndex = currentDates.findIndex((date) => date.date === info.dateStr);
    if (existingDateIndex > -1) {
      currentDates = removeAtIndex(currentDates, existingDateIndex);
    } else {
      const newDate = { title: '', date: info.dateStr, color: 'grey' };
      currentDates.push(newDate);
    }
    setDates(currentDates);
  };

  const handleEventClick = (info: EventClickArg) => {
    let currentDates = [...dates];
    const eventDate = info.event.startStr;
    const existingDateIndex = currentDates.findIndex((date) => date.date === eventDate);
    currentDates = removeAtIndex(currentDates, existingDateIndex);
    setDates(currentDates);
  };

  const handleToggleWeekends = () => {
    if (calendarStartDate && calendarEndDate) {
      // for some reason this method from date-fns gives us an extra day. remove it with slice
      const weekendDays = listWeekendsBetweenDates(calendarStartDate, calendarEndDate).slice(0, -1);
      
      const newDaysToadd: EventInput[] = weekendDays.map((day: Date) => {
        const formattedDate = format(day, 'yyyy-MM-dd');
        const index = dates.findIndex((date) => date.date === formattedDate);
        if (index === -1) {
          return {
            title: '',
            date: formattedDate,
            color: 'grey'
          };
        }
        return undefined;
      }).filter(date => date) as EventInput[];
      
      let currentDates = [...dates];
      currentDates = currentDates.concat(newDaysToadd);
      setDates(currentDates);
    }
  };

  const handleToggleWeekdays = () => {
    if (calendarStartDate && calendarEndDate) {
      // for some reason this method from date-fns gives us an extra day. remove it with slice
      const days = listDaysBetweenDates(calendarStartDate, calendarEndDate).slice(0, -1);
      
      const newDaysToadd: EventInput[] = days.map((day: Date) => {
        const formattedDate = format(day, 'yyyy-MM-dd');
        const index = dates.findIndex((date) => date.date === formattedDate);
        if (index === -1 && !isSunday(day) && !isSaturday(day)) {
          return {
            title: '',
            date: formattedDate,
            color: 'grey'
          };
        }
        return undefined;
      }).filter(date => date) as EventInput[];
      
      let currentDates = [...dates];
      currentDates = currentDates.concat(newDaysToadd);
      setDates(currentDates);
    }
  };

  const handleClearVisible = () => {
    if (calendarStartDate && calendarEndDate) {
      // for some reason this method from date-fns gives us an extra day. remove it with slice
      const days = listDaysBetweenDates(calendarStartDate, calendarEndDate).slice(0, -1);
      const currentDates = [...dates];

      const newDates = currentDates.map((date: EventInput) => {
        const index = days.findIndex((day) => format(day, 'yyyy-MM-dd') === date.date);
        if (index === -1) {
          return date;
        }
        return undefined;
      }).filter(date => date) as EventInput[];
      setDates([...newDates]);
    }
  };

  return (
    <Box>
      <FullCalendar
        plugins={[ dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        events={dates}
        contentHeight='auto'
        height='auto'
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        datesSet={(dateInfo) => {
          setCalendarStartDate(dateInfo.start)
          setCalendarEndDate(dateInfo.end)
        }}
      />
      <Box display='flex' justifyContent='center' marginY={2}>
        <Button variant='contained' sx={{ borderRadius: '6px', marginX: 2 }} onClick={handleToggleWeekends}>Weekends</Button>
        <Button variant='contained' sx={{ borderRadius: '6px', marginX: 2 }} onClick={handleToggleWeekdays}>Weekdays</Button>
        <Button variant='contained' sx={{ borderRadius: '6px', marginX: 2 }} onClick={handleClearVisible}>Clear Visible</Button>
      </Box>
      
    </Box>
  );
};

export default BigCalendar;