import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepButton, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
// import { ROUTER_URLS } from '../../../constants/router-urls';

interface ISteps {
  id: number;
  label: string;
  prompt: string;
  optional: boolean;
};

interface IProps {
  activeStep: number;
  steps: ISteps[];
  listingId?: string;
};

function HorizontalStepper({
  activeStep,
  steps,
  listingId
}: IProps) {
  const navigate = useNavigate();

  const handleStep = (index: number, id?: string) => () => {
    if (id) {
      let route = '';
      switch (index) {
        case 0:
          route = ROUTER_URLS.listings.edit;
          break;
        case 1:
          route = ROUTER_URLS.listings.editAddress;
          break;
        case 2:
          route = ROUTER_URLS.listings.editAdditionalDetails;
          break;
        case 3:
          route = ROUTER_URLS.listings.editPricing;
          break;
        case 4:
          route = ROUTER_URLS.listings.editFileUploads;
          break;
        case 5:
          route = ROUTER_URLS.listings.editAvailability;
          break;                    
        default:
          break;
      }
      const path = generatePath(route, { id });
      navigate(path);
    }
  };

  return (
    <Box sx={{ width: '100%', display: { xs: 'block', 'md': 'block' }}}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={step.id} completed={activeStep > index}>
              <StepButton color="inherit" onClick={handleStep(index, listingId)}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
        <Typography variant='h5' component='h5' align='center' sx={{ mt: 3, mb: 3, display: 'flex', justifyContent: 'center', justifyItems: 'center', width: '75%', alignItems: 'center' }}>{steps[activeStep].prompt}</Typography>
      </Box>
    </Box>
  );
}

export default HorizontalStepper;