import { Box, CardMedia, CircularProgress, Typography } from '@mui/material';

interface IProps {
  loading: boolean;
  listingThumbnailUrl?: string;
};

function ListingCardImage({
  loading,
  listingThumbnailUrl,
}: IProps) {

  if (loading) {
    return (
      <Box height={240} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (listingThumbnailUrl) {
    return (
      <CardMedia
        component="img"
        height="240"
        image={listingThumbnailUrl}
        alt="booking landing photo"
      />
    )
  }

  return (     
    <Box height={240} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography >No image uploaded</Typography>
    </Box>
  );
}

export default ListingCardImage;

