import React, { useState } from 'react';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { STATE_DROPDOWN_OPTIONS } from '../../../constants/settings';
import HorizontalStepperActions from '../../atoms/HorizontalStepperActions/HorizontalStepperActions';

interface SelectOption {
  id: string;
  label: string;
};

interface IProps {
  firstStep: boolean;
  finalStep: boolean;
  handleNext: () => void;
  handleBack: () => void;
};

function QuickCheckoutAddressFields({
  firstStep,
  finalStep,
  handleNext,
  handleBack,
}: IProps) {

  const [stateValue, setStateValue] = React.useState<SelectOption | null>(null);
  const [inputStateValue, setInputStateValue] = React.useState('');
  const [addressOneValue, setAddressOneValue] = useState('');

  const AddressPanel = (
    <>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={5} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="First Name"
              placeholder="First Name"
              fullWidth
              value={addressOneValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressOneValue(event.target.value);
              }}
            />
          </Grid>
          <Grid item lg={5} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Last Name"
              placeholder="Last Name"
              fullWidth
              value={addressOneValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressOneValue(event.target.value);
              }}
            />
          </Grid>
        </Grid>  
      </Grid>

      <Grid item>
        <Grid container direction='row'>
          <Grid item xs={10}>
            <TextField
              required
              id="outlined-required"
              label="Address 1"
              placeholder="Street Address"
              helperText="Your exact address will only be shared with confirmed guests."
              fullWidth
              value={addressOneValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressOneValue(event.target.value);
              }}
            />
          </Grid>
        </Grid>  
      </Grid>
      

      <Grid item>
        <Grid container direction='row'>
          <Grid item xs={10}>
            <TextField
              required
              id="outlined-required"
              label="Address 2"
              defaultValue=""
              placeholder="Apt, Suite..."
              helperText="Optional Apartment or Suite number etc."
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

    
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={4}>
            <TextField
              required
              id="outlined-required"
              label="City"
              defaultValue=""
              placeholder="City"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={stateValue}
              onChange={(event: any, newValue: SelectOption | null) => {
                if (newValue?.id) setStateValue(newValue);
              }}
              inputValue={inputStateValue}
              onInputChange={(event, newInputValue) => {
                setInputStateValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={STATE_DROPDOWN_OPTIONS}
              // sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="State" required />}
            />
          </Grid>          

          <Grid item xs={3}>
            <TextField
              required
              id="outlined-required"
              label="Zip"
              defaultValue=""
              placeholder="Zip"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  

  return (
    <>
      <Grid container direction='column' spacing={2} padding={2}>
        <Grid item>
          <Grid container direction='row'>
            <Grid item xs={12}>
              <FormControl fullWidth>
                
                
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        { AddressPanel }
      </Grid>
      <Grid container direction='row' spacing={5} justifyContent='center'>
        <Grid item xs={10} marginTop={5}>
          <HorizontalStepperActions
            firstStep={firstStep}
            finalStep={finalStep}
            handleNext={handleNext}
            handleBack={handleBack}
            isValid={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuickCheckoutAddressFields;