import React, { useState } from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchAdminRefunds } from '../../../hooks/fetchRefund';
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import toProperCase from '../../../utils/strings';
import { formatDateString } from '../../../utils/dates';
import Dashboard from '../../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';


import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import type { AdminBookingUserRefundType } from '../../../types/booking';
import formatCurrency from '../../../utils/currency';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { formatISO } from 'date-fns';

const PAYMENT_STATUSES = ['All', 'Scheduled', 'Processing', 'Error', 'Completed', 'Cancelled', 'Imported']

interface IProps extends WrappedProps {};

function AdminRefunds({ getConfig }: IProps) {

  const [filterStatus, setFilterStatus] = useState('All');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [page, setPage] = useState(0);

  const { data: refundsData, isLoading, refetch, isFetching } = useFetchAdminRefunds(getConfig, {
    refund: {
      start_date: startDate ? formatISO(startDate) : '',
      end_date: endDate ? formatISO(endDate) : '',
      status: filterStatus === 'All' ? '' : filterStatus.toLowerCase(),
    },
    page: page + 1,
  });

  const handleSearch = () => {
    setPage(0);
    refetch().catch((e) => { console.log(e) });
  };

  const handleFilterStatusChange = (event: SelectChangeEvent) => {
    setFilterStatus(event.target.value);
  };

  // const navigateToEditBooking = (e: MouseEvent<HTMLElement>, id: string) => {
  //   e.stopPropagation();
  //   const path = generatePath(ROUTER_URLS.admin.bookings.edit, { id });
  //   console.log(id);
  //   navigate(path);
  // };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null, page: number) => {
    setPage(page);
  };

  const ListDisplayComponent = (
    <TableContainer component={Paper}>
      <Table size='small' sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">Refund Date</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Guest</TableCell>
            <TableCell align="left">Listing Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refundsData?.refunds?.map((row: AdminBookingUserRefundType) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover={true}
            >
              <TableCell align="left">{row.id}</TableCell>
              <TableCell scope="row">
                {formatDateString(row.refundDate)}
              </TableCell>
              <TableCell align="left">{toProperCase(row.status ?? '')}</TableCell>
              <TableCell align="left">{`$${formatCurrency(row.amount / 100)}`}</TableCell>
              <TableCell align="left">{row.tripDetail.userName}</TableCell>
              <TableCell align="left">{row.tripDetail.listingName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={8}
              count={refundsData?.page?.count ?? 0}
              rowsPerPage={20}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                '.MuiTablePagination-spacer': {
                  flex: 'none !important'
                }
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Refunds</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
        </Grid>
      </Grid>
      <Grid container spacing={3} marginBottom={3}>
        <Grid item xs={12} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={filterStatus}
              label="Status"
              onChange={handleFilterStatusChange}
              fullWidth
            >
              { PAYMENT_STATUSES.map((status: string) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>  
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12} lg={3}>
            <DatePicker
              label="Filter Start Date"
              value={startDate}
              onChange={(newValue) => { setStartDate(newValue) }}
              sx={{ width: '100%' }}
              slotProps={{
                field: { clearable: true, onClear: () => { setStartDate(null) } },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DatePicker
              label="Filter End Date"
              value={endDate}
              onChange={(newValue) => { setEndDate(newValue) }}
              sx={{ width: '100%' }}
              slotProps={{
                field: { clearable: true, onClear: () => { setEndDate(null) } },
              }}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item xs={12} lg={3}>
          <Button
            type="submit"
            variant="contained"
            sx={{ height: '45px', marginLeft: 3, }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      { isLoading || isFetching ? <LoadingSpinner /> : ListDisplayComponent }
    </Dashboard>
  );
}

export default withCommonTools(AdminRefunds);