import React, { useState, forwardRef } from 'react';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable
} from '@dnd-kit/sortable';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import type { UploadedFileInterface } from '../ListingFileUploadInput/ListingFileUploadInput';
import StarIcon from '@mui/icons-material/Star';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImagePreview from '../ImagePreview/ImagePreview';
import { removeAtIndex } from '../../../utils/arrays';

interface IProps {
  draggableFiles: UploadedFileInterface[];
  setDraggableFiles: any;
  handleHardDeleteImage: (id: number) => void;
  filesToUploadCount: number;
  headingText?: string;
  hideStars?: boolean;
  allowDeleteAll?: boolean;
};

function SortableItem({ id, children }: { id: string, children: React.ReactNode }) {
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useSortable({ id });

  return (
    <Grid
      item
      key={id}
      xs={6}
      md={4}
      lg={2}
      display='flex'
      flexGrow={1}
      flexDirection='column'
    >
      <span ref={setNodeRef} {...attributes} {...listeners}>
        { children }
      </span>
    </Grid>
  );
}

type Ref = any;
interface OverlayProps {
  children?: React.ReactNode;
  id?: string;
}

const OverlayItem = forwardRef<Ref, OverlayProps>((props, ref) => {
  return (
    <Grid
      item
      xs={6}
      md={4}
      lg={2}
      display='flex'
      flexGrow={1}
      flexDirection='column'
    >
      <span ref={ref}>
        { props.children }
      </span>
    </Grid>
  )
});

export default function DragAndDropGrid(
  {
    draggableFiles,
    setDraggableFiles,
    handleHardDeleteImage,
    headingText,
    hideStars = false,
    allowDeleteAll = false}: IProps) {
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 2,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 2,
      }
    }),
  );
  function handleDragStart(event: any) {
    const { active } = event;

    setActiveId(active.id);
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = draggableFiles.findIndex((file) => file.id?.toString() === active.id || file.signedId === active.id);
      const newIndex = draggableFiles.findIndex((file) => file.id?.toString() === over.id || file.signedId === over.id);

      setDraggableFiles((files: any) => {
        // const oldIndex = files.indexOf(active.id);
        // const newIndex = files.indexOf(over.id);

        return arrayMove(files, oldIndex, newIndex);
      });
    }
  }
  const handleDeleteImage = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.stopPropagation();
    const deleteFile = draggableFiles[index];
    if (deleteFile) {
      const currentFiles = removeAtIndex(draggableFiles, index);
      setDraggableFiles([...currentFiles]);
      if (deleteFile.id) handleHardDeleteImage(deleteFile.id);
    }
  };

  return (

    <Grid item xs={12} lg={12} paddingX={2}>
      {draggableFiles && draggableFiles.length > 0 &&
        <Box sx={{ display: 'flex', marginY: 2, alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Typography>
            {headingText ?? "Drag and drop images to order them. The first image will be used as the primary image on your listing..."}
          </Typography>
        </Box>
      }
      <Grid container direction='row'>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={draggableFiles.map((file) => file.id ?? file.signedId ?? '')}
          >
            {draggableFiles.map((file, index) => {
              const borderColor = index === 0 ? 'secondary.main' : 'primary.main';
              const isPdf = file?.file?.type === 'application/pdf'
              return (
                <SortableItem key={file.signedId ?? file.id} id={file.signedId ?? file.id?.toString() ?? ''}>
                  <Box
                    flexGrow={1}
                    flexDirection='column'
                    sx={{
                      display: 'flex',
                      border: 2,
                      borderColor,
                      borderRadius: 2,
                      overflow: 'hidden',
                      minHeight: '200px',
                      margin: '1px'
                    }}
                  >
                    <Box sx={{
                      position: 'relative',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}>
                      {(!hideStars && index === 0) &&
                        <IconButton disabled sx={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: 'lightGrey', zIndex: 10 }}>
                          <StarIcon sx={{ color: 'secondary.main' }} />
                        </IconButton>
                      }
                      {(allowDeleteAll || index !== 0) &&
                        <IconButton sx={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'lightGrey', zIndex: 100 }} onClick={(e) => { handleDeleteImage(e, index) }}>
                          <DeleteForeverIcon color='error' />
                        </IconButton>
                      }
                      {
                        file.file && (isPdf ? <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', textOverflow: 'ellipsis', flexDirection: 'column'}}><PictureAsPdfIcon sx={{ fontSize: '50px',height: '200px'}} />{file?.file?.name}</div>: <ImagePreview file={file.file}
                          styleOverrides={{ display: 'block', width: '100%', height: 'auto' }}
                        />)
                      }
                      {
                        //  file.url && <ProgressiveImg
                        //    placeholderSource={file.url}
                        //    source={file.url}
                        //    alt="uploaded-file"
                        //    styleOverrides={{ display: 'block', width: '100%', height: 'auto' }}
                        //  />
                      }
                      {
                        file.url && <img src={file.url} alt="Preview" style={{ display: 'block', width: '100%', height: 'auto' }} />
                      }
                    </Box>
                  </Box>
                </SortableItem>
              )
            })}
            <DragOverlay>
              {activeId ? (
                <OverlayItem>
                  <Box
                    flexGrow={1}
                    flexDirection='column'
                    sx={{
                      display: 'flex',
                      border: 2,
                      borderRadius: 2,
                      overflow: 'hidden',
                      minHeight: '200px',
                      margin: '1px',
                      borderColor: 'primary.main',
                      minWidth: 200,
                      width: '100%',
                      backgroundColor: '#E5E7E9'
                    }}
                  >
                    <Box sx={{
                      position: 'relative',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      alignContent: 'center',
                    }}>

                    </Box>
                  </Box>
                </OverlayItem>
              ) : null}
            </DragOverlay>
          </SortableContext>
        </DndContext>
      </Grid>
    </Grid>
  );
}