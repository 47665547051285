import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import type { SelectChangeEvent } from '@mui/material';

import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { LISTING_AMENITIES_DEFAULT_OPTIONS, LISTING_GEAR_REQUIREMENT_OPTIONS, LISTING_GUIDED_TYPE_OPTIONS, LISTING_LODGING_TYPE_OPTIONS, LISTING_PROPERTY_TYPE_OPTIONS } from '../../../constants/listings';
import { useFetchListing } from '../../../hooks/fetchListing';
import RichTextEditor from '../../../components/atoms/RichTextEditor/RichTextEditor';

import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';

import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import LongTextAddableAutocomplete from '../../../components/atoms/LongTextAddableAutocomplete/LongTextAddableAutocomplete';


interface IProps extends WrappedProps {};

function AdminEditListingDetails({ getConfig, postConfig }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const [handicapAccessible, setHandicapAccessible] = useState(false);
  const [kidFriendly, setKidFriendly] = useState(false);
  const [petFriendly, setPetFriendly] = useState(false);
  
  const [amenities, setAmenities] = useState<string[]>([]);
  const [gearRequirements, setGearRequirements] = useState<string[]>([]);

  const [invasive, setInvasive] = useState(false);
  const [acreage, setAcreage] = useState('');
  const [lodgingType, setLodgingType] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [guidedType, setGuidedType] = useState('');

  const [houseRules, setHouseRules] = useState('');
  const [listingDescription, setListingDescription] = useState('');
  const [testimonials, setTestimonials] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      setHandicapAccessible(listingRecord.handicappedAccessible);
      setKidFriendly(listingRecord.kidFriendly);
      setPetFriendly(listingRecord.petFriendly);
      
      const filteredAmenities = listingRecord.amenities.filter((a) => a.length > 0);
      if (filteredAmenities.length > 0) {
        setAmenities(filteredAmenities);
      }
      const filteredGearRequirements = listingRecord.extras.filter((g) => g.length > 0);
      if (filteredGearRequirements.length > 0) {
        setGearRequirements(filteredGearRequirements);
      }
      setHouseRules(listingRecord.houseRules);
      setListingDescription(listingRecord.description ?? '');
      setTestimonials(listingRecord.testimonials ?? '');

      setInvasive(listingRecord.invasive);
      setAcreage(listingRecord.acreage?.toString() ?? '');
      setLodgingType(listingRecord.lodgingType ?? '');
      setPropertyType(listingRecord.propertyType ?? '');
      setGuidedType(listingRecord.guidedType ?? '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  const navigateToListing = () => {
    const path = generatePath(ROUTER_URLS.admin.listings.show, { id: listingId });
    navigate(path);
  };

  const handleSave = () => {
    setIsSaving(true);
    const params = {
      listing: {
        handicapped_accessible: handicapAccessible,
        kid_friendly: kidFriendly, 
        pet_friendly: petFriendly,
        extras: gearRequirements,
        amenities,
        house_rules: houseRules,
        description: listingDescription,
        invasive,
        acreage,
        testimonials,
        lodging_type: lodgingType,
        property_type: propertyType,
        guided_type: guidedType,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      navigateToListing();
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleCancel = () => {
    navigateToListing();
  }

  const handleHandicapAccessibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHandicapAccessible(event.target.checked);
  };

  const handleKidFriendlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKidFriendly(event.target.checked);
  };

  const handlePetFriendlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPetFriendly(event.target.checked);
  };

  const handleInvasiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvasive(event.target.checked);
  };

  const handleAcreageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcreage(event.target.value);
  }

  const handleLodgingTypeChange = (event: SelectChangeEvent) => {
    setLodgingType(event.target.value);
  }

  const handlePropertyTypeChange = (event: SelectChangeEvent) => {
    setPropertyType(event.target.value);
  }

  const handleGuidedTypeChange = (event: SelectChangeEvent) => {
    setGuidedType(event.target.value);
  }

   // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column' spacing={3} marginTop={3} marginBottom={10}>
        <Grid xs={12} lg={12}>
          <LongTextAddableAutocomplete
            value={amenities}
            setValue={setAmenities}
            defaultOptions={LISTING_AMENITIES_DEFAULT_OPTIONS}
            label="Amenities"
            helperText="Add amenities to your listing by typing or selecting them"
            required
          />
        </Grid>
        <Grid xs={12} lg={12}>
          <LongTextAddableAutocomplete
            value={gearRequirements}
            setValue={setGearRequirements}
            defaultOptions={LISTING_GEAR_REQUIREMENT_OPTIONS}
            label="Gear Requirements"
            helperText="Add gear requirements to your listing by typing or selecting them"
            required
          />
        </Grid>
        <Grid xs={12} lg={12}>
          <RichTextEditor label="Listing Description *" value={listingDescription} setValue={setListingDescription} />
          <RichTextEditor label="House Rules" value={houseRules} setValue={setHouseRules} />      
          <RichTextEditor label="Reviews" value={testimonials} setValue={setTestimonials} />
        </Grid>
        <Grid lg={12} direction='row'>
          <FormControlLabel control={<Checkbox
            checked={handicapAccessible}
            onChange={handleHandicapAccessibleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Handicap Accessible" />
          <FormControlLabel control={<Checkbox
            checked={kidFriendly}
            onChange={handleKidFriendlyChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Kid Friendly" />
          <FormControlLabel control={<Checkbox
            checked={petFriendly}
            onChange={handlePetFriendlyChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Dog Friendly" />
          <FormControlLabel control={<Checkbox
            checked={invasive}
            onChange={handleInvasiveChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Invasive Species Hunt" />
        </Grid>
        <Grid xs={12} lg={6}>
          <TextField
            required
            id="outlined-required"
            label="Acreage"
            value={acreage}
            onChange={handleAcreageChange}
            fullWidth
            placeholder="Example: 560"
            helperText="Please round to whole numbers, for example: 559.6 would be 560"
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Lodging</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={lodgingType}
              label="Lodging"
              onChange={handleLodgingTypeChange}
            >
              {
                LISTING_LODGING_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Public/Private Property</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={propertyType}
              label="Public/Private Property"
              onChange={handlePropertyTypeChange}
            >
              {
                LISTING_PROPERTY_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Guided</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={guidedType}
              label="Guided"
              onChange={handleGuidedTypeChange}
            >
              {
                LISTING_GUIDED_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            color='primary'
            variant='outlined'
            onClick={handleCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant='contained' color='primary'>
            Save
          </Button>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(AdminEditListingDetails);
