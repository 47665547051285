type sessionStorageKeyType = "impersonateUserName" | "impersonateUserToken" | "impersonateUserId";

export const getSessionStorageOrDefault = (key: sessionStorageKeyType, defaultValue?: string | number | boolean) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
};

export const setSessionStorage = (key: sessionStorageKeyType, value: string | number | boolean) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeSessionStorageItem = (key: sessionStorageKeyType) => {
  sessionStorage.removeItem(key);
};
