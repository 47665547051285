import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import axios from 'axios';
// import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchSigningDocuments } from '../../../hooks/fetchSigningTemplate';

interface IProps extends WrappedProps {
  timeStamp: string;
};

function SigningDocumentsTable({ timeStamp, getConfig }: IProps) {

  const { data: waivers, isLoading: loading } = useFetchSigningDocuments(timeStamp, getConfig);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Sent To</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone Number</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waivers?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                  
                >
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">{row.contact.firstName} {row.contact.lastName}</TableCell>
                  <TableCell align="left">{row.contact.emailAddress}</TableCell>
                  <TableCell align="left">{row.contact.phoneNumber}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                </TableRow>
              )}
            )}
          </TableBody>
        </Table>
      </TableContainer>
  );
}

export default withCommonTools(SigningDocumentsTable);