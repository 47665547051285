

import type { SelectedAdditionalLineItemType } from "../types/lineItem";
import type { ListingAdditionalCostItemType, ListingDetailType } from "../types/listing";
import { daysBetweenCalendarDays } from "./dates";


const UPCHARGE_PERCENT = 1.1;

export const lineItemDescription = (
  cost: number,
  perPerson: boolean,
  perDay: boolean,
  perNight: boolean,
  canSpecifyNumber: boolean,
): string => {
  const formattedPrice = `$${((cost / 100) * UPCHARGE_PERCENT).toFixed(2)}`
  const description: string[] = [formattedPrice];
  if (perPerson) {
    description.push('Per Person');
  }
  if (perDay) {
    description.push('Per Day');
  }
  if (perNight) {
    description.push('Per Night');
  }
  if (canSpecifyNumber) {
    description.push('Per Item');
  }
  return description.join(' ');
};

export const baseItemDescription = (
  cost: number,
  perPerson: boolean,
  listingUnit: string,
): string => {
  const formattedPrice = `$${((cost / 100) * UPCHARGE_PERCENT).toFixed(2)}`
  const description: string[] = [formattedPrice];
  if (perPerson) {
    description.push('Per Person');
  }
  let unit = '';
  switch (listingUnit) {
    case 'PerDay':
      unit = "Per Day"
      break;
    case 'PerNight':
      unit = "Per Night"
      break;
    case 'PerExperience':
      unit = "Per Experience"
      break;
    case 'PerLease':
      unit = "Per Lease"
      break;       
    default:
      break;
  }
  description.push(unit);
  return description.join(' ');
};

export const lineItemDescriptionForFee = (fee: ListingAdditionalCostItemType): string => {
  return lineItemDescription(
    fee.price,
    fee.perPerson,
    fee.perDay,
    fee.perNight,
    fee.canSpecifyNumber,
  );
};

export const lineItemTotal = (
  price: number,
  numberOfGuests: number,
  numberOfDays: number,
  numberOfItems: number,

  perPerson: boolean,
  perDay: boolean,
  perNight: boolean,
  canSpecifyNumber: boolean,
): number => {
  let total = price / 100;
  if (perPerson) total = total * numberOfGuests;
  if (perDay) total = total * numberOfDays;
  if (perNight && numberOfDays > 1) total = total * (numberOfDays - 1);
  if (canSpecifyNumber) total = total * numberOfItems;
  total = total * UPCHARGE_PERCENT;
  return total;
};

export const bookingTotal = (
  listing: ListingDetailType,
  numberOfGuests: number,
  startDate: Date,
  endDate: Date,
  numberOfItems: number,
  additionalFees: SelectedAdditionalLineItemType[],
  requiredFees: ListingAdditionalCostItemType[],
) => {
  const listingUnit = listing.listingUnit;
  const basePrice = listing.basePrice;
  const perPerson = listing.pricePerPerson;

  const perDay = listingUnit === "PerDay";
  const perNight = listingUnit === "PerNight";
  const canSpecifyNumber = listingUnit === "PerLease" || listingUnit === "PerExperience";

  const numberOfNights = daysBetweenCalendarDays(startDate, endDate);
  const numberOfDays = numberOfNights + 1;

  const baseTotal = lineItemTotal(
    basePrice,
    numberOfGuests,
    numberOfDays,
    numberOfItems,

    perPerson,
    perDay,
    perNight,
    canSpecifyNumber,
  );

  const requiredFeeSubTotal = requiredFees.reduce((subTotal: number, currentFee) => {
    const lineItemCost = lineItemTotal(
      currentFee.price,
      numberOfGuests,
      numberOfDays,
      numberOfItems,

      currentFee.perPerson,
      currentFee.perDay,
      currentFee.perNight,
      currentFee.canSpecifyNumber,
    );
    return subTotal + lineItemCost;
  }, 0);

  const optionalFeeSubTotal = additionalFees.reduce((subTotal: number, currentFee) => {
    const lineItemCost = lineItemTotal(
      currentFee.cost,
      numberOfGuests,
      numberOfDays,
      currentFee.numberOfItems,

      currentFee.perPerson,
      currentFee.perDay,
      currentFee.perNight,
      currentFee.canSpecifyNumber,
    );
    return subTotal + lineItemCost;
  }, 0);


  return baseTotal + requiredFeeSubTotal + optionalFeeSubTotal;
};