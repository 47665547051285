import React from 'react';
import { Card, CardActionArea, CardHeader, Grid } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ListingCardImage from './ListingCardImage';

interface IProps {
  listingId: string;
  listingThumbnailUrl?: string;
  listingName: string;
  postConfig: any;
  listingSlug: string;
  selected: boolean;
  selectListing: (newListing: number) => void;
};

function SelectableListingCard({
  listingId,
  listingThumbnailUrl,
  listingName,
  selected,
  selectListing,
}: IProps) {
  const cardAvatar = selected ? <CheckCircleIcon fontSize='large' color='success' /> : <></>;
  const borderProps: SxProps<Theme> = selected ? { border: 2, borderColor: 'green' } : {};
  return (
    <Grid item xs={12} md={6} lg={4} key={listingId}>
      <Card sx={borderProps}>
        <CardActionArea onClick={() => { selectListing(Number(listingId)) }}>
          <CardHeader
            title={listingName}
            subheader=""
            avatar={cardAvatar}
          />
          <ListingCardImage listingThumbnailUrl={listingThumbnailUrl} loading={false} />
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default SelectableListingCard;

