import React, { useState } from 'react';
import { Button, Grid, Typography } from "@mui/material";
import './styling.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import type { IReadFile } from '../../../types/readFile';

export interface UploadedFileProps {
  id: string;
  publicUrl: string;
  previewUrl: string;
  fileName: string;
  sizeBytes: number;
  source?: string;
  listingId: string;
  userId?: string;
  isPrimaryImage: boolean;
  description?: string;
  ownerUserId: string;
  createdDate: string;
  updatedDate: string;
  extraElements: string[];
}

interface IProps {
  // listingId: string;
  promptText: string;
  buttonText: string;
  uploadedFiles: IReadFile[];
  setUploadedFiles: (files: IReadFile[]) => void;
  postConfig: {
    headers: {
      Authorization: string;
    };
  };
  filesToUploadCount: React.MutableRefObject<number>;
  // fileAlreadyUploaded: (name: string) => boolean;
  saveToApi: (file: File) => Promise<void>;
}

function FileUploadInput({
  // listingId,
  promptText,
  buttonText,
  uploadedFiles,
  // setUploadedFiles,
  // postConfig,
  filesToUploadCount,
  // fileAlreadyUploaded,
  saveToApi,
}: IProps) {  
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  
  const handleDrag = (e: React.DragEvent<HTMLFormElement | HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const saveFile = async (file: File) => {
    saveToApi(file).catch((e) => { console.log(e) });
    // const formData = new FormData();
    // formData.append(
    //     "file",
    //     file,
    // );
    // saveToApi(formData).catch((e) => { console.log(e) });
    // const { data } = await axios.post(`${API_URLS.listing.imageUpload}/${listingId}`, formData, postConfig);
    // if (data.id) {
    //   setUploadedFiles(data.images)
    // }
  };

  const fileAlreadyUploaded = (fileName: string): boolean => {
    return Boolean(uploadedFiles.find((file) => { return file.name === fileName }));
  };

  const handleDrop = async function(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files?.[0]) {
      filesToUploadCount.current = e.dataTransfer.files.length;

      for (const file of Array.from(e.dataTransfer.files)) {
        if (fileAlreadyUploaded(file.name)) {
          setErrorMessage("File has already been uploaded. Please do not upload duplicates. Upload a different file to continue.");
          filesToUploadCount.current = 0;
          return;
        } else {
          await saveFile(file);
          const tempCount = filesToUploadCount.current;
          filesToUploadCount.current = (tempCount - 1);
        }
      }
    }
  };

  // triggers when file is selected with click
  const handleChange = async function(e: React.ChangeEvent<HTMLInputElement>) {
    setErrorMessage(undefined);
    e.preventDefault();
    if (e.target.files?.[0]) {
      filesToUploadCount.current = e.target.files.length - 1;

      for (const file of Array.from(e.target.files)) {
        if (fileAlreadyUploaded(file.name)) {
          setErrorMessage("File has already been uploaded. Please do not upload duplicates. Upload a different file to continue.");
          filesToUploadCount.current = 0;
          return;
        } else {
          await saveFile(file);
        }
      }
    }
  };

  const onButtonClick = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  if (filesToUploadCount.current > 0) {
    return (
      <Grid container direction='row' spacing={2} padding={2}>
        <Grid item xs={12} lg={12}>
          <LoadingSpinner />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction='row' spacing={2} padding={2}>
      <Grid item xs={12} lg={12}>
        <form id="file-upload-form" onDragEnter={handleDrag} onSubmit={(e) => { handleSubmit(e) }}>
          {
            // this is a fire and forget function for now
            // https://stackoverflow.com/questions/63488141/promise-returned-in-function-argument-where-a-void-return-was-expected
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <input ref={inputRef} type="file" id="file-upload-input" multiple={true} onChange={async (e) => { await handleChange(e) }} />
          }
          <label id="file-upload-label" htmlFor="file-upload-input" className={dragActive ? "drag-active" : "" }>
            <div>
              {
                errorMessage ? 
                <Typography
                  variant='body1'
                  component='p'
                  color='error'
                  sx={{
                    mt: 0,
                    mb: 0,
                    padding: 4,
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '100%',
                    fontWeight: 600,
                  }}
                > 
                  { errorMessage }
                </Typography> : 
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    mt: 0,
                    mb: 0,
                    padding: 4,
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '100%',
                  }}
                >
                  { promptText }
                </Typography>              
              }
              <Button
                onClick={onButtonClick}
                className="upload-button"
                variant="text"
              >
                { buttonText }
              </Button>
            </div>
          </label>
          {
            // this is a fire and forget function for now
            // https://stackoverflow.com/questions/63488141/promise-returned-in-function-argument-where-a-void-return-was-expected
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={async (e) => { await handleDrop(e) }}></div>
          }
        </form>
      </Grid>
    </Grid>
  );
}

export default FileUploadInput;
