// import { CheckboxInput } from "@/atoms/CheckboxInput";





import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem, Select, Typography } from "@mui/material";
import type { SelectChangeEvent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import type { SelectedAdditionalLineItemType } from "../../../types/lineItem";
import { daysBetweenCalendarDays } from "../../../utils/dates";
import { lineItemTotal } from "../../../utils/lineItems";
import { range } from "lodash";

interface IProps {
  id: string;
  basePrice: number;
  label: string;
  description: string;
  required: boolean;

  numberOfGuests: number,
  // numberOfDays: number,

  perPerson: boolean,
  perDay: boolean,
  perNight: boolean,
  canSpecifyNumber: boolean,

  selectAdditionalItem?: (item: SelectedAdditionalLineItemType) => void;
  removeAdditionalItem?: (id: string) => void;
  updateAdditionalItem?: (id: string, quantity: number) => void;

  startDate: Date;
  endDate: Date;

  minNumberItems?: number;
  maxNumberItems?: number;
};

export function AdditionalLineItem({
  id,
  basePrice,
  label,
  description,
  required,

  numberOfGuests,
  // numberOfDays,

  perPerson,
  perDay,
  perNight,
  canSpecifyNumber,

  selectAdditionalItem,
  removeAdditionalItem,
  updateAdditionalItem,

  startDate,
  endDate,

  minNumberItems,
  maxNumberItems
}: IProps) {
  const [selected, setSelected] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(minNumberItems?.toString() ?? '1');

  const numberOfNights = daysBetweenCalendarDays(startDate, endDate);
  const numberOfDays = numberOfNights + 1;

  const handleChange = () => {
    const newValue = !selected;
    setSelected(newValue);
    const castedNumberOfItems = Number(numberOfItems);
    if (newValue) {
      if (selectAdditionalItem) {
        const newAdditionalLineItem: SelectedAdditionalLineItemType = {
          id,
          description,
          cost: basePrice,

          numberOfGuests,
          numberOfDays,
          numberOfItems: castedNumberOfItems,

          perPerson,
          perDay,
          perNight,
          canSpecifyNumber,
        };
        selectAdditionalItem(newAdditionalLineItem);
      };
    } else {
      if (removeAdditionalItem) removeAdditionalItem(id)
    }
  }

  const handleChangeNumberOfItems = (event: SelectChangeEvent) => {
    if (updateAdditionalItem) {
      const castedValue = Number(event.target.value);
      updateAdditionalItem(id, castedValue);
    }
    setNumberOfItems(event.target.value)
  }

  const castedNumberOfItems = Number(numberOfItems);
  const total = lineItemTotal(
    basePrice,
    numberOfGuests,
    numberOfDays,
    castedNumberOfItems,

    perPerson,
    perDay,
    perNight,
    canSpecifyNumber,
  );

  const itemQuantityOptions = range(minNumberItems ?? 0, maxNumberItems, 1);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 2 }}>
    
      { required ?
        <Box >
          <Typography component='p' >
            { label }
          </Typography>
          <Typography component='p' >
            { description }
          </Typography>
        </Box> :
        <Grid2 container spacing={2} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Grid2 xs={12} md={8}>
            <FormControl sx={{  }}>
              <FormControlLabel control={<Checkbox
                checked={selected}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label={label} />
              <FormHelperText sx={{ marginY: 0 }}>{description}</FormHelperText>
            </FormControl>
          </Grid2>
          <Grid2 xs={12} md={4}>
          { selected && canSpecifyNumber &&
            <FormControl sx={{ maxWidth: 100 }}>
              <FormHelperText sx={{ marginX: 0 }}>Quantity</FormHelperText>
              <Select
                value={numberOfItems}
                onChange={handleChangeNumberOfItems}
                size="small"
              >
                {itemQuantityOptions.map((option) => {
                  return <MenuItem key={option} value={option.toString()}>{option}</MenuItem>
                })}
              </Select>
            </FormControl>}
          </Grid2>
        </Grid2>
        
      }
      <Typography component='span' >
        ${total.toFixed(2)}
      </Typography>    
    </Box>
  );
}