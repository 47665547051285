import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import type { SettingType } from '../../types/setting';
import { useSettingsContext } from '../../contexts/SettingsContext';
import type { ListingCancellationPolicyType, ListingDetailType } from '../../types/listing';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { ROUTER_URLS } from '../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { dateFromISOString } from '../../utils/dates';
import type { EventInput } from '@fullcalendar/core';
import { calendarDatesToStringDates, stringDatesToCalendarDates } from '../../utils/arrays';
import BigCalendar from '../../components/compounds/BigCalendar/BigCalendar';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';

import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { listingStepSixValid } from '../../utils/listing';

interface IProps extends WrappedProps {};

function EditListingAvailability({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const settingsContext: SettingType = useSettingsContext() as SettingType;

  const activeStep = 5;
  
  const [checkInTime, setCheckIntime] = useState<Date | null>(new Date());
  const [checkOutTime, setCheckOutTime] = useState<Date | null>(new Date());
  const [limitNumberOfGuests, setLimitNumberOfGuests] = useState(false);
  const [minimumNumberOfGuests, setMinimumNumberOfGuests] = useState("0");
  const [maximumNumberOfGuests, setMaximumNumberOfGuests] = useState("0");
  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
  const [cancellationPolicy, setCancellationPolicy] = useState<string>("");
  const [calendarBlackoutDates, setCalendarBlackoutDates] = useState<EventInput[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      // if (getCurrentUserId()?.toString() !== listingRecord.host.id.toString()) {
      //   const path = generatePath(ROUTER_URLS.listings.show, { id: listingId });
      //   navigate(path, { state: { policyError: true } });
      // }

      setCheckIntime(dateFromISOString(listingRecord.checkInTime ?? ''));
      setCheckOutTime(dateFromISOString(listingRecord.checkOutTime ?? ''));
      setLimitNumberOfGuests(listingRecord.enforceAccomodationRange);
      setMinimumNumberOfGuests(listingRecord.accommodateMin?.toString() ?? '');
      setMaximumNumberOfGuests(listingRecord.accommodateMax?.toString() ?? '');
      setPhoneNumber(listingRecord.contactNumber);

      const policyId = listingRecord.cancellationPolicyId?.toString() ?? '';
      setCancellationPolicy(policyId);

      const mappedDates = stringDatesToCalendarDates(listingRecord.blackoutDates, 'grey');
      setCalendarBlackoutDates(mappedDates)

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.show, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);
    const selectedCancellationPolicy: ListingCancellationPolicyType | undefined = settingsContext.cancellationPolicies.find((policy) => policy.id.toString() === cancellationPolicy.toString());
    const blackoutDates = calendarDatesToStringDates(calendarBlackoutDates);

    const params = {
      listing: {
        check_in_time: checkInTime,
        check_out_time: checkOutTime,
        enforce_accomodation_range: limitNumberOfGuests,
        accommodate_min: minimumNumberOfGuests,
        accommodate_max: maximumNumberOfGuests,
        contact_number: phoneNumber,
        cancellation_policy_id: selectedCancellationPolicy?.id,
        blackout_dates: blackoutDates,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) handleNext();
        setIsSaving(false);
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleBack = () => {
    const backUrl = generatePath(ROUTER_URLS.listings.editFileUploads, { id: listingId });
    navigate({
      pathname: backUrl,
    });
  };

  const handleLimitNumberOfGuestsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimitNumberOfGuests(event.target.checked);
  };

  const handleCancellationPolicyChange = (event: SelectChangeEvent) => {
    setCancellationPolicy(event.target.value);
  };

  const cancellationPolicyDescription = (): string => {
    const selectedPolicy = settingsContext.cancellationPolicies.find((policy: ListingCancellationPolicyType) => policy.key === cancellationPolicy);
    return selectedPolicy ? selectedPolicy.shortDescription : "";
  };

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='row' padding={2} spacing={2}>
            <Grid item xs={12} lg={6} marginTop={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container direction='row' paddingY={2} spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <TimePicker
                      label="Check In Time"
                      value={checkInTime}
                      onChange={(newValue) => { setCheckIntime(newValue) }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <TimePicker
                      label="Check Out Time"
                      value={checkOutTime}
                      onChange={(newValue) => { setCheckOutTime(newValue) }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <FormControlLabel control={<Checkbox
                      checked={limitNumberOfGuests}
                      onChange={handleLimitNumberOfGuestsChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Limit number of guests" />
                  </Grid>
                </Grid>
                {
                  limitNumberOfGuests && (
                    <Grid container direction='row' paddingY={2} spacing={2}>
                      <Grid item xs={12} lg={5}>
                        <TextField
                          id="outlined-number"
                          value={minimumNumberOfGuests}
                          onChange={(event) => { setMinimumNumberOfGuests(event.target.value)}}
                          label="Minimum"
                          type="number"
                          fullWidth
                          helperText="Minimum number of guests"
                        />
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <TextField
                          id="outlined-number"
                          value={maximumNumberOfGuests}
                          onChange={(event) => { setMaximumNumberOfGuests(event.target.value)}}
                          label="Maximum"
                          type="number"
                          fullWidth
                          helperText="Maximum number of guests"
                        />
                      </Grid>
                    </Grid>
                  )
                }
                { settingsContext.cancellationPolicies.length > 0 &&
                <Grid container direction='row' paddingY={2} spacing={2}>
                  <Grid item xs={12} lg={10}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">Cancellation Policy</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={cancellationPolicy}
                        label="Cancellation Policy"
                        onChange={handleCancellationPolicyChange}
                      >
                        { settingsContext.cancellationPolicies.map((option: ListingCancellationPolicyType) => (
                          <MenuItem key={option.key} value={option.id}>{option.name}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{cancellationPolicyDescription()}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                }
                <Grid container direction='row' paddingY={2} spacing={2}>
                  <Grid item xs={12} lg={10}>
                    <PhoneNumber
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      label="Contact Phone Number"
                      helperText="What is a good phone number to contact you regarding this listing?"
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} lg={6}>
              <BigCalendar
                dates={calendarBlackoutDates}
                setDates={setCalendarBlackoutDates}
              />
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
              <Grid item xs={10} marginTop={5}>
                <HorizontalStepperActions
                  firstStep={false}
                  finalStep={true}
                  handleNext={handleSave}
                  handleBack={handleBack}
                  handleSave={handleSave}
                  isValid={listingStepSixValid()}
                />
              </Grid>
            </Grid>

          
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListingAvailability);
