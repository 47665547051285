import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';

import Typography from '@mui/material/Typography';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../../compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../compounds/CommonWrapper/withCommonTools';

interface IProps extends WrappedProps {
  clientSecret: string;
  tripSlug?: string;
};

function OfflineConfirmation({ clientSecret, tripSlug, postConfig }: IProps) {
  const stripe = useStripe();
  
  const [message, setMessage] = useState<string | undefined>();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({ setupIntent }) => {

        if (!setupIntent) {
          setMessage('Error - Stripe api failure. Please contact support');
          return;
        }
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

        const paymentMethodId = setupIntent.payment_method;
        axios.patch(`${API_V3_ALLIGATOR_URLS.admin.bookings.patch}/${tripSlug ?? ''}`, { trip: { stripe_payment_method_id: paymentMethodId } }, postConfig).then((res) => {
          console.log('saved')
        }).catch((e) => {
          console.log(e);
        })

        switch (setupIntent.status) {
          case 'succeeded':
            setMessage('Your payment method has been saved.');
            break;

          case 'processing':
            setMessage("Processing payment details. We'll update you when processing is complete.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Failed to process payment details. Please try another payment method.');
            break;
        }

      }).catch((e) => {
        console.log(e);
      });
  }, [stripe]);

  return (
    <Typography variant="body1">
      <span style={{ fontWeight: 'bold' }}>Payment Details Status: </span> { message }
    </Typography>    
  );
}

export default withCommonTools(OfflineConfirmation);