import React, { useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import ListingFileUploadInput from '../../components/atoms/ListingFileUploadInput/ListingFileUploadInput';
import type { UploadedFileInterface } from '../../components/atoms/ListingFileUploadInput/ListingFileUploadInput';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { ROUTER_URLS } from '../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import type { ListingDetailType } from '../../types/listing';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { listingStepFiveValid } from '../../utils/listing';
import DragAndDropGrid from '../../components/atoms/DragAndDropGrid/DragAndDropGrid';

interface IProps extends WrappedProps {};

function EditListingFileUploads({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();
  const { id: listingId } = useParams();

  const activeStep = 4;

  // const [uploadedImages, setUploadedImages] = useState<ListingImageType[]>([]);
  const imagesToUploadCount = useRef(0);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileInterface[]>([]);
  
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      // if (getCurrentUserId()?.toString() !== listingRecord.host.id.toString()) {
      //   const path = generatePath(ROUTER_URLS.listings.show, { id: listingId });
      //   navigate(path, { state: { policyError: true } });
      // }
      const newFiles = listingRecord.mediaListings.map((file) => ({ id: file.id, url: file.url }))
      setUploadedFiles(newFiles);
      // setUploadedImages(listingRecord.images);
      // setUploadedDocs(listingRecord.additionalDocuments);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord]);

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.editAvailability, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);
    // const imagesToSave = uploadedImages.map((image, index) => {
    //   const tempImage = { ...image };
    //   if (index === 0) {
    //     tempImage.isPrimaryImage = true;
    //   } else {
    //     tempImage.isPrimaryImage = false;
    //   }
    //   return tempImage
    // });
    const filesToUpload = uploadedFiles.map((file, index) => ({
      id: file.id,
      position: index,
      file: file.signedId,
    }))
    const params = {
      listing: {
        media_listings_attributes: filesToUpload,
        published: true,
      }
      // additionalDocuments: [...uploadedDocs],
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) handleNext();
        setIsSaving(false);
        const newFiles = newListingData.mediaListings.map((file) => ({ id: file.id, url: file.url }))
        setUploadedFiles(newFiles);
      } else {
        console.log('error failed to save');
        setIsSaving(false);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleDeleteImage = (id: number) => {
    if (listingId) {
      axios.delete(`${API_V3_ALLIGATOR_URLS.listing.deleteImage}${id}`, postConfig).then((response) => {
        console.log(response)
      }).catch((e) => {
        console.log(e);
      })
    }
  };

  const handleBack = () => {
    const backUrl = generatePath(ROUTER_URLS.listings.editPricing, { id: listingId });
    navigate({
      pathname: backUrl,
    });
  };

  const imageAlreadyUploaded = (fileId: number): boolean => {
    return Boolean(uploadedFiles.find((file) => file.id === fileId));
  };

  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  console.log('test', uploadedFiles);

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='row'>
            {listingId && <ListingFileUploadInput
              listingId={listingId}
              promptText='Drag and drop your photos here or'
              buttonText='Upload photos'
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              postConfig={postConfig}
              filesToUploadCount={imagesToUploadCount}
              fileAlreadyUploaded={imageAlreadyUploaded}
            />}
            <DragAndDropGrid draggableFiles={uploadedFiles} setDraggableFiles={setUploadedFiles} handleHardDeleteImage={handleDeleteImage} filesToUploadCount={imagesToUploadCount.current} />
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item xs={10} marginTop={5}>
              <HorizontalStepperActions
                firstStep={false}
                finalStep={false}
                handleNext={handleSave}
                handleBack={handleBack}
                handleSave={handleSave}
                isValid={listingStepFiveValid(uploadedFiles)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListingFileUploads);
