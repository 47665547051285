
import { baseItemDescription } from "../../../utils/lineItems";
import { AdditionalLineItem } from "../AdditionalLineItem/AdditionalLineItem";


interface IProps {
  basePrice: number;
  numberOfGuests: number,

  perPerson: boolean,
  listingUnit: string,
  startDate: Date;
  endDate: Date;
};

export function BasePriceLineItem({
  basePrice,
  numberOfGuests,
  perPerson,
  listingUnit,
  startDate,
  endDate,
  
}: IProps) {
  const description = baseItemDescription(basePrice, perPerson, listingUnit)

  const perDay = listingUnit === "PerDay";
  const perNight = listingUnit === "PerNight";
  const canSpecifyNumber = listingUnit === "PerLease" || listingUnit === "PerExperience";

  return (
    <AdditionalLineItem
      id="base-price-id"
      key="base-listing-price"
      basePrice={basePrice}

      label="Base Price"
      description={description}
      required={true}

      numberOfGuests={Number(numberOfGuests)}
      
      perPerson={perPerson}
      perDay={perDay}
      perNight={perNight}
      canSpecifyNumber={canSpecifyNumber}

      startDate={startDate}
      endDate={endDate}
    />
  )
};
