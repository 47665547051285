import { parseISO, format, parse, formatISO, eachWeekendOfInterval, eachDayOfInterval, isSameDay, differenceInCalendarDays } from 'date-fns';

export const formatDateString = (dateString?: string, style?: string): string => {
  // 2021-01-28T06:00:00+00:00
  if (!dateString) return '';

  const dateFormat = style ?? 'M/d/yy';
  const date = parseISO(dateString);
  return format(date, dateFormat);
};

export const formatTimeString = (dateTimeString: string): string => {
  const date = parseISO(dateTimeString);
  return format(date, 'p');
};

export const dateFromISOString = (dateTimeString: string): Date => {
  return parseISO(dateTimeString);
};

export const isoStringFromDateString = (dateString: string): string => {
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
  return formatISO(parsedDate);
};

export const listWeekendsBetweenDates = (startDate: Date, endDate: Date): Date[] => {
  return eachWeekendOfInterval({ start: startDate, end: endDate });
};

export const listDaysBetweenDates = (startDate: Date, endDate: Date): Date[] => {
  return eachDayOfInterval({ start: startDate, end: endDate });
  
  // return eachWeekendOfInterval({ start: startDate, end: endDate });
};

export const formatDateRange = (startDate: string, endDate: string, style?: string): string => {
  const sameDay = isSameDay(parseISO(startDate), parseISO(endDate));
  if (sameDay) {
    return formatDateString(startDate, style);
  }
  
  const dateFormat = style ?? 'M/d/yy';
  return `${formatDateString(startDate, dateFormat)} - ${formatDateString(endDate, dateFormat)}`;
};

export const daysBetweenCalendarDays = (start: Date, end: Date): number => {
  return differenceInCalendarDays(end, start);
}

export const updateTimeToCurrent = (value: Date | null): Date | null => {
  const current = new Date();
  const hours = current.getHours();
  const minutes = current.getMinutes();
  const seconds = current.getSeconds();
  if (value) {
    value.setHours(hours);
    value.setMinutes(minutes);
    value.setSeconds(seconds);
  }
  return value;
}