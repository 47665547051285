import React from 'react';
import { AuthProvider, createRefresh } from 'react-auth-kit';
import { ThemeProvider } from '@mui/material/styles';
import '@fontsource/poppins';
import { RouterProvider } from 'react-router-dom';
import { ROUTES } from './utils/Routes';
import theme from './theme/theme';
import SettingsContextProvider from './contexts/SettingsContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { doRefresh } from './api/auth';
import "./App.css";

const queryClient = new QueryClient()

const tokenRefresh = createRefresh({
  interval: 25200,
  refreshApiCallback: async(params) => {
    const { refreshToken } = params;
    const response = await doRefresh(refreshToken ?? '');
    if (response.status !== 200) {
      // doLogout();
      return {
        isSuccess: false,
        newAuthToken: "",
      };
    }

    const { data } = response;
    
    return {
      isSuccess: true,
      newAuthToken: data.access_token,
      newAuthTokenExpireIn: data.expires_in,
      newRefreshTokenExpiresIn: 60
    };
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      
      <AuthProvider
        authType='cookie'
        authName='venku-host-toolkit-auth'
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
        refresh={tokenRefresh}
      >
        <QueryClientProvider client={queryClient}>
          <SettingsContextProvider>
            <div className="App">
              <RouterProvider router={ROUTES} />
            </div>
          </SettingsContextProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
