import React from 'react';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import PaperWithHeader from '../PaperWithHeader/PaperWithHeader';
import { displayPhoneNumber } from '../../../utils/phoneNumber';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';

// interface IProps {
//   user: UserType
// }

interface IProps {
  status: string;
  user: {
    name: string;
    thumbnail?: {
      publicUrl: string;
    };
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  chatId?: number;
}

function BookingUserCard({ status, user, chatId }: IProps) {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const path = generatePath(ROUTER_URLS.messages.show, { id: chatId });
    navigate(path);
  }

  const showPhoneNumber = !['requested'].includes(status);

  return (
    <PaperWithHeader headerTitle='Requested By'>
      <Grid container direction='row' padding={2} spacing={2}>
        <Grid item xs={12} lg={4}>
          <Box display='flex' alignContent='center' justifyContent='center'>
            <Avatar alt={`User Profile Picture ${user.name}`} src={user.thumbnail?.publicUrl} sx={{ width: 112, height: 112, bgcolor: 'secondary.main' }} >
              { `${user.firstName[0].toUpperCase()}.${user.lastName[0].toUpperCase()}.` }
            </Avatar>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display='flex' marginY={2}>
            <Typography variant="body1" sx={{ fontWeight: 600, marginRight: 1 }}>Name:</Typography>
            <Typography variant="body1">{ user.name }</Typography>
          </Box>
          {showPhoneNumber && <Box display='flex' marginY={2}>
            <Typography variant="body1" sx={{ fontWeight: 600, marginRight: 1 }}>Phone:</Typography>
            <Typography variant="body1">{ displayPhoneNumber(user.phoneNumber) }</Typography>
          </Box>}
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box display='flex' justifyContent='center' flexGrow={1} width='100%'>
            {
            // <Button variant='outlined' sx={{ borderRadius: '6px', margin: 2 }} disabled>View Profile</Button>
            }
            {chatId && <Button variant='outlined' onClick={handleClick} sx={{ borderRadius: '6px', margin: 2, paddingX: 8, textTransform: 'none' }}>Message Guest</Button>}
          </Box>
        </Grid>
      </Grid>
    </PaperWithHeader>
  );
}

export default BookingUserCard;