import { Grid } from '@mui/material';
import React from 'react';
import HorizontalStepper from '../components/atoms/HorizontalStepper/HorizontalStepper';
import Dashboard from '../components/layouts/Dashboard/Dashboard';
import QuickCheckoutPricingFields from '../components/compounds/QuickCheckoutPricingFields/QuickCheckoutPricingFields';
import QuickCheckoutAddressFields from '../components/compounds/QuickCheckoutAddressFields/QuickCheckoutAddressFields';
import QuickCheckoutPaymentFields from '../components/compounds/QuickCheckoutPaymentFields/QuickCheckoutPaymentFields';
import QuickCheckoutReview from '../components/compounds/QuickCheckoutReview/QuickCheckoutReview';

const steps = [
  {
    id: 0,
    label: 'Add cart items',
    prompt: 'Begin by adding items to be purchased',
    optional: false,
  },
  {
    id: 2,
    label: 'Payment Address',
    prompt: 'Add your payment address',
    optional: false,
  },
  {
    id: 3,
    label: 'Payment Details',
    prompt: 'Add card details',
    optional: false,
  },
  {
    id: 4,
    label: 'Review purchase',
    prompt: 'Review purchase and payment details',
    optional: false,
  },
];


function QuickCheckout() {

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContentPanel = () => {
    switch (activeStep) {
      case 0:
        return <QuickCheckoutPricingFields firstStep={true} finalStep={false} handleNext={handleNext} handleBack={handleBack} />;
      case 1:
        return <QuickCheckoutAddressFields firstStep={false} finalStep={false} handleNext={handleNext} handleBack={handleBack} />
      case 2:
        return <QuickCheckoutPaymentFields firstStep={false} finalStep={false} handleNext={handleNext} handleBack={handleBack} />;
      case 3:
        return <QuickCheckoutReview firstStep={false} finalStep={true} handleNext={handleNext} handleBack={handleBack} /> ;
      default:
        return <p>Error invalid step</p>;
    }
  };

  return (
    <Dashboard>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={steps}
          />
          { getStepContentPanel() }
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default QuickCheckout;
