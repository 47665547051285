import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack, TextField } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { PhoneNumber } from '../../atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../atoms/PhoneInput/PhoneInput';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { useState } from 'react';

interface ConfigType {
  headers: {
      Authorization: string;
  };
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  postConfig: ConfigType;
}

export default function NewSigningDocument(props: SimpleDialogProps) {
  const { onClose, open, postConfig } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [notificationPreference, setNotificationPreference] = useState('email');

  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmailAddress('');
    setPhoneNumber('');
    setNotificationPreference('email');
  }

  const handleClose = () => {
    clearForm();
    onClose();
  };

  const handleSend = () => {
    setLoading(true);

    const saveParams = {
      signing_document: {
        first_name: firstName,
        last_name: lastName,
        email_address: emailAddress,
        phone_number: phoneNumber,
        notification_method: notificationPreference,
      }
    };

    axios.post(API_V3_ALLIGATOR_URLS.signingDocuments.create, saveParams, postConfig).then(() => {
      console.log('saved');
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      handleClose();
      setLoading(false);
    });
  };

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const formComplete = () => {
    return firstName.length > 0 && lastName.length > 0
          && (phoneNumber.length > 5 || emailAddress.length > 0)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Send New Waiver To
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack>
          <Grid container spacing={2}>
            <Grid xs={12} lg={6} marginTop={1}>
              <TextField
                label="First name"
                type="text"
                id="first-name"
                name="first-name"
                autoComplete="given-name"
                value={firstName}
                required
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(event.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12} lg={6} sx={{ marginTop: { xs: 0, sm: 1 } }}>
              <TextField
                label="Last name"
                type="text"
                id="last-name"
                name="last-name"
                required
                autoComplete="family-name"
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                type="email"
                id="email-address"
                name="email-address"
                autoComplete="email"
                label="Email Address"
                value={emailAddress}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmailAddress(event.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <PhoneNumber
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                label="Phone Number"
                helperText="Email or phone number must be entered"
              />
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Send waiver via:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={notificationPreference}
                  onChange={(e) => { setNotificationPreference(e.target.value)}}
                >
                  <FormControlLabel value="email" control={<Radio />} label="Email" />
                  <FormControlLabel value="sms" control={<Radio />} label="Text" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
        <Button variant='contained' color='primary' disabled={loading || !formComplete()} onClick={handleSend}>Send</Button>
      </DialogActions>
    </Dialog>
  );
}
