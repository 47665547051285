const drawerWidth = 275;

export const LeftDrawerContainerStyles = {
  width: drawerWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
};

export const LeftDrawerSpacerStyles = {
  flexGrow: 1,
};

export const ContactSupportContainerStyles = {
  margin: 2,
  padding: 2,
  borderRadius: 2,
  backgroundColor: 'lightGrey',
  alignSelf: 'flex-end',
};

export const ContactSupportBoldStyles = {
  fontWeight: 'bold',
};

export const SearchNavContainerStyles = {
  zIndex: (theme: any) => (theme.zIndex.drawer as number) + 1,
  height: 72,
  backgroundColor: (theme: any) => theme.palette.primary.main,
  justifyContent: 'center',
};

export const SearchNavHamburgerStyles = {
  mr: 1,
};

export const SearchNavLabelStyles = {
  display: { xs: 'none', sm: 'block' },
  fontWeight: 'bold',
  marginLeft: 1,
};

export const SearchNavUserStyles = {
  display: { xs: 'block', sm: 'block' },
  paddingRight: 1,
};
