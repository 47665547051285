import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ROUTER_URLS } from '../../constants/router-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchLandings } from '../../hooks/fetchLanding';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import { generatePath, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import LandingCard from '../../components/compounds/LandingCard/LandingCard';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import type { LandingType } from '../../types/landing';

interface IProps extends WrappedProps {};

function Landings({ postConfig }: IProps) {
  const { data: landingRecords, isLoading: loading } = useFetchLandings(postConfig);

  
  const navigate = useNavigate();
  
  const createAndEdit = () => {

    axios.post(API_V3_ALLIGATOR_URLS.landing.create, { title: 'New Landing Page' }, postConfig).then((response) => {
      const newRecord: LandingType = response.data;
      if (newRecord.id) {
        const path = generatePath(ROUTER_URLS.landings.edit, { id: newRecord.id });
        navigate(path);
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Hunt Pay Landing Pages</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          <Button
            type="submit"
            variant="contained"
            onClick={createAndEdit}
            color='secondary'
          >
            + New Landing Page
          </Button>
        </Grid>
      </Grid>
      { loading ? <LoadingSpinner /> : <Grid container spacing={5}>
          { (landingRecords ?? landingRecords ?? []).map((landing) => (
            <LandingCard
              key={landing.id}
              id={landing.id}
              thumbnailUrl={landing.logoImageUrl}
              title={landing.title}
              slug={landing.slug}
            />
          ))}
        </Grid> }
    </Dashboard>
  );
}

export default withCommonTools(Landings);
