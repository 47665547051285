import * as React from 'react';
import Button from '@mui/material/Button';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import HostStep from '../../../components/compounds/OfflineBooking/HostStep';
import UserStep from '../../../components/compounds/OfflineBooking/UserStep';
import ListingStep from '../../../components/compounds/OfflineBooking/ListingStep';
import DetailsStep from '../../../components/compounds/OfflineBooking/DetailsStep';
import type { DateRange } from '@mui/x-date-pickers-pro';
import type { SelectedAdditionalLineItemType } from '../../../types/lineItem';
import PaymentStep from '../../../components/compounds/OfflineBooking/PaymentStep';
import type { ITripParamsType } from '../../../components/compounds/OfflineBooking/PaymentStepForm';

const steps = ['Find Host', 'Select Listing', 'Add Details', 'Choose User', 'Enter Payment'];

function AdminOfflineBooking() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const [hostId, setHostId] = React.useState<number | null>(null);
  const [hostName, setHostName] = React.useState('');

  const [listingId, setListingId] = React.useState<number | null>(null);
  const [listingName, setListingName] = React.useState('');

  const [userId, setUserId] = React.useState<number | null>(null);
  const [userName, setUserName] = React.useState('');

  const [numberOfGuests, setNumberOfGuests] = React.useState('1');
  const [payInFull, setPayInFull] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange<Date>>([
    new Date(),
    new Date(),
  ]);

  const [additionalItems, setAdditionalItems] = React.useState<SelectedAdditionalLineItemType[]>([]);

  const isStepOptional = (step: number) => {
    return step === 4;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const nextEnabled = () => {
    switch (activeStep) {
      case 0:
        return hostId && hostId > 0;
      case 1:
        return listingId && listingId > 0;
      case 3:
        return userId && userId > 0;  
      default:
        return true;
    }
  }

  const nextStepPanel = (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
          variant='contained'
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        <Button onClick={handleNext} variant='contained' disabled={!nextEnabled()}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </React.Fragment>
  )

  const currentStepComponent = () => {
    const selectedAdditionalItems = additionalItems.map((item) => ({ id: item.id, quantity: item.numberOfItems }));
    const params: ITripParamsType = {
      trip: {
        listing_id: listingId,
        start_date: dateRange[0],
        end_date: dateRange[1],
        number_of_guests: numberOfGuests,
        pay_later: !payInFull,
        trip_additional_items: selectedAdditionalItems,
        vrp_code: null,
        offline_payment: false,
        trip_leader_id: userId
      }
    };

    switch (activeStep) {
      case 0:
        return <HostStep setHostId={setHostId} hostId={hostId} setHostName={setHostName} nextStepPanel={nextStepPanel} />;
      case 1:
        return (
          <ListingStep
            setListingId={setListingId}
            hostId={hostId}
            listingId={listingId}
            setListingName={setListingName}
            nextStepPanel={nextStepPanel}
          />
        );
      case 2:
        return (
          <DetailsStep
            listingId={listingId}
            nextStepPanel={nextStepPanel}
            numberOfGuests={numberOfGuests}
            setNumberOfGuests={setNumberOfGuests}
            payInFull={payInFull}
            setPayInFull={setPayInFull}
            dateRange={dateRange}
            setDateRange={setDateRange}
            additionalItems={additionalItems}
            setAdditionalItems={setAdditionalItems}
          />
        );
      case 3:
        return <UserStep setUserId={setUserId} userId={userId} setUserName={setUserName} nextStepPanel={nextStepPanel} />;
      case 4:
        return (
          <PaymentStep
            userId={userId}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            tripParams={params}
            hostName={hostName}
            listingName={listingName}
            userName={userName}
          />
        );
      default:
        return <HostStep setHostId={setHostId} hostId={hostId} setHostName={setHostName} nextStepPanel={nextStepPanel} />;
    }
  }

  return (
    <DashboardLayout>
      <Grid2 container direction='column' marginTop={5} marginBottom={10}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">(Optional)</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          { currentStepComponent() }
        </Box>
      </Grid2>
    </DashboardLayout>
  );
};

export default AdminOfflineBooking;