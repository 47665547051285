import React from 'react';
import { Box, Link } from "@mui/material";
import {
  Link as RouterLink,
} from 'react-router-dom';

interface IProps {
  to: string;
  text: string;
}

function CustomLink({ to, text }: IProps) {
  return (
    <Box sx={{ typography: 'body1' }}>
      <Link component={RouterLink} to={to}>
        { text }
      </Link>
    </Box>
  );
}

export default CustomLink;