import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import { withCommonTools } from '../../compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../compounds/CommonWrapper/withCommonTools';
import axios from 'axios';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { Box, Button, TextField } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import { updateTimeToCurrent } from '../../../utils/dates';

export interface ITripParamsType {
  trip: {
    listing_id: number | null,
    start_date: Date | null,
    end_date: Date | null,
    number_of_guests: string,
    pay_later: boolean,
    trip_additional_items: Array<{ id: string, quantity: number }>,
    vrp_code: string | null,
    trip_leader_id: number | null,
    offline_payment: boolean,
  }
}

interface IProps extends WrappedProps {};

interface IProps {
  tripParams: ITripParamsType;
  activeStep: number;
  setActiveStep: (value: number) => void;
  clientSecret: string;
  stripeSetupIntentId: string;
}

function PaymentStepForm({ postConfig, activeStep, setActiveStep, tripParams, clientSecret, stripeSetupIntentId }: IProps) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [vrpCode, setVrpCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleBack = () => {
    const currentActiveStep = activeStep;
    setActiveStep(currentActiveStep - 1);
  };

  const submitTripWithoutPaymentInfo = () => {
    setLoading(true);
    const params: any = { ...tripParams.trip };
    const startDate = updateTimeToCurrent(params.start_date);
    const endDate = updateTimeToCurrent(params.end_date);
    updateTimeToCurrent(startDate);
    updateTimeToCurrent(endDate);

    params.offline_payment = true;
    params.start_date = startDate?.toISOString();
    params.end_date = endDate?.toISOString();

    axios.post(API_V3_ALLIGATOR_URLS.admin.bookings.create, { trip: params }, postConfig).then((res) => {
      const id = res.data.id;      
      const path = generatePath(ROUTER_URLS.admin.bookings.debug, { id });
      navigate(path);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
    })
  };

  const handleSubmit = () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    setLoading(true);
    
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const apiParams: any = { ...tripParams.trip };
    const startDate = updateTimeToCurrent(apiParams.start_date);
    const endDate = updateTimeToCurrent(apiParams.end_date);
    updateTimeToCurrent(startDate);
    updateTimeToCurrent(endDate);

    apiParams.start_date = startDate?.toISOString();
    apiParams.end_date = endDate?.toISOString();

    apiParams.trip.stripe_setup_intent_id = stripeSetupIntentId;

    // could we call the reserve booking function here?
    axios.post(API_V3_ALLIGATOR_URLS.admin.bookings.create, { trip: apiParams }, postConfig).then((res) => {
      const id = res.data.id;
      stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          // return_url: `http://localhost:3000/admin/bookings/${id as number}/confirm`,
          return_url: `https://host.venku.com/admin/bookings/${id as number}/confirm`,
        },
      }).then((res) => {
        setErrorMessage(res.error.message);
        setLoading(false);
      }).catch((e) => {
        setErrorMessage(e.message);
        setLoading(false);
      })
    }).catch((e) => {
      console.log(e);
    })
  };

  return (
    <>
      <Grid2 xs={12} lg={6}>  
        <>
          { clientSecret && clientSecret.length > 0 &&
            <PaymentDetails errorMessage={errorMessage} />
          }
          <Box sx={{ my: 3 }}>
            <TextField
              required
              id="outlined-required"
              label="Referral Code"
              value={vrpCode}
              onChange={(e) => { setVrpCode(e.target.value) }}
              fullWidth
            />
          </Box>
        </>
      </Grid2>
      <Grid2 xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant='contained'
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button variant='contained' color="inherit" sx={{ mr: 1 }}
            onClick={submitTripWithoutPaymentInfo}
            disabled={loading}
          >
            Save without Payment Info
          </Button>
          <Button variant='contained'
            onClick={handleSubmit}
            disabled={loading}
          >
            Save Trip
          </Button>
        </Box>
      </Grid2>
    </>
  );
};

export default withCommonTools(PaymentStepForm);
