import React, { useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Alert, Box, Button, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';
import { useNavigate } from 'react-router-dom';

interface IProps extends WrappedProps {};

function UserEditPassword({ postConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();
  const userId = getCurrentUserId();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isSaving, setIsSaving] = useState(false);
  const [errorText, setErrorText ] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);

  const handleClickShowPassword = () => { setShowPassword((show) => !show) };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowOldPassword = () => { setShowOldPassword((show) => !show) };

  const handleMouseDownOldPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (): void => {
    if (newPassword !== confirmPassword) {
      setErrorText("New password and confirmation do not match");
      return;
    }

    if (oldPassword && newPassword && confirmPassword) {
      setIsSaving(true);

      const params = {
        user: {
          current_password: oldPassword,
          password: newPassword,
          password_confirmation: confirmPassword,
        }
      };
      axios.patch(API_V3_ALLIGATOR_URLS.auth.changePassword, params, postConfig).then((res) => {
        if (res.status === 200) {
          navigate(ROUTER_URLS.user.profile);
        } else {
          setErrorText("Failed to update password");
        }
      }).catch(() => {
        setIsSaving(false);
        setErrorText("Failed to update password");
      });
    } else {
      setErrorText("Incomplete form")
    }
  };

  const submitEnabled = (): boolean => {
    return newPassword.length > 0 &&
           confirmPassword.length > 0 &&
           newPassword === confirmPassword &&
           Boolean(newPassword.match(/\d/)) &&
           Boolean(newPassword.match(/[a-zA-Z]/));
  }

  if (!userId) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isSaving}>
      <Container component={Stack} maxWidth="md" my={6}>
        <Typography component="h1" variant="h5" my={3}>
            Change Password
        </Typography>

        <Box sx={{ my: 1 }}>
          { errorText &&
            <Alert severity="error" sx={{ my: 2 }}>{ errorText }</Alert>
          }
          <FormControl fullWidth required variant="outlined" sx={{ my: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
            <OutlinedInput
              id="old-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Old Password"
              name="old-password"
              value={oldPassword}
              onChange={(e) => { setOldPassword(e.target.value) }}
            />
          </FormControl>

          <FormControl fullWidth required variant="outlined" sx={{ my: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
            <OutlinedInput
              id="new-password"
              type={showOldPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownOldPassword}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
              name="new-password"
              value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value) }}
            />
          </FormControl>

          { newPassword.length > 0 && newPassword.length < 8 &&
            <Alert severity="error" sx={{ my: 2 }}>Password must contain at least 8 characters</Alert>
          }
          { newPassword.length > 0 && newPassword.length > 7 && !newPassword.match(/\d/) &&
            <Alert severity="error" sx={{ my: 2 }}>Password must include at least one number</Alert>
          }
          { newPassword.length > 0 && newPassword.length > 7 && !newPassword.match(/[a-zA-Z]/) &&
            <Alert severity="error" sx={{ my: 2 }}>Password must include at least one letter</Alert>
          }

          <FormControl fullWidth required variant="outlined" sx={{ my: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showOldPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownOldPassword}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm New Password"
              name="confirm-new-password"
              value={confirmPassword}
              onChange={(e) => { setConfirmPassword(e.target.value) }}
            />
          </FormControl>

          { newPassword.length > 0 && confirmPassword.length > 0 && newPassword !== confirmPassword &&
            <Alert severity="error" sx={{ my: 2 }}>New password and confirm password do not match</Alert>
          }

          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 3, mb: 2 }}
            disabled={!submitEnabled()}
          >
            Reset Password
          </Button>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default withCommonTools(UserEditPassword);