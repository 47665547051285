import React, { useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';

import { useFetchListing } from '../../../hooks/fetchListing';
import ListingFileUploadInput from '../../../components/atoms/ListingFileUploadInput/ListingFileUploadInput';
import type { UploadedFileInterface } from '../../../components/atoms/ListingFileUploadInput/ListingFileUploadInput';

import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';

import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

import DragAndDropGrid from '../../../components/atoms/DragAndDropGrid/DragAndDropGrid';

interface IProps extends WrappedProps {};

function AdminEditListingPhotos({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();
  const { id: listingId } = useParams();

  const imagesToUploadCount = useRef(0);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileInterface[]>([]);
  
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      // if (getCurrentUserId()?.toString() !== listingRecord.host.id.toString()) {
      //   const path = generatePath(ROUTER_URLS.listings.show, { id: listingId });
      //   navigate(path, { state: { policyError: true } });
      // }
      const newFiles = listingRecord.mediaListings.map((file) => ({ id: file.id, url: file.url }))
      setUploadedFiles(newFiles);
      // setUploadedImages(listingRecord.images);
      // setUploadedDocs(listingRecord.additionalDocuments);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord]);

  const navigateToListing = () => {
    const path = generatePath(ROUTER_URLS.admin.listings.show, { id: listingId });
    navigate(path);
  };

  const handleSave = () => {
    setIsSaving(true);
    // const imagesToSave = uploadedImages.map((image, index) => {
    //   const tempImage = { ...image };
    //   if (index === 0) {
    //     tempImage.isPrimaryImage = true;
    //   } else {
    //     tempImage.isPrimaryImage = false;
    //   }
    //   return tempImage
    // });
    const filesToUpload = uploadedFiles.map((file, index) => ({
      id: file.id,
      position: index,
      file: file.signedId,
    }))
    const params = {
      listing: {
        media_listings_attributes: filesToUpload,
        published: true,
      }
      // additionalDocuments: [...uploadedDocs],
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      navigateToListing()
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleDeleteImage = (id: number) => {
    if (listingId) {
      axios.delete(`${API_V3_ALLIGATOR_URLS.listing.deleteImage}${id}`, postConfig).then((response) => {
        console.log(response)
      }).catch((e) => {
        console.log(e);
      })
    }
  };

  const handleCancel = () => {
    navigateToListing();
  }

  const imageAlreadyUploaded = (fileId: number): boolean => {
    return Boolean(uploadedFiles.find((file) => file.id === fileId));
  };

  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item>
          {listingId && <ListingFileUploadInput
            listingId={listingId}
            promptText='Drag and drop your photos here or'
            buttonText='Upload photos'
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            postConfig={postConfig}
            filesToUploadCount={imagesToUploadCount}
            fileAlreadyUploaded={imageAlreadyUploaded}
          />}
          <DragAndDropGrid draggableFiles={uploadedFiles} setDraggableFiles={setUploadedFiles} handleHardDeleteImage={handleDeleteImage} filesToUploadCount={imagesToUploadCount.current} />
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            color='primary'
            variant='outlined'
            onClick={handleCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant='contained' color='primary'>
            Save
          </Button>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(AdminEditListingPhotos);
