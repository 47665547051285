import axios from "axios";
import { useCallback } from "react";
import { API_V3_ALLIGATOR_URLS } from "../../../constants/api-urls";
import useIntervalAsync from "../../../hooks/useInterval";

interface IProps {
  getConfig: {
    headers: {
        Authorization: string;
    }
  };
  bookingId: string;
  watchingStatuses: string[];
  refetch: () => void;
}

export default function TripWorkflowStatusPoller({ getConfig, bookingId, watchingStatuses, refetch }: IProps) {

  const updateState = useCallback(async () => {
    const path = API_V3_ALLIGATOR_URLS.booking.statusCheck.replace(':id', bookingId)
    const res = await axios.get(path, getConfig)
    if (res.data.status && watchingStatuses.includes(res.data.status)) {
      refetch();
    }
  }, []);
  useIntervalAsync(updateState, 2000);

  return <div />;
}