import { useQuery } from "react-query";
import { fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { WaiverResultType } from "../types/waiver";

export const useFetchWaivers = (config: { headers: { Authorization: string } }, tripId: number, ) => {
  const url = `${API_V3_ALLIGATOR_URLS.waivers.list}${tripId}`;
  return useQuery<WaiverResultType[]>(
    ['WaiversInfo', tripId],
    async () => await fetchList(url, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};