import axios from "axios";
import { useQuery } from "react-query";
import { fetchById } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { AdminBookingUserReversalType } from "../types/booking";
import type { PagedResultsType } from "../types/pagedResults";

interface ReversalSearchParamsType {
  reversal: {
    start_date: string;
    end_date: string;
    status: string;
  }
  page: number;
}

export const useFetchAdminReversal = (config: { headers: { Authorization: string } }, id?: string, ) => {
  return useQuery<AdminBookingUserReversalType>(
    ['RefundInfo', id],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.admin.reversals.show, id, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useFetchAdminReversals = (config: { headers: { Authorization: string } }, params: ReversalSearchParamsType) => {
  return useQuery<{reversals: AdminBookingUserReversalType[], page: PagedResultsType}>(
    ['AdminRefundsInfo', params.page],
    async () => {
      const response = await axios.post(API_V3_ALLIGATOR_URLS.admin.reversals.search, params, config);

      if (response.status !== 200) {
        if (response.status === 401) {
          throw new Error('error');
        }
        throw new Error('Network response was not ok');
      }
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};