import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

interface IProps {
  defaultOptions: string[];
  label: string;
  helperText: string;
  required: boolean;
  value: string[];
  setValue: (value: string[]) => void;
}

const filter = createFilterOptions<string>();

function AddableAutocomplete({ defaultOptions, label, helperText, required, value, setValue }: IProps) {
  const selectOptions = defaultOptions;

  const handleOnChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={handleOnChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(inputValue);
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={selectOptions}
      getOptionLabel={(option) => {
        return option;
      }}
      renderOption={(props, option) => {
        const isExisting = selectOptions.some((selectOption) => selectOption === option);
        if (!isExisting) return <li {...props}>Add &quot;{option}&quot;</li>

        return <li {...props}>{option}</li>
      }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth helperText={helperText} required={required} />
      )}
    />
  );
}

export default AddableAutocomplete;