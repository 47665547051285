import * as React from 'react';
import { generatePath, useNavigate } from 'react-router';
import { Avatar, IconButton, MenuItem, MenuList, Paper, Stack, styled } from '@mui/material';
import { useSignOut } from 'react-auth-kit'
import { doLogout } from '../../../api/auth';
import { SearchNavUserStyles } from './StyleOverrides';
import { ROUTER_URLS } from '../../../constants/router-urls';
import { deepOrange } from '@mui/material/colors';

interface IProps {
  postConfig: any;
  thumbnailUrl?: string;
  token: string;
  userFullName: string;
}

const PopoverListItem = styled(Stack)(() => ({
  position: 'relative',
  "&:hover .MuiPaper-root": {
    display: 'block'
  }
}))

const UserAccountPopover = styled(Paper)(() => ({
  position: 'absolute',
  zIndex:2,
  right: 0,
  top: 30,
  width: 170,
  display: 'none'
}))

function UserAvatar({ thumbnailUrl, userFullName }: { thumbnailUrl?: string, userFullName: string }) {
  if (thumbnailUrl && thumbnailUrl.length > 0) {
    return (
      <Avatar alt={userFullName} src={thumbnailUrl} />
    );
  }

  const initials = userFullName.split(' ');
  return (
    <Avatar alt={userFullName} sx={{ bgcolor: deepOrange[500] }}>{initials?.[0]?.[0]} {initials?.[1]?.[0]}</Avatar>
  )
};

function UserPopover({ postConfig, thumbnailUrl, token, userFullName }: IProps) {
  const signOut = useSignOut();
  const navigate = useNavigate();

  const navigateToChangePassword = () => {
    const path = generatePath(ROUTER_URLS.user.editPassword);
    navigate(path);
  };

  const navigateToProfile = () => {
    const path = generatePath(ROUTER_URLS.user.profile);
    navigate(path);
  }

  const logout = () => {
    doLogout(token).then((r) => {
      signOut();
    }).catch((e) => {
      console.log(e);
    });
  };

  return (
      <PopoverListItem sx={SearchNavUserStyles}>
        <IconButton sx={{ p: 0 }}>
          <UserAvatar userFullName={userFullName} thumbnailUrl={thumbnailUrl} />
        </IconButton>
        <UserAccountPopover elevation={4}>
          <MenuList>
            <MenuItem onClick={navigateToProfile}>Profile</MenuItem>
            <MenuItem onClick={navigateToChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={logout}>Log out</MenuItem>
          </MenuList>
        </UserAccountPopover>
      </PopoverListItem>
    
  );
}

export default UserPopover;