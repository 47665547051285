import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { useFetchAdminListing } from '../../../hooks/fetchListing';
import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

interface IProps extends WrappedProps {};

export const removeInvalidOptions = (simpleArray: Array<string | undefined>): string[] => (
  simpleArray.filter((value) => Boolean(value) && value !== ',') as string[]
);

function AdminEditAdminFieldsListing({ getConfig, postConfig }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const [salesConfident, setSalesConfident] = useState(true);
  const [salesScore, setSalesScore] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchAdminListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      setSalesConfident(listingRecord.salesTeamSellable);
      setSalesScore(listingRecord.salesTeamScore.toString());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingRecord])

  const navigateToListing = () => {
    const path = generatePath(ROUTER_URLS.admin.listings.show, { id: listingId });
    navigate(path);
  };

  const handleSave = () => {
    setIsSaving(true);
    const params = {
      listing: {
        sales_team_sellable: salesConfident,
        sales_team_score: salesScore,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      navigateToListing()
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleCancel = () => {
    navigateToListing();
  }

  const handleSalesConfidentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalesConfident(event.target.checked);
  };

  const handleScoreChange = (event: SelectChangeEvent) => {
    setSalesScore(event.target.value);
  };

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column' spacing={3} marginTop={3} marginBottom={10}>
        <Grid item xs={12} lg={6}>
          <Typography variant='h5'>Edit Listing {listingRecord.name}</Typography>
        </Grid>
        
        <Grid item xs={12}>
          <FormControlLabel control={<Checkbox
            checked={salesConfident}
            onChange={handleSalesConfidentChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Sales confident" />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">Sales Score</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={salesScore}
              label="Sales Score"
              onChange={handleScoreChange}
              fullWidth
            >
              { ['1', '2', '3'].map((r: string) => (
                <MenuItem key={r} value={r}>{r}</MenuItem>
              ))}
            </Select>
          </FormControl>  
        </Grid>

        <Grid item sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            color='primary'
            variant='outlined'
            onClick={handleCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant='contained' color='primary'>
            Save
          </Button>
        </Grid>
      </Grid>      
    </DashboardLayout>
  );
}

export default withCommonTools(AdminEditAdminFieldsListing);
