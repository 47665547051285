import React, { useState } from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { ROUTER_URLS } from '../../../constants/router-urls';

interface IProps extends WrappedProps {};

function AdminNewReversals({ postConfig }: IProps) {
  const navigate = useNavigate();

  const { id: payoutId } = useParams();

  const [amount, setAmount] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const handleBack = () => {
    navigate(ROUTER_URLS.admin.payouts.index);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params = {
      purchase_reversal: {
        amount: Number(amount) * 100,
        purchase_payout_id: payoutId,
      }
    }
    axios.post(`${API_V3_ALLIGATOR_URLS.admin.reversals.create}`, params, postConfig).then(() => {
      navigate(ROUTER_URLS.admin.reversals.index);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  return (
    <DashboardLayout loading={isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Create Reversal
        </Typography>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Amount"
            value={amount}
            onChange={(e) => { setAmount(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>  
  );
}

export default withCommonTools(AdminNewReversals);