import getStripe from "../../../utils/get-stripejs";
import { Elements } from "@stripe/react-stripe-js";
import type { StripeElementsOptions } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

interface IProps {
  clientSecret: string;
  children: React.ReactNode;
}

function StripeWrapper({ clientSecret, children }: IProps) {
  const options: StripeElementsOptions = {
    clientSecret,
    // mode: 'payment',
    // amount: 1099,
    // currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {/* ... */},
  };

  const [stripe, setStripe] = useState<any>(null)

  useEffect(() => {
    getStripe().then((stripePromise) => {
      setStripe(stripePromise);
    }).catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    <Elements stripe={stripe} options={options}>
      { children }
    </Elements>
  )
}

export default StripeWrapper;