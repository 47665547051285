import React, { forwardRef } from 'react';
import PhoneNumberInput from 'react-phone-number-input/input';
import { TextField } from '@mui/material';

// add this random type for the depency copied from this bug
// https://github.com/catamphetamine/libphonenumber-js/issues/170
export type E164Number = string;

const StyledPhoneInput = forwardRef<HTMLInputElement>((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      variant='outlined'
      name='phone'
    />
  )
});

StyledPhoneInput.displayName = 'StyledPhoneInputComponent';

interface IProps {
  value: E164Number;
  onChange: (value: E164Number) => void;
  label: string;
  helperText: string;
}

export function PhoneNumber({ value, onChange, label, helperText }: IProps) {
  return (
    <PhoneNumberInput
      country="US"
      value={value}
      onChange={onChange}
      defaultCountry="US"
      label={label}
      fullWidth
      helperText={helperText}
      inputComponent={StyledPhoneInput}
    />
  );
};