import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { LISTING_AMENITIES_DEFAULT_OPTIONS, LISTING_GEAR_REQUIREMENT_OPTIONS, LISTING_GUIDED_TYPE_OPTIONS, LISTING_LODGING_TYPE_OPTIONS, LISTING_PROPERTY_TYPE_OPTIONS, newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import RichTextEditor from '../../components/atoms/RichTextEditor/RichTextEditor';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { ROUTER_URLS } from '../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import type { ListingDetailType } from '../../types/listing';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import LongTextAddableAutocomplete from '../../components/atoms/LongTextAddableAutocomplete/LongTextAddableAutocomplete';
import { listingStepThreeValid } from '../../utils/listing';

interface IProps extends WrappedProps {};

function EditListingAdditionalDetails({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const activeStep = 2;
  const [handicapAccessible, setHandicapAccessible] = useState(false);
  const [kidFriendly, setKidFriendly] = useState(false);
  const [petFriendly, setPetFriendly] = useState(false);
  
  const [amenities, setAmenities] = useState<string[]>([]);
  const [gearRequirements, setGearRequirements] = useState<string[]>([]);

  const [invasive, setInvasive] = useState(false);
  const [acreage, setAcreage] = useState('');
  const [lodgingType, setLodgingType] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [guidedType, setGuidedType] = useState('');

  const [houseRules, setHouseRules] = useState('');
  const [listingDescription, setListingDescription] = useState('');
  const [testimonials, setTestimonials] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      // if (getCurrentUserId()?.toString() !== listingRecord.host.id.toString()) {
      //   const path = generatePath(ROUTER_URLS.listings.show, { id: listingId });
      //   navigate(path, { state: { policyError: true } });
      // }

      setHandicapAccessible(listingRecord.handicappedAccessible);
      setKidFriendly(listingRecord.kidFriendly);
      setPetFriendly(listingRecord.petFriendly);
      
      const filteredAmenities = listingRecord.amenities.filter((a) => a.length > 0);
      if (filteredAmenities.length > 0) {
        setAmenities(filteredAmenities);
      }
      const filteredGearRequirements = listingRecord.extras.filter((g) => g.length > 0);
      if (filteredGearRequirements.length > 0) {
        setGearRequirements(filteredGearRequirements);
      }
      setHouseRules(listingRecord.houseRules);
      setListingDescription(listingRecord.description ?? '');
      setTestimonials(listingRecord.testimonials ?? '');

      setInvasive(listingRecord.invasive);
      setAcreage(listingRecord.acreage?.toString() ?? '');
      setLodgingType(listingRecord.lodgingType ?? '');
      setPropertyType(listingRecord.propertyType ?? '');
      setGuidedType(listingRecord.guidedType ?? '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.editPricing, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);
    const params = {
      listing: {
        handicapped_accessible: handicapAccessible,
        kid_friendly: kidFriendly, 
        pet_friendly: petFriendly,
        extras: gearRequirements,
        amenities,
        house_rules: houseRules,
        description: listingDescription,
        invasive,
        acreage,
        testimonials,
        lodging_type: lodgingType,
        property_type: propertyType,
        guided_type: guidedType,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) handleNext();
        setIsSaving(false);
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleBack = () => {
    const backUrl = generatePath(ROUTER_URLS.listings.editAddress, { id: listingId });
    navigate({
      pathname: backUrl,
    });
  };

  const handleHandicapAccessibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHandicapAccessible(event.target.checked);
  };

  const handleKidFriendlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKidFriendly(event.target.checked);
  };

  const handlePetFriendlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPetFriendly(event.target.checked);
  };

  const handleInvasiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvasive(event.target.checked);
  };

  const handleAcreageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcreage(event.target.value);
  }

  const handleLodgingTypeChange = (event: SelectChangeEvent) => {
    setLodgingType(event.target.value);
  }

  const handlePropertyTypeChange = (event: SelectChangeEvent) => {
    setPropertyType(event.target.value);
  }

  const handleGuidedTypeChange = (event: SelectChangeEvent) => {
    setGuidedType(event.target.value);
  }

   // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='row' padding={2}>
            <Grid item xs={12} lg={10}>
              <LongTextAddableAutocomplete
                value={amenities}
                setValue={setAmenities}
                defaultOptions={LISTING_AMENITIES_DEFAULT_OPTIONS}
                label="Amenities"
                helperText="Add amenities to your listing by typing or selecting them"
                required
              />
            </Grid>
          </Grid>

          <Grid container direction='row' padding={2}>
            <Grid item xs={12} lg={10}>
              <LongTextAddableAutocomplete
                value={gearRequirements}
                setValue={setGearRequirements}
                defaultOptions={LISTING_GEAR_REQUIREMENT_OPTIONS}
                label="Gear Requirements"
                helperText="Add gear requirements to your listing by typing or selecting them"
                required
              />
            </Grid>
          </Grid>
          <RichTextEditor label="Listing Description *" value={listingDescription} setValue={setListingDescription} />
          <RichTextEditor label="House Rules" value={houseRules} setValue={setHouseRules} />
          <RichTextEditor label="Reviews" value={testimonials} setValue={setTestimonials} />
          <Grid container direction='row' paddingX={2} spacing={2}>
            <Grid item lg={6}>
              <FormControlLabel control={<Checkbox
                checked={handicapAccessible}
                onChange={handleHandicapAccessibleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Handicap Accessible" />
              <FormControlLabel control={<Checkbox
                checked={kidFriendly}
                onChange={handleKidFriendlyChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Kid Friendly" />
              <FormControlLabel control={<Checkbox
                checked={petFriendly}
                onChange={handlePetFriendlyChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Dog Friendly" />
            </Grid>
          </Grid>
          <Grid container direction='row' paddingX={2} spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel control={<Checkbox
                checked={invasive}
                onChange={handleInvasiveChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Invasive Species Hunt" />
            </Grid>
            <Grid item xs={12} lg={5}>
              <TextField
                required
                id="outlined-required"
                label="Acreage"
                value={acreage}
                onChange={handleAcreageChange}
                fullWidth
                placeholder="Example: 560"
                helperText="Please round to whole numbers, for example: 559.6 would be 560"
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Lodging</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={lodgingType}
                  label="Lodging"
                  onChange={handleLodgingTypeChange}
                >
                  {
                    LISTING_LODGING_TYPE_OPTIONS.map((option) => (
                      <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Public/Private Property</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={propertyType}
                  label="Public/Private Property"
                  onChange={handlePropertyTypeChange}
                >
                  {
                    LISTING_PROPERTY_TYPE_OPTIONS.map((option) => (
                      <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Guided</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={guidedType}
                  label="Guided"
                  onChange={handleGuidedTypeChange}
                >
                  {
                    LISTING_GUIDED_TYPE_OPTIONS.map((option) => (
                      <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item lg={10} marginTop={5}>
              <HorizontalStepperActions
                firstStep={false}
                finalStep={false}
                handleNext={handleSave}
                handleBack={handleBack}
                handleSave={handleSave}
                isValid={listingStepThreeValid(amenities, gearRequirements, listingDescription)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListingAdditionalDetails);
