import React from 'react';
import { Box, Button } from '@mui/material';

interface IProps {
  // activeStep: number;
  // steps: ISteps[];
  firstStep: boolean;
  finalStep: boolean;
  handleNext: (moveToNextStep: boolean) => void;
  handleBack?: () => void;
  // handleReset: () => void;
  handleSave?: (moveToNextStep: boolean) => void;
  isValid: boolean;
}

function HorizontalStepperActions({
  // activeStep,
  firstStep,
  finalStep,
  // steps,
  handleNext,
  handleBack,
  handleSave,
  // handleReset,
  isValid,
}: IProps) {
  // const handleSave = () => {
  //   console.log('SAVE RECORD!!!');
  // }

  return (
    <>
      
      
      
      
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        {
          handleBack && <Button
            color="inherit"
            disabled={firstStep}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
        }
        <Box sx={{ flex: '1 1 auto' }} />
        {
          // isStepOptional(activeStep) && (
          // <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
          //   Skip
          // </Button>
          // )
        }
        {
          !firstStep && handleSave && (
            <Button disabled={!isValid} onClick={() => { handleSave(false) }}>
              Save
            </Button>
          )
        }
        <Button disabled={!isValid} variant='contained' color='secondary' onClick={() => { handleNext(true) }}>
          {finalStep ? 'Finish' : 'Save & Next'}
        </Button>
      </Box>
    </>
  );
};

export default HorizontalStepperActions;