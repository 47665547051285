import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import StripeWrapper from '../StripeWrapper/StripeWrapper';
import { withCommonTools } from '../../compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../compounds/CommonWrapper/withCommonTools';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import PaymentStepForm from './PaymentStepForm';
import type { ITripParamsType } from './PaymentStepForm';
import { Box, Stack, Typography } from '@mui/material';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';
import { format } from 'date-fns';


interface IProps extends WrappedProps {};

interface IProps {
  userId: number | null;
  tripParams: ITripParamsType;
  activeStep: number;
  setActiveStep: (value: number) => void;
  hostName: string;
  listingName: string;
  userName: string;
}

function PaymentStep({
  userId,
  postConfig,
  activeStep,
  setActiveStep,
  tripParams,
  hostName,
  listingName,
  userName,
}: IProps) {
  const [clientSecret, setClientSecret] = useState("");
  const [stripeSetupIntentId, setStripeSetupIntentId] = useState('');

  useEffect(() => {
    if (userId) {
      const params = { customer: { user_id: userId } };
      axios.post(API_V3_ALLIGATOR_URLS.admin.checkout.customer, params, postConfig).then((res) => {
        setClientSecret(res.data.client_secret)
        setStripeSetupIntentId(res.data.stripe_setup_intent_id)
      }).catch((e) => {
        console.log(e)
      })
    }
  }, [])

  return (
    <Grid2 container spacing={4} marginX={3}>
      <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h5'>Add payment details if needed</Typography>
      </Grid2>
      {clientSecret && clientSecret.length > 0 ? <StripeWrapper clientSecret={clientSecret}>
        <Grid2 xs={12} lg={6}>
          <Stack spacing={2}>
            <Typography variant='h5' sx={{ textAlign: 'center' }}>Confirm Booking Details</Typography>
            <Box>
              <Typography variant='h6'>Host Name:</Typography>
              <Typography variant='body1'>{hostName}</Typography>
            </Box>
            <Box>
              <Typography variant='h6'>User Name:</Typography>
              <Typography variant='body1'>{userName}</Typography>
            </Box>
            <Box>
              <Typography variant='h6'>Listing Name:</Typography>
              <Typography variant='body1'>{listingName}</Typography>
            </Box>
            <Box>
              <Typography variant='h6'>Trip Details:</Typography>
              <Typography variant='body1'>{tripParams.trip.number_of_guests} Guests</Typography>
              {tripParams.trip.start_date && tripParams.trip.end_date &&
                <Typography variant='body1'>{format(tripParams.trip.start_date, 'M/d/yyyy')} to {format(tripParams.trip.end_date, 'M/d/yyyy')}</Typography>}
            </Box>
          </Stack>
        </Grid2>
        <PaymentStepForm
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          tripParams={tripParams}
          clientSecret={clientSecret}
          stripeSetupIntentId={stripeSetupIntentId}
        />
      </StripeWrapper>
      : 
        <Grid2 xs={12}>
          <LoadingSpinner />
        </Grid2>
      }
    </Grid2>
  );
};

export default withCommonTools(PaymentStep);
