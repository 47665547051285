import React from 'react';
import { Card, CardActionArea, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import LandingCardActions from './LandingCardActions';
import LandingCardImage from './LandingCardImage';


interface IProps {
  id: string;
  thumbnailUrl?: string;
  title: string;
  slug: string;

};

function LandingCard({
  id,
  title,
  thumbnailUrl,
  slug,
}: IProps) {
  const navigate = useNavigate();

  const navigateToLanding = (id: string) => {
    const path = generatePath(ROUTER_URLS.landings.edit, { id });
    navigate(path);
  };

  return (
    <Grid item xs={12} md={6} lg={4} key={id}>
      <Card>
        <CardHeader
          action={<LandingCardActions
              id={id}
              slug={slug}
            />}
          title={title}
          subheader=""
        />
        <CardActionArea onClick={() => { navigateToLanding(id) }}>
          <LandingCardImage thumbnailUrl={thumbnailUrl} loading={false} />
        </CardActionArea>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          
        </CardActions>
      </Card>
    </Grid>
  );
}

export default LandingCard;

