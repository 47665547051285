import type { SettingStateType, SettingTimeZoneType } from "../types/setting";

export const STATE_DROPDOWN_OPTIONS: SettingStateType[] = [
  { id: "al", label: "Alabama" },
  { id: "ak", label: "Alaska" },
  { id: "az", label: "Arizona" },
  { id: "ar", label: "Arkansas" },
  { id: "ca", label: "California" },
  { id: "co", label: "Colorado" },
  { id: "ct", label: "Connecticut" },
  { id: "dc", label: "District Of Columbia" },
  { id: "de", label: "Delaware" },
  { id: "fl", label: "Florida" },
  { id: "ga", label: "Georgia" },
  { id: "hi", label: "Hawaii" },
  { id: "id", label: "Idaho" },
  { id: "il", label: "Illinois" },
  { id: "in", label: "Indiana" },
  { id: "ia", label: "Iowa" },
  { id: "ks", label: "Kansas" },
  { id: "ky", label: "Kentucky" },
  { id: "la", label: "Louisiana" },
  { id: "me", label: "Maine" },
  { id: "md", label: "Maryland" },
  { id: "ma", label: "Massachusetts" },
  { id: "mi", label: "Michigan" },
  { id: "mn", label: "Minnesota" },
  { id: "ms", label: "Mississippi" },
  { id: "mo", label: "Missouri" },
  { id: "mt", label: "Montana" },
  { id: "ne", label: "Nebraska" },
  { id: "nv", label: "Nevada" },
  { id: "nh", label: "New Hampshire" },
  { id: "nj", label: "New Jersey" },
  { id: "nm", label: "New Mexico" },
  { id: "ny", label: "New York" },
  { id: "nc", label: "North Carolina" },
  { id: "nd", label: "North Dakota" },
  { id: "oh", label: "Ohio" },
  { id: "ok", label: "Oklahoma" },
  { id: "or", label: "Oregon" },
  { id: "pa", label: "Pennsylvania" },
  { id: "pr", label: "Puerto Rico" },
  { id: "ri", label: "Rhode Island" },
  { id: "sc", label: "South Carolina" },
  { id: "sd", label: "South Dakota" },
  { id: "tn", label: "Tennessee" },
  { id: "tx", label: "Texas" },
  { id: "ut", label: "Utah" },
  { id: "vt", label: "Vermont" },
  { id: "va", label: "Virginia" },
  { id: "wa", label: "Washington" },
  { id: "wv", label: "West Virginia" },
  { id: "wi", label: "Wisconsin" },
  { id: "wy", label: "Wyoming" },
  { id: "other", label: "Foreign Country" },
];

export const LANGUAGES_DROPDOWN_OPIONS: string[] = [
  'English',
  'Spanish',
  'French',
  'Other',
];

export const TIME_ZONES_DROPDOWN_OPTIONS: SettingTimeZoneType[] = [
  { id: 'America/Chicago', label: 'Central Time'  },
  { id: 'America/New_York', label: 'Eastern Time'  },
  { id: 'America/Denver', label: 'Mountain Time' },
  { id: 'America/Los_Angeles', label: 'Pacific Time' },
  { id: 'America/Juneau', label: 'Alaska Time' },
  { id: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time' },
  { id: 'Atlantic/Faroe', label: 'Other' },
];

export const ADMIN_USER = 'admin';
export const REFERRAL_USER = 'referral';
export const HOST_USER = 'host';
export const HOST_AND_REFERRAL_USER = 'hostAndReferral'
export const REGULAR_USER = 'user';
export const SALES_USER = 'sales'

export const ROLES = [REGULAR_USER, HOST_USER, ADMIN_USER, REFERRAL_USER, HOST_AND_REFERRAL_USER, SALES_USER];

export const FEATURE_FLAGS = {
  contactSupport: false,
}