import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { ROUTER_URLS } from '../../../constants/router-urls';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import axios from 'axios';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { Alert, Box, Button, Container, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { PhoneNumber } from '../../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../../components/atoms/PhoneInput/PhoneInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';

interface IProps extends WrappedProps {};

const CREATABLE_USER_TYPES = [
  { id: 'host', label: 'Host' },
  { id: 'user', label: 'User' },
  { id: 'referral', label: 'Referral' },
  { id: 'hostAndReferral', label: 'Host & Referral' },
  { id: 'sales', label: 'Sales' }
]

function AdminUserCreateHost({ postConfig }: IProps) {
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('US');
  const [businessType, setBusinessType] = useState('individual');

  const [userType, setUserType] = useState('host');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorText, setErrorText ] = useState('');
  
  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const handleClickShowPassword = () => { setShowPassword((show) => !show) };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value);
  };

  const handleBack = () => {
    navigate(ROUTER_URLS.user.profile);
  }

  const handleChangeCountry = (event: SelectChangeEvent) => {
    setCountry(event.target.value);
  };

  const handleChangeBusinessType = (event: SelectChangeEvent) => {
    setBusinessType(event.target.value);
  };

  const handleSubmit = (): void => {
    if (newPassword !== confirmPassword) {
      setErrorText("New password and confirmation do not match");
      return;
    }

    if (newPassword && confirmPassword) {
      setIsSaving(true);

      const params = {
        user: {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: email.trim(),
          phone_number: phoneNumber,
          password: newPassword,
          zip_code: zipCode,
          country,
          user_type: userType,
          business_type: businessType,
        }
      }
      axios.post(API_V3_ALLIGATOR_URLS.admin.users.create, params, postConfig).then((response) => {
        if (response.data?.id) {
          const id = response.data.id;
          const path = generatePath(ROUTER_URLS.admin.users.show, { id });
          navigate(path);
        }        
      }).catch(() => {
        setIsSaving(false);
      });
    } else {
      setErrorText("Incomplete form")
    }
  };

  const submitEnabled = (): boolean => {
    return newPassword.length > 0 &&
           confirmPassword.length > 0 &&
           newPassword === confirmPassword &&
           Boolean(newPassword.match(/\d/)) &&
           Boolean(newPassword.match(/[a-zA-Z]/)) &&
           firstName.length > 0 &&
           lastName.length > 0 &&
           email.length > 0 &&
           zipCode.length > 0 &&
           phoneNumber.length > 0;
  }

  return (
    <DashboardLayout loading={isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Create User
        </Typography>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="first-name-outlined-required"
            label="First Name"
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="last-name-outlined-required"
            label="Last Name"
            value={lastName}
            onChange={(e) => { setLastName(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="email-outlined-required"
            label="Email"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            id="zipcode-outlined-required"
            label="Zip Code"
            value={zipCode}
            onChange={(e) => { setZipCode(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              id="country-select"
              value={country}
              label="Country"
              onChange={handleChangeCountry}
            >
              <MenuItem value={'US'}>United States</MenuItem>
              <MenuItem value={'CA'}>Canada</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="business-type-label">Business Type</InputLabel>
            <Select
              labelId="business-type-label"
              id="business-type-select"
              value={businessType}
              label="Business Type"
              onChange={handleChangeBusinessType}
            >
              <MenuItem value={'individual'}>Individual</MenuItem>
              <MenuItem value={'company'}>Business</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ my: 3 }}>
          <PhoneNumber
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            label="Contact Phone Number"
            helperText=""
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">User Type</InputLabel>
            <Select
              labelId="user-type-label"
              id="user-type-select"
              value={userType}
              label="User Type"
              onChange={handleUserTypeChange}
              fullWidth
            >
              { CREATABLE_USER_TYPES.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ my: 1 }}>
          { errorText &&
            <Alert severity="error" sx={{ my: 2 }}>{ errorText }</Alert>
          }
          <FormControl fullWidth required variant="outlined" sx={{ my: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
            <OutlinedInput
              id="new-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
              name="new-password"
              value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value) }}
            />
          </FormControl>

          { newPassword.length > 0 && newPassword.length < 8 &&
            <Alert severity="error" sx={{ my: 2 }}>Password must contain at least 8 characters</Alert>
          }
          { newPassword.length > 0 && newPassword.length > 7 && !newPassword.match(/\d/) &&
            <Alert severity="error" sx={{ my: 2 }}>Password must include at least one number</Alert>
          }
          { newPassword.length > 0 && newPassword.length > 7 && !newPassword.match(/[a-zA-Z]/) &&
            <Alert severity="error" sx={{ my: 2 }}>Password must include at least one letter</Alert>
          }

          <FormControl fullWidth required variant="outlined" sx={{ my: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm New Password"
              name="confirm-new-password"
              value={confirmPassword}
              onChange={(e) => { setConfirmPassword(e.target.value) }}
            />
          </FormControl>

          { newPassword.length > 0 && confirmPassword.length > 0 && newPassword !== confirmPassword &&
            <Alert severity="error" sx={{ my: 2 }}>New password and confirm password do not match</Alert>
          }
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} disabled={!submitEnabled()} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default withCommonTools(AdminUserCreateHost);