import React from 'react';
import SalesReportLayout from '../../components/compounds/Reports/SalesReport/SalesReportLayout';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';

function RecentDeposits() {

  return (
    <DashboardLayout>
      <SalesReportLayout />
    </DashboardLayout>
  );
}

export default RecentDeposits;
