import React, { useEffect, useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { useFetchUser } from '../../hooks/fetchUser';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';
import { TIME_ZONES_DROPDOWN_OPTIONS } from '../../constants/settings';
import type { SettingTimeZoneType } from '../../types/setting';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateFromISOString, updateTimeToCurrent } from '../../utils/dates';
import { phoneNumberForInput } from '../../utils/phoneNumber';

interface IProps extends WrappedProps {};

function UserEditAccount({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
  const [timeZone, setTimeZone] = useState(TIME_ZONES_DROPDOWN_OPTIONS[0].id);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date());
  const [gender, setGender] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const { data: userRecord, isLoading } = useFetchUser(getConfig);

  useEffect(() => {
    if (userRecord) {
      if (userRecord.phoneNumber) {
        const phone = phoneNumberForInput(userRecord.phoneNumber);
        setPhoneNumber(phone);
      }
      setTimeZone(userRecord.timeZone);
      const formattedDateOfBirth = dateFromISOString(userRecord.dateOfBirth)
      setDateOfBirth(formattedDateOfBirth);
      setGender(userRecord.gender?.toLowerCase() ?? '');
    }
  }, [userRecord]);

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const handleTimeZoneChange = (event: SelectChangeEvent) => {
    setTimeZone(event.target.value);
  };

  const handleBack = () => {
    navigate(ROUTER_URLS.user.profile);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params = {
      user: {
        phone_number: phoneNumber,
        time_zone: timeZone,
        date_of_birth: dateOfBirth,
        gender,
      }
    }

    axios.patch(API_V3_ALLIGATOR_URLS.users.updateProfile, params, postConfig).then(() => {
      navigate(ROUTER_URLS.user.profile);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Account Details
        </Typography>
        <Box sx={{ my: 3 }}>
          <PhoneNumber
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            label="Contact Phone Number"
            helperText=""
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              value={dateOfBirth}
              onChange={(newValue) => {
                updateTimeToCurrent(newValue)
                setDateOfBirth(newValue)
              }}
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={gender}
              onChange={(e) => { setGender(e.target.value)}}
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="female" control={<Radio />} label="Female" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Time Zone</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={timeZone}
              label="Time Zone"
              onChange={handleTimeZoneChange}
            >
              { TIME_ZONES_DROPDOWN_OPTIONS.map((option: SettingTimeZoneType) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Please select your preffered time zone notification purposes</FormHelperText>
          </FormControl>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default withCommonTools(UserEditAccount);