import React from 'react';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import { ROLES, SALES_USER } from '../../../constants/settings';

// workaround for "Cannot update a component (`AuthProvider`) while rendering
// a different component <component>."
// per https://github.com/react-auth-kit/react-auth-kit/issues/1023#issuecomment-1379461046
export interface PrivateRouteProps {
  rolesRequired?: string[];
};

function PrivateRoute({ rolesRequired }: PrivateRouteProps): any {
  const isAuthed = useIsAuthenticated();
  const location = useLocation();
  const auth = useAuthUser();

  // if a role is required it should match an existing one
  if (rolesRequired && rolesRequired.length > 0) {
    rolesRequired.forEach((roleRequired) => {
      if (!ROLES.includes(roleRequired)) {
        throw new Error("Error - Invalid config role");
      }
    })
  }

  // if the user is not logged in send to login
  if (!isAuthed()) {
    return <Navigate to={ROUTER_URLS.auth.login} state={{from: location}} replace/>;
  }

  // if a role is required check it otherwise send them along otherwise send them home
  // sales team home is a different place that everyone else home
  const userInfo = auth();
  if (rolesRequired) {
    if (userInfo && rolesRequired.includes(userInfo.role)) {
      return <Outlet />;  
    } else {
      if (userInfo && userInfo.role === SALES_USER) {
        return <Navigate to={ROUTER_URLS.referralProgram.dashboard} replace />
      }
      return <Navigate to={ROUTER_URLS.home} replace/>
    }
  }

  return <Outlet />;
};

export default PrivateRoute;
