import axios from "axios";
import { useQuery } from "react-query";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { ContactHostType } from "../types/contactHost";
import type { PagedResultsType } from "../types/pagedResults";

export const useFetchAdminContactHosts = (config: { headers: { Authorization: string } }, params: { page: number }) => {
  return useQuery<{ contacts: ContactHostType[], page: PagedResultsType}>(
    ['AdminContactHostInfo'],
    async () => {
      const response = await axios.get(API_V3_ALLIGATOR_URLS.admin.hostContacts.list, config);

      if (response.status !== 200) {
        if (response.status === 401) {
          throw new Error('error');
        }
        throw new Error('Network response was not ok');
      }
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};