import React from 'react';
import { Grid } from '@mui/material';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';

function ConfirmBooking() {

  // const { data: listingRecord, isLoading, error } = useFetchListing(listingId || '');

  // useEffect(() => {
  //   if (listingRecord) {
  //     setTitle(listingRecord.name);
  //     setCategory(listingRecord.categorySlug);
  //     setShortDescription(listingRecord.shortDescription);
  //     // setKeywords
  //     // @todo: need to map an array of strins into an array of select options - add util for this
  //   }
  // }, [listingRecord])

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  // if (!listingRecord) {
  //   return (
  //     <p>Failed to load listing record to edit</p>
  //   )
  // }

  return (
    <Dashboard>
      <Grid container direction='column'>
        
      </Grid>
    </Dashboard>
  );
}

export default ConfirmBooking;
