import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../constants/api-urls';

export const doLogin = async (
  username: string,
  password: string,
  success: any,
  failure: any
) => {
  const data = {
    grant_type: 'password',
    email: username,
    password,
    client_id: process.env.REACT_APP_ALLIGATOR_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_ALLIGATOR_OAUTH_CLIENT_SECRET,
  }
  axios.post(API_V3_ALLIGATOR_URLS.auth.login, data).then(success).catch(failure)
};

export const doLogout = async (token: string) => {
  const params = {
    token: token.split(' ')[1],
    client_id: process.env.REACT_APP_ALLIGATOR_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_ALLIGATOR_OAUTH_CLIENT_SECRET,
  }

  axios.post(API_V3_ALLIGATOR_URLS.auth.logout, params).then(() => {
  }).catch((e) => {
    console.log(e);
  });
};

export const doRefresh = async (
  refreshToken: string,
) => {
    const data = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: process.env.REACT_APP_ALLIGATOR_OAUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_ALLIGATOR_OAUTH_CLIENT_SECRET,
    }
    return await axios.post(API_V3_ALLIGATOR_URLS.auth.refresh, data)
};
