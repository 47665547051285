import React from 'react';
import { Grid, Typography } from "@mui/material";

interface IProps {
  attribute: string;
  value: string;
};

function RecordDetailRichText({ attribute, value }: IProps) {
  return (
    <Grid container direction='row' marginY={2}>
      <Grid item xs={12} lg={3}>
        <Typography variant='body1' sx={{ fontWeight: 600 }}>{ attribute }</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div dangerouslySetInnerHTML={{__html: value}} />
      </Grid>
    </Grid>
  );
};

export default RecordDetailRichText;