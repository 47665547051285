import React from 'react';
import { Box, Typography } from '@mui/material';
import { ContactSupportContainerStyles, ContactSupportBoldStyles } from './StyleOverrides';

export function ContactSupport() {
  return (
    <Box
      sx={ContactSupportContainerStyles}
    >
      <Typography
          variant="body2"
          component="div"
          sx={ContactSupportBoldStyles}
        >
          Need help?
      </Typography>
      <Typography
          variant="body2"
          component="div"
          marginTop={1}
        >
          Contact our support team with your questions.
      </Typography>
      <Typography
          variant="body2"
          component="div"
          color='primary'
          marginTop={2}
        >
          Message Us
      </Typography>
    </Box>
  );
}
