import React, { useEffect, useRef, useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useFetchUser } from '../../hooks/fetchUser';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { API_URLS } from '../../constants/api-urls';
import { ROUTER_URLS } from '../../constants/router-urls';
import type { IReadFile } from '../../types/readFile';
import { getCroppedImg } from '../../utils/cropImage';
import RoundImageCrop from '../../components/atoms/ImageCrop/ImageCrop';
import FileUploadInput from '../../components/atoms/FileUploadInput/FileUploadInput';
import { computeChecksumMd5, uploadToS3 } from '../../utils/fileUpload';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';

interface IProps extends WrappedProps {};

function UserEditProfile({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [shortBio, setShortBio] = useState('');
  const [fullBio, setFullBio] = useState('');
  const [smsTransactionalOptIn, setSmsTransactionalOptIn] = useState(false);
  const [smsMarketingOptIn, setSmsMarketingOptIn] = useState(false);
  const [notificationPreference, setNotificationPreference] = useState('');

  const [uploadedImages, setUploadedImages] = useState<IReadFile[]>([]);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>(null);
  const imagesToUploadCount = useRef(0);

  // profile picture

  const [isSaving, setIsSaving] = useState(false);

  const { data: userRecord, isLoading } = useFetchUser(getConfig);

  useEffect(() => {
    if (userRecord) {
      setFirstName(userRecord.firstName);
      setLastName(userRecord.lastName);
      setShortBio(userRecord.shortBio);
      setFullBio(userRecord.longBio);

      setSmsTransactionalOptIn(userRecord.transactionalMessageOptIn);
      setSmsMarketingOptIn(userRecord.marketingMessageOptIn);
      setNotificationPreference(userRecord.notificationPreference ?? '');
      // profile pic url
    }
  }, [userRecord]);

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  const handleBack = () => {
    navigate(ROUTER_URLS.user.profile);
  }

  const readFile = async (file: File) => {
    try {
      return await new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => { resolve(reader.result) }, false)
        reader.readAsDataURL(file)
      })
    } catch (error) {
      console.log(error);
    }
  }

  const saveAfterUpload = async (file: File) => {
    try {
      const imageDataUrl = await readFile(file).catch((e) => { console.log(e) }) as string;
      if (imageDataUrl) {
        const readImage = {
          publicUrl: imageDataUrl,
          ...file,
        }
        setUploadedImages([readImage]);
      }
    } catch(e) {
      console.log(e);
    }
  };

  const getS3Url = async (newFile: File) => {
    const checkSum = await computeChecksumMd5(newFile);
    return await axios.post(
      API_V3_ALLIGATOR_URLS.directUpload.create,
      {
        blob: {
          filename: newFile.name,
          content_type: newFile.type,
          byte_size: newFile.size,
          checksum: checkSum, /* base 64 of the MD5 hash of the file */
        }
      },
      {
        headers: { Authorization: postConfig.headers.Authorization },
      }
    )
  };

  const saveImage = async (): Promise<string | undefined> => {
    try {
      const croppedImage= await getCroppedImg(
        uploadedImages[0].publicUrl ?? '',
        croppedAreaPixels,
      ) as Blob;
      const newImage = new File([croppedImage], 'profilePicture');

      const s3UrlRes = await getS3Url(newImage);

      if (s3UrlRes.data.direct_upload) {
        const directUpload = s3UrlRes.data.direct_upload;

        const uploadToS3Res = await uploadToS3(directUpload.url, directUpload.headers, newImage)
        if (uploadToS3Res.status === 200) {
          return s3UrlRes.data.signed_id;
        }             
      }

    } catch (e) {
      console.error(e)
      return undefined;
    }
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params: any = {
      user: {
        first_name: firstName,
        last_name: lastName,
        short_bio: shortBio,
        long_bio: fullBio,

        marketing_message_opt_in: smsMarketingOptIn,
        transactional_message_opt_in: smsTransactionalOptIn,
        notification_preference: notificationPreference,
      }
    }

    saveImage().then((signedId) => {
      if (signedId && signedId.length > 0) {
        params.user.avatar = signedId
      }
      axios.patch(
        API_V3_ALLIGATOR_URLS.users.profile,
        params,
        getConfig,
      ).then(() => {
        navigate(ROUTER_URLS.user.profile);
      }).catch((e) => {
        console.log(e)
        setIsSaving(false)
      })
    }).catch((e) => {
      console.log(e);
      setIsSaving(false)
    });
  };

  const handleSmsTransactionalOptInChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSmsTransactionalOptIn(event.target.checked);
  };

  const handleSmsMarketingOptInChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSmsMarketingOptIn(event.target.checked);
  };

  const handleNotificationPreferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationPreference((event.target as HTMLInputElement).value);
  };

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Host Profile Details
        </Typography>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="First Name"
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Last Name"
            value={lastName}
            onChange={(e) => { setLastName(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Short Bio"
            value={shortBio}
            onChange={(e) => { setShortBio(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Full Bio"
            value={fullBio}
            onChange={(e) => { setFullBio(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControlLabel control={<Checkbox onChange={handleSmsTransactionalOptInChange} checked={smsTransactionalOptIn} />} label="Recieve trip updates and notifications via text" />
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControlLabel control={<Checkbox onChange={handleSmsMarketingOptInChange} checked={smsMarketingOptIn} />} label="Recieve promotional offers via text" />
        </Box>
        <Box sx={{ my: 3 }}>
          <FormControl>
            <FormLabel id="notification-preference-radio-buttons-group">Notification Preference</FormLabel>
            <RadioGroup
              aria-labelledby="notification-preference-radio-buttons-group"
              name="notification-preference-radio-buttons-group"
              value={notificationPreference}
              onChange={handleNotificationPreferenceChange}
            >
              <FormControlLabel value="sms_notification" control={<Radio />} label="Text" />
              <FormControlLabel value="email_notification" control={<Radio />} label="Email" />
            </RadioGroup>
          </FormControl>        
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography component="p" variant="body1" marginTop={3}>
            Change Profile Picture
          </Typography>
          {uploadedImages.length === 0 && 
              <FileUploadInput
              promptText='Drag and drop your new profile image here or'
              buttonText='Upload Photo'
              uploadedFiles={uploadedImages}
              setUploadedFiles={setUploadedImages}
              postConfig={postConfig}
              filesToUploadCount={imagesToUploadCount}
              saveToApi={saveAfterUpload}
            />}
          {uploadedImages.length > 0 &&
            <RoundImageCrop
              uploadedImage={uploadedImages[0]}
              imgSrc={userRecord.avatarUrl ?? ''}
              setCroppedAreaPixels={setCroppedAreaPixels}
            />
          }
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default withCommonTools(UserEditProfile);
