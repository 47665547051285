import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { LISTING_CATEGORIES } from '../../../constants/listings';
import { useFetchAdminListing } from '../../../hooks/fetchListing';
import type { ListingCategoryType } from '../../../types/listing';
import AddableAutocomplete from '../../../components/atoms/AddableAutocomplete/AddableAutocomplete';
import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';

interface IProps extends WrappedProps {};

export const removeInvalidOptions = (simpleArray: Array<string | undefined>): string[] => (
  simpleArray.filter((value) => Boolean(value) && value !== ',') as string[]
);

function AdminEditListing({ getConfig, postConfig }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchAdminListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      setTitle(listingRecord.name ?? '');
      setCategory(listingRecord.category ?? '');
      setShortDescription(listingRecord.shortDescription ?? '');
      if (listingRecord.keywords) {
        const validKeywords = removeInvalidOptions(listingRecord.keywords);
        setKeywords(validKeywords);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingRecord])

  const navigateToListing = () => {
    const path = generatePath(ROUTER_URLS.admin.listings.show, { id: listingId });
    navigate(path);
  };

  const handleSave = () => {
    setIsSaving(true);
    const params = {
      listing: {
        name: title,
        category,
        short_description: shortDescription,
        keywords,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      navigateToListing()
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleCancel = () => {
    navigateToListing();
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleShortDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShortDescription(event.target.value);
  }

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column' spacing={3} marginTop={3} marginBottom={10}>
        <Grid item xs={12} lg={6}>
          <Typography variant='h5'>Edit Listing {listingRecord.name}</Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            required
            id="outlined-required"
            label="Title"
            value={title}
            onChange={handleTitleChange}
            fullWidth
            placeholder="Example: Canoe Fishing on Walden Pond"
            helperText="Listing title will be present on search"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label" required>Category</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={category}
              label="Category"
              onChange={handleCategoryChange}
              required
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              { LISTING_CATEGORIES.map((option: ListingCategoryType) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
              
            </Select>
            <FormHelperText>Pick a category for your listing</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            required
            id="outlined-required"
            label="Short description"
            value={shortDescription}
            onChange={handleShortDescriptionChange}
            fullWidth
            helperText="This will be visible in search results along with your title"
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <AddableAutocomplete
            value={keywords}
            setValue={setKeywords}
            defaultOptions={[]}
            label="Keywords"
            helperText="Add tags to your listing by typing and selecting them"
            required={false}
          />
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            color='primary'
            variant='outlined'
            onClick={handleCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant='contained' color='primary'>
            Save
          </Button>
        </Grid>
      </Grid>      
    </DashboardLayout>
  );
}

export default withCommonTools(AdminEditListing);
