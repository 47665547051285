import React, { useState } from 'react';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import SignificantNumberCard from '../../components/compounds/SignificantNumberCard/SignificantNumberCard';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useFetchReferralCode, useFetchReferralMetrics } from '../../hooks/fetchReferralCode';

interface IProps extends WrappedProps {};

function ReferralProgramDashboard({ getConfig }: IProps) {
  // const { data: dashboardMetrics, isLoading: dashboardMetricsLoading } = useFetchDashboardMetrics(getConfig);
  const [link, setLink] = useState('');
  const [sharableLink, setSharableLink] = useState('');
  
  const { data: vrpCode, isLoading } = useFetchReferralCode(getConfig);
  const { data: referralMetrics, isLoading: metricsLoading } = useFetchReferralMetrics(getConfig);

  const generateSharableLink = () => {
    setSharableLink(`${link}?vrp=${vrpCode?.vrp_code ?? ''}`);
  }

  const copySharableLink = () => {
    navigator.clipboard.writeText(sharableLink).catch((e) => { console.log(e) })
  };

  return (
    <Dashboard>
      <Grid container direction='column' spacing={2} marginTop={2}>
        <Grid item>
          <Grid container direction='row' spacing={2} height={350}>
            <Grid item lg={6} xs={12} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
              <Paper elevation={2} sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
                  <Typography
                    variant='h6'
                    component='p'
                    align='center'
                    sx={{
                      mt: 2,
                      mb: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      width: '75%',
                      alignItems: 'center',
                      fontWeight: 600,
                    }}
                  >
                    Generate Link
                  </Typography>
                </Box>
                <Grid2 container padding={4} minHeight={250}>
                  <Grid2 xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Paste Url"
                      value={link}
                      onChange={(e) => { setLink(e.target.value) }}
                      fullWidth
                    />
                  </Grid2>
                  <Grid2 xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" color="primary" disabled={isLoading} onClick={generateSharableLink} sx={{ width: 150, marginLeft: 2 }}>
                      Generate Link
                    </Button>
                  </Grid2>
                  <Grid2 xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Sharable Url"
                      value={sharableLink}
                      onChange={(e) => { setSharableLink(e.target.value) }}
                      fullWidth
                    />
                  </Grid2>
                  <Grid2 xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant='outlined' color="primary" onClick={copySharableLink} sx={{ width: 150, marginLeft: 2 }}>
                      Copy
                    </Button>
                  </Grid2>
                </Grid2>
              </Paper>
            </Grid>

            <Grid item lg={6} xs={12}>
              <SignificantNumberCard
                header="Dollars Earned"
                loading={metricsLoading}
                count={referralMetrics?.dollarsEarned ? (referralMetrics.dollarsEarned / 100) : 0}
                variant='h2'
                currency
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={2} height={350}>
            <Grid item lg={6} xs={12}>
              <SignificantNumberCard
                header="Trips Booked"
                loading={metricsLoading}
                count={referralMetrics?.tripsBooked ?? 0}
                variant='h2'
              />
            </Grid>

            <Grid item lg={6} xs={12}>
              <SignificantNumberCard
                header="Views Generated"
                loading={metricsLoading}
                count={referralMetrics?.listingViewCount ?? 0}
                variant='h2'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default withCommonTools(ReferralProgramDashboard);
