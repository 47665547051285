import * as React from 'react';
// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import type { UserResultType } from '../../../types/user';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';

// const autocompleteService = { current: null };

interface IProps extends WrappedProps {
  value: UserResultType | null;
  setValue: (newValue: UserResultType | null) => void;
};

// interface MainTextMatchedSubstrings {
//   offset: number;
//   length: number;
// }
// interface StructuredFormatting {
//   main_text: string;
//   secondary_text: string;
//   main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
// }

function HostAutoSelect({ value, setValue, postConfig }: IProps) {
  // const [value, setValue] = React.useState<UserResultType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly UserResultType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly UserResultType[]) => void,
        ) => {
          // (autocompleteService.current as any).getPlacePredictions(
          //   request,
          //   callback,
          // );
          console.log(request)
          const params = {
            page: 1,
            sort: {
              field: 'firstName',
              sort: 'asc',
            },
            user: {
              full_name: request.input
            }
          }
    
          axios.post(API_V3_ALLIGATOR_URLS.admin.users.list, params, postConfig).then((response) => {
            callback(response.data.users);
          }).catch((error) => {
            console.log(error);
          });
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    // if (!autocompleteService.current && (window as any).google) {
    //   autocompleteService.current = new (
    //     window as any
    //   ).google.maps.places.AutocompleteService();
    // }
    // if (!autocompleteService.current) {
    //   return undefined;
    // }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly UserResultType[]) => {
      console.log(results);
      if (active) {
        let newOptions: readonly UserResultType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : `${option.firstName} ${option.lastName}`
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No hosts found"
      onChange={(event: any, newValue: UserResultType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Add a host" fullWidth />
      )}
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <li {...optionProps}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography variant="body1">
                  { `${option.firstName} ${option.lastName}` }
                </Typography>
                <Typography variant="body2">
                  {option.email}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default withCommonTools(HostAutoSelect);