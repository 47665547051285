
import { PaymentElement } from '@stripe/react-stripe-js';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface IProps {
  errorMessage?: string;
}

// https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=elements#create-intent
function PaymentDetails({ errorMessage }: IProps) {

  return (
    <Grid2 container spacing={3}>
      <Grid2 xs={12}>        
        <PaymentElement />
        {errorMessage && <div>{errorMessage}</div>}
      </Grid2>
    </Grid2>
    
  );
}

export default PaymentDetails;