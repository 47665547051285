import React, { useEffect, useState } from 'react';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { PhoneNumber } from '../../components/atoms/PhoneInput/PhoneInput';
import type { E164Number } from '../../components/atoms/PhoneInput/PhoneInput';
import { phoneNumberForInput } from '../../utils/phoneNumber';
import { useFetchUser } from '../../hooks/fetchUser';
import { useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../constants/router-urls';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import axios from 'axios';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';

interface IProps extends WrappedProps {};

function UserEditEmergencyContact({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const navigate = useNavigate();

  const [contactName, setContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");

  const [isSaving, setIsSaving] = useState(false);

  const { data: userRecord, isLoading } = useFetchUser(getConfig);

  useEffect(() => {
    if (userRecord) {
      if (userRecord.emergencyContactNumber) {
        const phone = phoneNumberForInput(userRecord.emergencyContactNumber);
        setPhoneNumber(phone);
      }
      setContactName(userRecord.emergencyContactName ?? '');
    }
  }, [userRecord]);

  const handlePhoneNumberChange = (event?: E164Number | undefined) => {
    setPhoneNumber(event ?? "");
  }

  const handleBack = () => {
    navigate(ROUTER_URLS.user.profile);
  }

  const handleSubmit = (): void => {
    setIsSaving(true);
    const params = {
      user: {
        emergency_contact_number: phoneNumber,
        emergency_contact_name: contactName,
      }
    }
    axios.patch(API_V3_ALLIGATOR_URLS.users.updateProfile, params, postConfig).then(() => {
      navigate(ROUTER_URLS.user.profile);
    }).catch(() => {
      setIsSaving(false);
    });
  };

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Container component={Stack} maxWidth="lg" my={6}>
        <Typography component="h1" variant="h5" my={3}>
          Edit Emergency Contact Details
        </Typography>
        <Box sx={{ my: 3 }}>
          <TextField
            required
            id="outlined-required"
            label="Emergency Contact Name"
            value={contactName}
            onChange={(e) => { setContactName(e.target.value) }}
            fullWidth
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <PhoneNumber
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            label="Emergency Contact Phone Number"
            helperText=""
          />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Button variant="outlined" onClick={handleBack}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ mx: 2 }}>Save</Button>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default withCommonTools(UserEditEmergencyContact);