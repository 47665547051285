import { useQuery } from "react-query";
import { fetchById, fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { ChatType } from "../types/chat";


export const useFetchMessages = (config: { headers: { Authorization: string } }, folder?: string, ) => {
  const url = `${API_V3_ALLIGATOR_URLS.messages.list}?folder=${folder ?? 'inbox'}`;
  return useQuery<ChatType[]>(
    ['ListingsInfo', folder],
    async () => await fetchList(url, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchMessage = (config: { headers: { Authorization: string } }, messageId?: string, ) => {
  return useQuery<ChatType>(
    ['BookingInfo', messageId],
    async () => await fetchById(API_V3_ALLIGATOR_URLS.messages.show, messageId, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};