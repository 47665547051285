import type { EventInput } from "@fullcalendar/core";
import { format } from "date-fns";
import type { SelectOptionType } from "../types/selectOption";
import { formatDateString, isoStringFromDateString } from "./dates";

export const removeAtIndex = <Type>(simpleArray: Type[], index: number): Type[] => {
  const clonedArray = [...simpleArray];
  clonedArray.splice(index, 1);
  return clonedArray;
};

export const arrayToSelectOption = (simpleArray: string[]): SelectOptionType[] => (
  simpleArray.filter((k) => k).map((keyword: string) => ({ value: keyword, label: keyword }))
);

export const selectOptionsToArray = (simpleArray: SelectOptionType[]): string[] => (
  simpleArray.map((keyword: SelectOptionType) => keyword.value)
);

export const stringDatesToCalendarDates = (dates: string[], color: string): EventInput[] => (
  dates.map((date: string) => ({
    title: '',
    date: formatDateString(date, 'yyyy-MM-dd'),
    color,
  }))
);

export const calendarDatesToStringDates = (dates: EventInput[]): string[] => {
  const mappedDates = dates.map((date: EventInput) => {
    const stringDate = date.date;
    if (stringDate !== undefined) return isoStringFromDateString(stringDate.toString());
    return undefined;
  })
  return mappedDates.filter((date: string | undefined) => Boolean(date)) as string[];
};

export const datesToCalendarDates = (dates: Date[], color: string): EventInput[] => (
  dates.map((date: Date) => ({
    title: '',
    date: format(date, 'yyyy-MM-dd'),
    color,
  }))
);
