import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import type { ListingResultType } from '../../../types/listing';

import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import Grid2 from '@mui/material/Unstable_Grid2';
import ListingCardImage from '../ListingCard/ListingCardImage';



interface IProps extends WrappedProps {
  hostId: number | null;
  listingId: number | null;
  setListingId: (value: number | null) => void;
  setListingName: (value: string) => void;
  nextStepPanel: React.ReactElement;
};

function ListingStep({ nextStepPanel, hostId, listingId, setListingId, setListingName, postConfig }: IProps) {
  const [listings, setListings] = useState<ListingResultType[]>([]);
  
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const params = {
      listing: {
        host_id: hostId
      }
    };
    axios.post(API_V3_ALLIGATOR_URLS.admin.listings.search, params, postConfig).then((results) => {
      setListings(results.data.listings);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
      setLoading(false);
    })
  }, []);
  
  if (loading) {
    return (
      <Grid2 container>
        <Grid2 xs={12}>
          <LoadingSpinner />
        </Grid2>
      </Grid2>
    );
  }

  if (!hostId) {
    return (
      <Grid2 container>
        <p>Error - you must select a host</p>
      </Grid2>
    );
  }

  const shortDescriptionText = (text: string): string => {
    if (text.length < 50) return text;
    return `${text.substring(34, 0)}...`
  };

  return (
    <Grid2 container spacing={2} marginX={3}>
      <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h5'>Select the listing to book</Typography>
      </Grid2>
      <Grid2 xs={12} minHeight={400}>
        <div>
        <Grid2 container spacing={5}>
          { listings.map((listing) => {
            const isSelected = listingId === Number(listing.id);
            const selectedStyles: any = {};
            if (isSelected) {
              selectedStyles.border = 2;
              selectedStyles.borderColor = 'secondary.main'
              selectedStyles.borderRadius = 3
            }

            return (
              <Grid2 xs={12} md={6} lg={3} key={listing.id}>
                <Card sx={selectedStyles}>
                  <CardHeader
                    title={listing.name}
                    subheader=""
                  />
                  <CardActionArea onClick={() => {
                      setListingId(Number(listing.id));
                      setListingName(listing.name);
                    }}
                  >
                    <ListingCardImage listingThumbnailUrl={listing.thumbnailUrl} loading={loading} />
                  </CardActionArea>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {shortDescriptionText(listing.shortDescription)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid2>
            )
          })}
        </Grid2>
        </div>
      </Grid2>
      <Grid2 xs={12}>
        { nextStepPanel }
      </Grid2>
    </Grid2>
  );
}

export default withCommonTools(ListingStep);
