import { AppBar, Box, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { useState } from "react";
import LeftDrawer from "../../layouts/Dashboard/LeftDrawer";
import { SearchNavContainerStyles, SearchNavHamburgerStyles, SearchNavLabelStyles } from "../../layouts/Dashboard/StyleOverrides";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";

const drawerWidth = 275;

const Contents = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  // flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
  }),
}));

const StyledLink = styled(Link)`
    

    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: inherit;
    }
`;

function BoringNavBar({ toggle }: { toggle: () => void }) {
  return (
    <Box flexGrow={1} flexDirection='column'>
      <AppBar
        position="fixed"
        sx={SearchNavContainerStyles}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggle}
            edge="start"
            sx={SearchNavHamburgerStyles}
          >
            <MenuIcon />
          </IconButton>
          
          <StyledLink to="/">
          <Typography
            variant="h5"
            noWrap
            component="div"
            
            sx={SearchNavLabelStyles}
          >
            Host Dashboard
          </Typography>
          </StyledLink>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0 }}>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

function HappyErrorBoundary() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(matches);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
      <Box>
        <BoringNavBar
          toggle={toggleDrawer}
        />
      </Box>
      <Box>
        <LeftDrawer open={open} />
          <Contents open={open}>
            <Toolbar />
            <Box padding={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 10, alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                <Typography variant='h6' marginBottom={3}>An unexpected error occurred</Typography>
                <Typography variant='body1'>Please try refreshing the page. If you continuing experiencing issues please contact support using the chat bot</Typography>
                <Typography variant='body1'>or calling us at +1 (844) 688-7437</Typography>
              </Box>
            </Box>
          </Contents>
      </Box>
    </Box>  
  );
}

export default HappyErrorBoundary;