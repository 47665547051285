import React from 'react';
import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import formatCurrency from "../../../utils/currency";

interface IProps {
  header: string;
  count: number;
  loading: boolean;
  currency?: boolean;
  variant?: string;
  navLink?: React.ReactNode;
  toggles?: React.ReactNode;
};

const determineVariant = (variant?: string) => {
  switch (variant) {
    case 'h2':
      return 'h2'
    case 'h4':
      return 'h4'
    default:
      return 'h1'
  }
}

function SignificantNumberCard({ header, count, currency, variant, navLink, loading, toggles }: IProps) {
  const appliedVariant = determineVariant(variant);
  
  return (
    <Paper elevation={2} sx={{ borderRadius: 3, height: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider', width: '100%',  }}>
        <Typography
          variant='h6'
          component='h6'
          align='center'
          sx={{
            mt: 2,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '75%',
            alignItems: 'center',
            fontWeight: 600,
          }}
        >
          { header }
        </Typography>
      </Box>        
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        borderColor: 'divider',
        flexGrow: 1,
      }}>
        <Stack>
        { loading ?
          <Box sx={{
            mt: 3,
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            minHeight: '72px',
          }}>
            <CircularProgress />
          </Box> :
          <Typography
            variant={appliedVariant}
            component={appliedVariant}
            align='center'
            color='primary'
            sx={{
              mt: 3,
              mb: 3,
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
              width: '100%',
              alignItems: 'center',
              fontWeight: 600,
            }}
          >
            { currency ? `$${formatCurrency(count)}` : count }
          </Typography>
        }
        { toggles }
        </Stack>
      </Box>
      { navLink }
    </Paper>
  );
}

export default SignificantNumberCard;