import { useQuery } from "react-query";
import { fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";

interface DashboardRecentDepositsParamType {
  days_back: number;
}

interface DashboardUpcomingBookingsParamType {
  days_filter: string;
}

interface DashboardRecentDepositResultType {
  total: number;
}

interface DashboardUpcomingBookingsResultType {
  total: number;
}

export const useFetchDashboardMetrics = (config: { headers: { Authorization: string } }) => {
  return useQuery<any>(
    ['DashboardMetrics'],
    async () => await fetchList(API_V3_ALLIGATOR_URLS.dashboard.metrics, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchDashboardRecentDeposits = (config: { headers: { Authorization: string } }, params: DashboardRecentDepositsParamType) => {
  return useQuery<DashboardRecentDepositResultType>(
    ['DashboardMetrics', params.days_back],
    async () => await fetchList(`${API_V3_ALLIGATOR_URLS.dashboard.recentDeposits}?days_back=${params.days_back}`, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchDashboardUpcomingBookings = (config: { headers: { Authorization: string } }, params: DashboardUpcomingBookingsParamType) => {
  return useQuery<DashboardUpcomingBookingsResultType>(
    ['DashboardMetrics', params.days_filter],
    async () => await fetchList(`${API_V3_ALLIGATOR_URLS.dashboard.upcomingBookings}?days_filter=${params.days_filter}`, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};