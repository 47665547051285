import type { AxiosHeaders } from "axios";
import axios from "axios";
import * as SparkMD5 from 'spark-md5';

export const uploadToS3 = async (
  url: string,
  config: AxiosHeaders,
  // signedId: string,
  file: File
) => {
  const s3Upload = await axios({
    method: 'put',
    data: file,
    url,
    headers: config,
  });

  if (s3Upload.status !== 200) {
    throw new Error('Failed to upload to cloud')
  }

  return s3Upload;
}

// [
//   {
//       "AllowedHeaders": [
//           "Content-Type",
//           "Content-MD5",
//           "Content-Disposition"
//       ],
//       "AllowedMethods": [
//           "PUT"
//       ],
//       "AllowedOrigins": [
//           "http://localhost:3000"
//       ],
//       "ExposeHeaders": [],
//       "MaxAgeSeconds": 3600
//   }
// ]

export const computeChecksumMd5 = async (file: File): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/return-await
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0; // current cursor in file

    fileReader.onerror = function(): void {
      reject(new Error('MD5 computation failed - error reading the file'));
    };

    // read chunk starting at `cursor` into memory
    function processChunk(chunkStart: number): void {
      const chunkEnd = Math.min(file.size, chunkStart + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunkStart, chunkEnd));
    }

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead 
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    fileReader.onload = function(e: any): void {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      cursor += chunkSize; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        // This returns the base64 encoded md5 hash, which is what
        // Rails ActiveStorage or cloud services expect
        resolve(btoa(spark.end(true)));

        // If you prefer the hexdigest form (looking like
        // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
        // resolve(spark.end());
      }
    };

    processChunk(0);
  });
}