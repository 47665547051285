import * as React from 'react';
import { IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';

interface IProps {
  listingId: string;
  previewLink: string;
  setLoading: (value: boolean) => void;
  postConfig: any;
}

export default function ListingCardActions({ listingId, previewLink, setLoading, postConfig}: IProps) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToListing = (id: string) => {
    const path = generatePath(ROUTER_URLS.listings.show, { id });
    navigate(path);
  };

  const navigateToEdit = (id: string) => {
    const path = generatePath(ROUTER_URLS.listings.edit, { id });
    navigate(path);
  };

  const duplicateListing = (id: string) => {
    setLoading(true);
    const config: any = { ...postConfig };
    const params = {
      id
    }
    axios.post(API_V3_ALLIGATOR_URLS.listing.duplicate, params, config).then((response) => {
      const newListing = response.data;
      if (newListing?.id) {
        navigateToListing(newListing.id);
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <IconButton
        aria-label="options"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { duplicateListing(listingId) }}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { navigateToEdit(listingId) }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem disabled>
          <ListItemIcon>
            <ShareIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <ListItemText>Share on Social</ListItemText>
        </MenuItem>
        <Link target='_blank' rel="noopener" href={previewLink} underline='none' color='text.primary'>
          <MenuItem>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" color='primary' />
            </ListItemIcon>
            <ListItemText>Preview</ListItemText>
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}