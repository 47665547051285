import React, { useState } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTER_URLS } from '../../../constants/router-urls';
import ListingCardImage from './ListingCardImage';
import ListingCardActions from '../../atoms/ListingCardActions/ListingCardActions';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { useUserCanAccessBookNow } from '../../../hooks/userCanAccess';


interface IProps {
  listingId: string;
  listingThumbnailUrl?: string;
  listingName: string;
  listingShortDescription: string;
  listingListingRating: number;
  postConfig: any;
  listingSlug: string;
  listingPublished: boolean;
  bookNowSlug: string;
  refetch: any;
};

function ListingCard({
  listingId,
  listingThumbnailUrl,
  listingName,
  listingShortDescription,
  postConfig,
  listingSlug,
  listingPublished,
  bookNowSlug,
  refetch,
}: IProps) {
  const navigate = useNavigate();
  const canAccessBookNow = useUserCanAccessBookNow();
  const previewLink = canAccessBookNow ? `https://www.huntpay.com/listing/s/${bookNowSlug}` : `https://www.venku.com/listing/s/${listingSlug}`

  const [loading, setLoading] = useState(false);
  const [tempListingStatus, setTempListingStatus] = useState(listingPublished);

  const shortDescriptionText = (text: string): string => {
    if (text.length < 50) return text;
    return `${text.substring(34, 0)}...`
  };

  const navigateToListing = (id: string) => {
    const path = generatePath(ROUTER_URLS.listings.show, { id });
    navigate(path);
  };

  const publishListing = (id: string) => {
    // geoLocation?
    setLoading(true);
    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, { listing: { published: true } }, postConfig).then((response) => {
      setTempListingStatus(true);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const unpublishListing = (id: string) => {
    // geoLocation?
    setLoading(true);
    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, { listing: { published: false } }, postConfig).then((response) => {
      setTempListingStatus(false);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const deleteListing = (id: string) => {
    setLoading(true);
    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, { listing: { host_hidden: true } }, postConfig).then((response) => {
      refetch();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Grid item xs={12} md={6} lg={4} key={listingId}>
      <Card>
        <CardHeader
          action={<ListingCardActions
              listingId={listingId}
              setLoading={setLoading}
              postConfig={postConfig}
              previewLink={previewLink}
            />}
          title={listingName}
          subheader=""
        />
        <CardActionArea onClick={() => { navigateToListing(listingId) }}>
          <ListingCardImage listingThumbnailUrl={listingThumbnailUrl} loading={loading} />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {shortDescriptionText(listingShortDescription)}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          {
            !tempListingStatus ? (
              <Button variant="contained" color="secondary" startIcon={<PublishIcon />} onClick={() => { publishListing(listingId) }}>
                Publish
              </Button>
            ) : (
              <Button variant="outlined" startIcon={<UnpublishedIcon />} onClick={() => { unpublishListing(listingId) }}>
                Unpublish
              </Button>
            )
          }
          <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => { deleteListing(listingId) }} sx={{ marginX: 2 }}>
            Delete
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default ListingCard;

