import { Box, Slider } from '@mui/material'
import React from 'react'
// import ReactDOM from 'react-dom'

import Cropper from 'react-easy-crop'
import type { IReadFile } from '../../../types/readFile';

// import getCroppedImg from '../../../utils/cropImage';
// import type { UploadedFileProps } from '../FileUploadInput/FileUploadInput';
// import './styles.css'

interface IProps {
  imgSrc: string;
  uploadedImage: IReadFile;
  // croppedImage: any;
  // setCroppedImage: (image: any) => void;
  setCroppedAreaPixels: (pixels: any) => void;
}

function RoundImageCrop({ imgSrc, uploadedImage, setCroppedAreaPixels }: IProps) {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)

  // const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>(null);
  

  const onCropChange = (crop: any) => {
    console.log('onCropChange')
    setCrop(crop)
  }

  

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    console.log('onCropComplete');
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onZoomChange = (zoom: any) => {
    setZoom(zoom)
  }

  return (
    <>
      <Box display='block' maxHeight={400}>
        {
          // https://github.com/ValentinH/react-easy-crop
          // "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
        }
        <Cropper
          image={uploadedImage.publicUrl}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="rect"
          showGrid={false}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
          style={{
            containerStyle:{
              height: 300,
              position: 'relative',
            }
          }}
          
        />
      </Box>
      <Slider
        value={zoom}
        min={1}
        max={3}
        step={0.1}
        aria-labelledby="Zoom"
        sx={{ marginTop: 2 }}
        onChange={(e, zoom) => { onZoomChange(zoom) }}
      />
    </>
  )
}

export default RoundImageCrop;