import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PaperWithHeader from '../PaperWithHeader/PaperWithHeader';
import type { BookingDetailType } from '../../../types/booking';
import formatCurrency from '../../../utils/currency';
import PayoutSchedule from './PayoutSchedule';
import { PaymentFailedMessage } from './PaymentFailedMessage';

interface IProps {
  booking: BookingDetailType
}

function BookingPayoutCard({ booking }: IProps) {

  return (
    <PaperWithHeader headerTitle='Payout Details'>
      <Grid container direction='row' padding={2} spacing={2}>
        <Grid item xs={12} lg={12}>
          {booking.status.toLowerCase() === 'paymentfailed' ? <PaymentFailedMessage /> :
          <>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
              flexGrow: 1,
            }}>
              
              <Typography
                variant='h4'
                align='center'
                color='primary'
                sx={{
                  mt: 2,
                  mb: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  width: '100%',
                  alignItems: 'center',
                  fontWeight: 600,
                }}
              >
                { `$${formatCurrency((booking.purchaseDetails?.recipientTotal ?? 0) / 100)}` }
              </Typography>
            </Box>
            <PayoutSchedule booking={booking} />
          </>
          }
        </Grid>
      </Grid>
    </PaperWithHeader>
  );
}

export default BookingPayoutCard;