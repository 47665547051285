import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import DrawerLinks from './DrawerLinks';
import { LeftDrawerContainerStyles, LeftDrawerSpacerStyles } from './StyleOverrides';
import { ContactSupport } from './ContactSupport';
import { FEATURE_FLAGS } from '../../../constants/settings';

interface IProps {
  open: boolean;
}

function LeftDrawer({ open }: IProps) {
  return (
    <Drawer
      sx={LeftDrawerContainerStyles}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Toolbar />
      <DrawerLinks />
      <Box sx={LeftDrawerSpacerStyles} />
      {FEATURE_FLAGS.contactSupport && <ContactSupport />}
    </Drawer>
  );
}

export default LeftDrawer;
