const baseApiDomain = process.env.REACT_APP_ALLIGATOR_DOMAIN ?? 'http://localhost:3008';

export const API_V3_ALLIGATOR_URLS = {
  auth: {
    login: `${baseApiDomain}/oauth/token`,
    refresh: `${baseApiDomain}/oauth/token`,
    logout: `${baseApiDomain}/oauth/revoke`,
    changePassword: `${baseApiDomain}/users/password`,
    forgotPassword: `${baseApiDomain}/users/forgot_password`,
    confirmEmail: `${baseApiDomain}/users/confirmation`,
    resendConfirmEmail: `${baseApiDomain}/users/resend_confirmation`,
  },
  appUsageFees: {
    search: `${baseApiDomain}/host_dashboard/app_fees/search`,
  },
  messages: {
    list: `${baseApiDomain}/host_dashboard/messages`,
    show: `${baseApiDomain}/host_dashboard/messages/`,
    create: `${baseApiDomain}/host_dashboard/messages/`,
    update: `${baseApiDomain}/host_dashboard/messages/`,
    unread_count: `${baseApiDomain}/host_dashboard/messages/unread_count/`
  },
  reports: {
    recentDeposits: `${baseApiDomain}/host_dashboard/reports/recent_deposits/`,
  },
  dashboard: {
    metrics: `${baseApiDomain}/host_dashboard/home_metrics/`,
    recentDeposits: `${baseApiDomain}/host_dashboard/home_metrics/recent_deposits/`,
    upcomingBookings: `${baseApiDomain}/host_dashboard/home_metrics/upcoming_bookings/`,
    countsAndNotifications: `${baseApiDomain}/host_dashboard/home_metrics/user_counts_and_notifications/`
  },
  users: {
    profile: `${baseApiDomain}/host_dashboard/user_profile/`,
    updateProfile: `${baseApiDomain}/host_dashboard/user_profile/`,
  },
  listing: {
    list: `${baseApiDomain}/host_dashboard/listings/`,
    show: `${baseApiDomain}/host_dashboard/listings`,
    create: `${baseApiDomain}/host_dashboard/listings/`,
    update: `${baseApiDomain}/host_dashboard/listings/`,
    deleteImage: `${baseApiDomain}/host_dashboard/media_listings/`,
    duplicate: `${baseApiDomain}/host_dashboard/listings/duplicate`,
  },
  landing: {
    list: `${baseApiDomain}/host_dashboard/landing_pages/`,
    show: `${baseApiDomain}/host_dashboard/landing_pages`,
    create: `${baseApiDomain}/host_dashboard/landing_pages/`,
    update: `${baseApiDomain}/host_dashboard/landing_pages/`,
  },
  listing_additional_charges: {
    create: `${baseApiDomain}/host_dashboard/listings/:listingId/listing_additional_charges/`,
    update: `${baseApiDomain}/host_dashboard/listings/:listingId/listing_additional_charges/:id`,
    destroy: `${baseApiDomain}/host_dashboard/listings/:listingId/listing_additional_charges/:id`,
  },
  booking: {
    list: `${baseApiDomain}/host_dashboard/trips/search`,
    show: `${baseApiDomain}/host_dashboard/trips/`,
    statusCheck: `${baseApiDomain}/host_dashboard/trips/:id/status_check/`,
    confirm: `${baseApiDomain}/host_dashboard/trips/confirm/`,
    deny: `${baseApiDomain}/host_dashboard/trips/deny/`,
    export: `${baseApiDomain}/host_dashboard/trips/export`,
  },
  cancellationPolicies: {
    list: `${baseApiDomain}/host_dashboard/cancellation_policies`,
  },
  stripeConnect: {
    create: `${baseApiDomain}/host_dashboard/stripe_connect`,
  },
  directUpload: {
    create: `${baseApiDomain}/rails/active_storage/direct_uploads/`,
  },
  // uploads to the private bucket
  privateDirectUpload: {
    create: `${baseApiDomain}/host_dashboard/presigned_urls/`,
  },
  signingTemplates: {
    list: `${baseApiDomain}/host_dashboard/signing_templates/`,
    create: `${baseApiDomain}/host_dashboard/signing_templates/`,
    destroy: `${baseApiDomain}/host_dashboard/signing_templates/`,
    enabledForUser: `${baseApiDomain}/host_dashboard/signing_templates/enabled/check`,
    verify: `${baseApiDomain}/host_dashboard/signing_templates/verify`,
  },
  signingDocuments: {
    list: `${baseApiDomain}/host_dashboard/signing_documents/`,
    create: `${baseApiDomain}/host_dashboard/signing_documents/`,
  },
  referralCode: {
    show: `${baseApiDomain}/host_dashboard/referral_codes/`,
  },
  referralMetrics: {
    show: `${baseApiDomain}/host_dashboard/referral_metrics/`,
  },
  waivers: {
    list: `${baseApiDomain}/host_dashboard/trip_signing_documents/`,
  },
  admin: {
    hostContacts: {
      list: `${baseApiDomain}/admin/host_contacts/`
    },
    appUsageFees: {
      list: `${baseApiDomain}/admin/app_fees/`,
      create: `${baseApiDomain}/admin/app_fees/`
    },
    bookings: {
      list: `${baseApiDomain}/admin/bookings/`,
      search: `${baseApiDomain}/admin/bookings/search`,
      patch: `${baseApiDomain}/admin/bookings`,
      show: `${baseApiDomain}/admin/bookings`,
      create: `${baseApiDomain}/admin/bookings/`,
      update: `${baseApiDomain}/admin/bookings/`,
      export: `${baseApiDomain}/admin/bookings/export`,
      delete: `${baseApiDomain}/admin/bookings/`,
    },
    checkout: {
      customer: `${baseApiDomain}/admin/checkout/customer`,
    },
    listings: {
      show: `${baseApiDomain}/admin/listings`,
      search: `${baseApiDomain}/admin/listings/search`,
      export: `${baseApiDomain}/admin/listings/export`,
      update: `${baseApiDomain}/admin/listings/`,
    },
    payments: {
      search: `${baseApiDomain}/admin/payments/search`,
      show: `${baseApiDomain}/admin/payments`,
      update: `${baseApiDomain}/admin/payments/`,
    },
    payouts: {
      search: `${baseApiDomain}/admin/payouts/search`,
      show: `${baseApiDomain}/admin/payouts`,
      update: `${baseApiDomain}/admin/payouts/`,
    },
    refunds: {
      search: `${baseApiDomain}/admin/refunds/search`,
      show: `${baseApiDomain}/admin/refunds`,
      create: `${baseApiDomain}/admin/refunds/`,
    },
    reversals: {
      search: `${baseApiDomain}/admin/reversals/search`,
      show: `${baseApiDomain}/admin/reversals`,
      create: `${baseApiDomain}/admin/reversals/`,
    },
    users: {
      list: `${baseApiDomain}/admin/users/search`,
      show: `${baseApiDomain}/admin/users/`,
      changePassword: `${baseApiDomain}/admin/users/password/`,
      create: `${baseApiDomain}/admin/users/`,
      update: `${baseApiDomain}/admin/users/`,
      impersonate: `${baseApiDomain}/admin/users/impersonate`,
      stopImpersonating: `${baseApiDomain}/admin/users/stop_impersonating/`,
    },
  }
}