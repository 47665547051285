import React, { useState } from 'react';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { LISTING_ADDITIONAL_COST_ITEM } from '../../../constants/listings';
import type { ListingAdditionalCostItemType } from '../../../types/listing';
import HorizontalStepperActions from '../../atoms/HorizontalStepperActions/HorizontalStepperActions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { removeAtIndex } from '../../../utils/arrays';
import QuickCheckoutCartSummary from '../QuickCheckoutCartSummary/QuickCheckoutCartSummary';

interface IProps {
  firstStep: boolean;
  finalStep: boolean;
  handleNext: () => void;
  handleBack: () => void;
};

function QuickCheckoutPricingFields({
  firstStep,
  finalStep,
  handleNext,
  handleBack,
}: IProps) {
  const handleAddAdditionalPricingItem = () => {
    const currentPricingItems = [...additionalPricingItems];
    currentPricingItems.push(LISTING_ADDITIONAL_COST_ITEM);
    setAdditionalPricingItems(currentPricingItems);
  };

  const handleRemoveAdditionalPricingItem = (index: number) => {
    const newPricingItems = removeAtIndex(additionalPricingItems, index);
    setAdditionalPricingItems(newPricingItems)
  };

  const basePrice = '';

  const [additionalPricingItems, setAdditionalPricingItems] = useState<ListingAdditionalCostItemType[]>([]);

  return (
    <>
      <Grid container direction='row' spacing={2} padding={2}>
        <Grid item xs={8}>
          <Paper elevation={2}>
            <Grid container direction='row' display='flex' padding={2} justifyContent='center' justifyItems='center'>
              <Grid item xs={8}>
              
                <Typography
                  variant='body1'
                  component='p'
                  // align='center'
                  sx={{
                    mt: 0,
                    mb: 0,
                    display: 'flex',
                    // justifyContent: 'center',
                    // justifyItems: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '100%',
                    fontWeight: 600,
                    // width: '75%',
                    // alignItems: 'center'
                  }}
                >
                  Additional Costs
                </Typography>
    
              </Grid>
              <Grid item xs={4} display='flex' justifyContent='end'>
                <Button
                  type="submit"
                  variant="contained"
                  // sx={{ height: '45px', marginY: 3, marginLeft: 3, }}
                  onClick={handleAddAdditionalPricingItem}
                >
                  Add Additional Cost
                </Button>
              </Grid>
            </Grid>

            {
              additionalPricingItems && additionalPricingItems.length > 0 ?
              additionalPricingItems.map((additionalPricingItem: ListingAdditionalCostItemType, index: number) => {
                return (
                  <Box key={`${additionalPricingItem.description ?? ''}-${index}`} sx={{ borderTop: 1, borderColor: 'divider' }}>
                    <Grid container direction='row'>
                      <Grid item xs={1}>
                        <Box display='flex' alignContent='center' alignItems='center' justifyContent='center' justifyItems='center' height='100%'>
                          <IconButton color='error' sx={{ height: '20px' }} onClick={() => { handleRemoveAdditionalPricingItem(index) }}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid item xs={11}>
                        
                        <Grid container direction='row' padding={2} spacing={2}>
                          <Grid item xs={8}>
                            <TextField
                              required
                              id="outlined-required"
                              label="Name"
                              defaultValue=""
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="outlined-adornment-amount">Cost</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Cost"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container direction='row' padding={2} spacing={2}>
                          <Grid item xs={8}>
                            <TextField
                              required
                              id="outlined-required"
                              label="Description"
                              defaultValue=""
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="outlined-adornment-amount">Quantity</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                label="Quantity"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                );
              }) :
              <Grid container direction='row' display='flex' padding={2} justifyContent='center' justifyItems='center'>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    component='p'
                    sx={{
                      mt: 0,
                      mb: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      height: '100%',
                      fontStyle: 'italic',
                      width: '100%',
                    }}
                  >
                    Listing has no additional costs
                  </Typography>
      
                </Grid>
              </Grid>
            }
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <QuickCheckoutCartSummary listingBasePrice={Number(basePrice)} />
        </Grid>
      </Grid>
      <Grid container direction='row' spacing={5} justifyContent='center'>
        <Grid item xs={10} marginTop={5}>
          <HorizontalStepperActions
            firstStep={firstStep}
            finalStep={finalStep}
            handleNext={handleNext}
            handleBack={handleBack}
            isValid={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuickCheckoutPricingFields;