import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function LoadingSpinner() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 10, alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
      <Typography variant='h6' marginBottom={3}>Loading...</Typography>
      <CircularProgress />
    </Box>
  );
}