import ExcelJS from 'exceljs';
import { formatDateString } from './dates';

export const arrayToExcel = (data: any[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Recent Deposits");
  sheet.columns = [
    {
      header: "Date",
      key: "date",
      width: 20,
    },
    {
      header: "Payee",
      key: "payee",
      width: 20,
    },
    {
      header: "Party Size",
      key: "partySize",
      width: 20,
    },
    {
      header: "Listing Name",
      key: "listingName",
      width: 50,
    },
    {
      header: "Deposit Amount",
      key: "amount",
      width: 20,
    },
  ];
  data.forEach((row) => {
    sheet.addRow({
      date: formatDateString(row.date),
      payee: row.payee,
      partySize: row.partyCount,
      listingName: row.listingName,
      amount: row.amount?.toFixed(2),
    })
  });
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "recent-deposits-list.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  }).catch((e) => { console.log(e) });
};