import startCase from 'lodash/startCase';

const toProperCase = (text: string): string => {
  const lowerCaseText = text.toLowerCase();
  return startCase(lowerCaseText);
};

export const snakeToProper = (text: string): string => {
  const startCaseText = startCase(text);
  return startCaseText;
};

export const camelToProper = (text: string): string => {
  const startCaseText = startCase(text);
  return startCaseText;
}

export default toProperCase;