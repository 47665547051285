import { useQuery } from "react-query";
import { fetchList } from "../api/requests";
import { API_V3_ALLIGATOR_URLS } from "../constants/api-urls";
import type { SigningTemplateResultType, SigningDocumentResultType } from "../types/signingTemplate";

export const useFetchSigningTemplates = (config: { headers: { Authorization: string } }) => {
  const url = API_V3_ALLIGATOR_URLS.signingTemplates.list;
  return useQuery<SigningTemplateResultType[]>(
    ['SigningTemplatesInfo'],
    async () => await fetchList(url, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchWaiverEnabled = (config: { headers: { Authorization: string } }) => {
  const url = API_V3_ALLIGATOR_URLS.signingTemplates.enabledForUser;
  return useQuery<{ boldsign: boolean }>(
    ['SigningTemplatesEnabled'],
    async () => await fetchList(url, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchSigningDocuments = (timeStamp: string, config: { headers: { Authorization: string } }) => {
  const url = API_V3_ALLIGATOR_URLS.signingDocuments.list;
  return useQuery<SigningDocumentResultType[]>(
    ['SigningDocumentsInfo', timeStamp],
    async () => await fetchList(url, config),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};