import React, { useState } from 'react';
import { Button, Grid, Typography } from "@mui/material";
import './styling.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import axios from 'axios';
import { computeChecksumMd5, uploadToS3 } from '../../../utils/fileUpload';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';

export interface UploadedFileInterface {
  id?: number;
  signedId?: string;
  file?: File;
  url?: string;
}

interface IProps {
  listingId: string;
  promptText: string;
  buttonText: string;
  // setUploadedFiles: (files: UploadedFileProps[]) => void;
  uploadedFiles: UploadedFileInterface[]
  setUploadedFiles: (newFile: UploadedFileInterface[]) => void;
  postConfig: {
    headers: {
      Authorization: string;
    };
  };
  filesToUploadCount: React.MutableRefObject<number>;
  fileAlreadyUploaded: (name: number) => boolean;
}

function ListingFileUploadInput({
  listingId,
  promptText,
  buttonText,
  // setUploadedFiles,
  uploadedFiles,
  setUploadedFiles,
  postConfig,
  filesToUploadCount,
  fileAlreadyUploaded,
}: IProps) {  
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  
  const handleDrag = (e: React.DragEvent<HTMLFormElement | HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const getS3Url = async (newFile: File) => {
    const checkSum = await computeChecksumMd5(newFile);
    return await axios.post(
      API_V3_ALLIGATOR_URLS.directUpload.create,
      {
        blob: {
          filename: newFile.name,
          content_type: newFile.type,
          byte_size: newFile.size,
          checksum: checkSum, /* base 64 of the MD5 hash of the file */
        }
      },
      {
        headers: { Authorization: postConfig.headers.Authorization },
      }
    )
  };

  const handleDrop = async function(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files?.[0]) {
      filesToUploadCount.current = e.dataTransfer.files.length;

      const tempFiles = [...uploadedFiles]
      for (const file of Array.from([...e.dataTransfer.files])) {

        const s3UrlRes = await getS3Url(file);
        
        if (s3UrlRes.data.direct_upload) {
          const directUpload = s3UrlRes.data.direct_upload;
  
          const uploadToS3Res = await uploadToS3(directUpload.url, directUpload.headers, file)
          if (uploadToS3Res.status === 200) {
              
            const newUploadedFile = {
              // id: res.data.id,
              signedId: s3UrlRes.data.signed_id,
              file: file,
            }

            tempFiles.push(newUploadedFile);
          }             
        }

        const tempCount = filesToUploadCount.current;
        filesToUploadCount.current = (tempCount - 1);
      }
      setUploadedFiles(tempFiles);
    }
  };

  // triggers when file is selected with click
  const handleChange = async function(e: React.ChangeEvent<HTMLInputElement>) {
    setErrorMessage(undefined);
    e.preventDefault();
    if (e.target.files?.[0]) {
      filesToUploadCount.current = e.target.files.length - 1;


      const tempFiles = [...uploadedFiles]
      for (const file of Array.from([...e.target.files])) {

        const s3UrlRes = await getS3Url(file);
        
        if (s3UrlRes.data.direct_upload) {
          const directUpload = s3UrlRes.data.direct_upload;
  
          const uploadToS3Res = await uploadToS3(directUpload.url, directUpload.headers, file)
          if (uploadToS3Res.status === 200) {
              
            const newUploadedFile = {
              // id: res.data.id,
              signedId: s3UrlRes.data.signed_id,
              file: file,
            }

            tempFiles.push(newUploadedFile);
          }             
        }

        const tempCount = filesToUploadCount.current;
        filesToUploadCount.current = (tempCount - 1);
      }
      setUploadedFiles(tempFiles);
    }
  };

  const onButtonClick = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  if (filesToUploadCount.current > 0) {
    return (
      <Grid container direction='row' spacing={2} padding={2}>
        <Grid item xs={12} lg={12}>
          <LoadingSpinner />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction='row' spacing={2} padding={2}>
      <Grid item xs={12} lg={12}>
        <form id="file-upload-form" onDragEnter={handleDrag} onSubmit={(e) => { handleSubmit(e) }}>
          {
            // this is a fire and forget function for now
            // https://stackoverflow.com/questions/63488141/promise-returned-in-function-argument-where-a-void-return-was-expected
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <input ref={inputRef} type="file" id="file-upload-input" multiple={true} onChange={async (e) => { await handleChange(e) }} />
          }
          <label id="file-upload-label" htmlFor="file-upload-input" className={dragActive ? "drag-active" : "" }>
            <div>
              {
                errorMessage ? 
                <Typography
                  variant='body1'
                  component='p'
                  color='error'
                  sx={{
                    mt: 0,
                    mb: 0,
                    padding: 4,
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '100%',
                    fontWeight: 600,
                  }}
                > 
                  { errorMessage }
                </Typography> : 
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    mt: 0,
                    mb: 0,
                    padding: 4,
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '100%',
                  }}
                >
                  { promptText }
                </Typography>              
              }
              <Button
                onClick={onButtonClick}
                className="upload-button"
                variant="text"
              >
                { buttonText }
              </Button>
            </div>
          </label>
          {
            // this is a fire and forget function for now
            // https://stackoverflow.com/questions/63488141/promise-returned-in-function-argument-where-a-void-return-was-expected
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={async (e) => { await handleDrop(e) }}></div>
          }
        </form>
      </Grid>
    </Grid>
  );
}

export default ListingFileUploadInput;
